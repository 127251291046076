import { useState } from 'react';
import {
  CameraOutlined,
  EditOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import { BadgeIcon } from '../../UI';
import UserForm from '../UserForm';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  editUser,
  editUserAssigment,
} from '../../../store/actions/user-actions';
import { useParams } from 'react-router-dom';
import { UserRoles } from '../../../lib/constants/roles';
import DefaultBadge from '../../UI/default-badge/DefaultBadge';
import UserText from '../UserText';
import ChangePasswordForm from '../../change-password/ChangePasswordForm';
import { updateUser } from '../../../lib/user';
import { toastrSuccess } from '../../../helpers/toastr';
import { selectCurrentDealership } from '../../../store/slices/dealership-slice';
import { Payments } from '../../../lib/constants/payments';
import moment from 'moment';
import { PRIMARY_DATE_FORMAT } from '../../../lib/constants/utils';
import { useTranslation } from 'react-i18next';

const UserProfileData = ({
  user,
  isSelfProfile = false,
  canChangePassword = false,
}) => {
  const dispatch = useDispatch();
  const dealership = useSelector(selectCurrentDealership);
  const { t } = useTranslation();

  const { dealershipId: id } = useParams();
  const dealershipId = id || dealership?.id;

  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const [toggleEditUser, setToggleEditUser] = useState(false);
  const [toggleChangePassword, setToggleChangePassword] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const onCloseHandler = () => {
    setToggleEditUser(false);
  };

  const editUserHandler = async (body, dealershipUserBody = null) => {
    setIsAdding(true);

    if (Object.keys(body).length > 0) {
      await dispatch(editUser({ userId: user.id, body, isCurrent: true }));
    }

    if (Object.keys(dealershipUserBody).length > 0) {
      await dispatch(
        editUserAssigment({
          userId: user.id,
          dealershipId,
          body: dealershipUserBody,
          isCurrent: true
        })
      );
    }

    onCloseHandler();
    setIsAdding(false);
  };

  const userDealership = user.dealerships.find(
    (dealership) => dealership.id === dealershipId
  );

  const isAdmin =
    !!userDealership &&
    userDealership.user_dealership?.user_role === UserRoles.ADMIN;

  const userCityCountry = `${user.city || '-'}, ${user.country || '-'}`;

  const updatePasswordHandler = async (data) => {
    setIsAdding(true);

    try {
      await updateUser(user.id, data);

      setToggleChangePassword(false);
      toastrSuccess(t('global.successPasswordChange'));
    } catch (err) {}

    setIsAdding(false);
  };

  const creditsType =
    dealership && dealership?.price_type === Payments.PER_USE
      ? 'PREPAID'
      : dealership?.price_type;

  const credits =
    dealership && dealership?.price_type === Payments.UNLIMITED
      ? `Unlimited (until: ${moment(dealership?.unlimited_until).format(
          PRIMARY_DATE_FORMAT
        )})`
      : dealership?.credits
      ? dealership?.credits
      : '0';

  return (
    <>
      <div className="p-2" style={{ position: 'relative' }}>
        <div className="mt-3">
          <p className="user_name">{user.name}</p>
          <p className="user_email small mb-2">{user.email}</p>
          {!isSelfProfile && !!userDealership && (
            <DefaultBadge
              text={
                userDealership.user_dealership?.group?.name ||
                t('users.selfRegistered')
              }
            />
          )}
          {!isSelfProfile && isAdmin && (
            <DefaultBadge text={'Admin'} isAdmin={true} />
          )}
        </div>
        {isSelfProfile && (
          <>
            <div className="d-flex justify-content-end">
              <Button
                type="text"
                onClick={() => setToggleEditUser(true)}
                className="d-flex align-items-center"
              >
                {t('global.edit')} <EditOutlined />
              </Button>
            </div>
            <div className="mt-3">
              <BadgeIcon title={'CREDITS'} />
              <span>{credits}</span>
              <p className="small mt-2">
                {t('global.creditsType')}
                <span style={{ fontWeight: '500' }}>{creditsType}</span>
              </p>
            </div>
          </>
        )}
        <div style={{ height: '25px' }}></div>

        <p className="small mb-3 gray-clr">{t('selfProfile.accountDetails')}</p>

        <UserText
          icon={<HomeOutlined className="primary_clr" />}
          text={userCityCountry}
        />

        <UserText
          icon={<EnvironmentOutlined className="primary_clr" />}
          text={user.address}
        />

        <UserText
          icon={<PhoneOutlined className="primary_clr" />}
          text={user.phone}
        />

        {canChangePassword && (
          <div className="mt-4">
            <Button
              type="default"
              style={{ fontSize: '0.8rem' }}
              onClick={() => setToggleChangePassword(true)}
            >
              {t('selfProfile.changePassword')}
            </Button>
          </div>
        )}
      </div>

      {toggleEditUser && (
        <UserForm
          onEditUser={editUserHandler}
          isVisible={toggleEditUser}
          isSM={isSM}
          isAdding={isAdding}
          onClose={onCloseHandler}
          selectedUser={user}
          dealershipId={dealership.id}
        />
      )}

      {toggleChangePassword && (
        <ChangePasswordForm
          onClose={() => setToggleChangePassword(false)}
          onUpdatePassword={updatePasswordHandler}
          isLoading={isAdding}
        />
      )}
    </>
  );
};

export default UserProfileData;
