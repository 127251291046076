import { CloseOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

export const Backdrop = (props) => {
  const backdropColor =
    props.backdropColor === 'white' ? classes.white_bg : null;

  return (
    <div
      className={`${classes.backdrop} ${backdropColor}`}
      onClick={props.onClose}
    ></div>
  );
};

const ModalOverlay = ({
  onClose,
  children,
  exit = true,
  title = null,
  className,
}) => {
  const _classes = className ? classes[className] : classes['modal'];

  return (
    <div className={_classes}>
      <div
        className={`d-flex ${
          !title ? 'justify-content-end' : 'justify-content-between'
        }`}
      >
        {title && <p>{title}</p>}
        {exit && <CloseOutlined onClick={onClose} />}
      </div>
      <Divider></Divider>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const Modal = (props) => {
  const portalElement = document.getElementById('overlays');

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          exit={props.exit}
          onClose={props.onClose}
          className={props.className}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
