import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Modal } from '../UI';
import moment from 'moment';
import {
  isExpiredDate,
  isNotZeroDate,
  isPastDate,
  setEndOfDay,
} from '../../helpers/_util';

const { Option } = Select;
const dateFormatList = ['DD.MM.YYYY', 'DD/MM/YYYY'];

const FeaturePriceForm = ({
  selectedFeature,
  onClose,
  isLoading,
  onSubmitForm,
}) => {
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();

  const config = {
    rules: [
      {
        message: 'Please select date.',
        required: true,
      },
    ],
  };

  const initialValues = () => {
    let values = {};

    if (selectedFeature.formType === 'edit') {
      values['price'] = selectedFeature.price;
      values['type'] = selectedFeature.type;
      if (isNotZeroDate(selectedFeature?.valid_until)) {
        values['valid_until'] = moment(selectedFeature.valid_until);
      }
    }

    return values;
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onSubmitHandler = () => {
    form.submit();
  };

  const onFinish = async (values) => {
    const { price, type, valid_until } = values;
    const selectedDate = setEndOfDay(valid_until);

    const body = {
      feature: selectedFeature.name.toUpperCase(),
      price,
      type,
      valid_until: selectedDate,
    };

    onSubmitForm(body);
  };

  const isExpired = useMemo(() => {
    return isExpiredDate(selectedFeature.valid_until);
  }, [selectedFeature.valid_until]);

  return (
    <Modal
      title={
        selectedFeature.formType === 'edit'
          ? 'Edit price'
          : `Set price for ${selectedFeature.name} feature`
      }
      onClose={onClose}
    >
      <>
        <Radio.Group className="mb-3" onChange={onChange} value={value}>
          <Radio value={1}>Set only price</Radio>
          <Radio value={2}>Set price & validation date</Radio>
          <Radio value={3}>Set only validation date</Radio>
        </Radio.Group>
        <Form form={form} onFinish={onFinish} initialValues={initialValues()}>
          <Row gutter={16}>
            {[1, 2].includes(value) && (
              <Col xs={24}>
                <Form.Item
                  name="price"
                  rules={[{ required: true, message: 'Price is required.' }]}
                >
                  <InputNumber className="w-100" placeholder="Price" min={0} />
                </Form.Item>
              </Col>
            )}
            {[2, 3].includes(value) && (
              <Col xs={24}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: 'Type is required.' }]}
                >
                  <Select placeholder="Select Payment Type" allowClear>
                    <Option value={'PER_USE'}>Prepaid</Option>
                    <Option value={'MONTHLY'}>Monthly</Option>
                    <Option value={'UNLIMITED'}>Unlimited</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {[2, 3].includes(value) && (
              <Col xs={24}>
                <Form.Item className="w-100" name="valid_until" {...config}>
                  <DatePicker
                    format={dateFormatList}
                    disabledDate={(date) => isPastDate(date.toDate())}
                    placeholder="Valid Until"
                  />
                </Form.Item>
                {isExpired && (
                  <p className="mb-3" style={{ color: 'red' }}>
                    The date has expired.
                  </p>
                )}
              </Col>
            )}
            <Col xs={24}>
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onSubmitHandler}
                >
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};

export default FeaturePriceForm;
