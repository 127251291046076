import {
  CameraOutlined,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React from 'react';
import { BlurHashImage } from '../../UI';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ProfilePicture = ({
  editPicture = false,
  onUploadProfilePicture,
  user,
  isUploading,
}) => {
  const props = {
    name: 'file',
    customRequest(e) {
      onUploadProfilePicture(e.file);
    },
  };

  const profilePicture = user?.profile_picture || null;

  return (
    <div className=" text-center d-flex flex-column align-items-center avatarcic">
      {editPicture && (
        <div className="camera_button_wrapper flex_center">
          <ImgCrop modalOk="Upload" modalTitle="Upload Image">
            <Upload {...props} showUploadList={false}>
              <Button
                type="text"
                className="camera_button flex_center"
                style={{ fontSize: '0.9rem' }}
              >
                <CameraOutlined />
              </Button>
            </Upload>
          </ImgCrop>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '50%',
          // minHeight: '70px'
        }}
      >
        {isUploading && (
          <div>
            <Spin
              className="flex_center"
              style={{
                position: 'absolute',
                zIndex: '9',
                width: '100%',
                height: '100%',
              }}
              indicator={antIcon}
            />
          </div>
        )}
        {profilePicture ? (
          <BlurHashImage
            url={profilePicture?.paths['320x240']}
            blurHash={profilePicture?.blurhash}
            spinner={false}
            imgClass={'avatar'}
            imgWidth={70}
            imgHeight={70}
            previewUrl={profilePicture?.paths.original}
            alt="profile image"
          />
        ) : (
          <Avatar
            className="avatar"
            icon={<UserOutlined />}
            alt="profile image"
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
