import React, { useCallback, useEffect, useState } from 'react';
import { Pagination, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PaginationCount from '../components/pagination/PaginationCount';
import { ContentWrapper, LoadingSpinner, PageHeader } from '../components/UI';
import UsersList from '../components/Users/UsersList';
import { editUser, fetchUsers } from '../store/actions/user-actions';
import { dealershipActions } from '../store/slices/dealership-slice';
import UserForm from '../components/Users/UserForm';
import { useMediaQuery } from 'react-responsive';
import { setPagination } from '../helpers/_util';

const { Option } = Select;

const Users = () => {
  const [searchParams, setSearchParams] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [toggleUserForm, setToggleUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const dispatch = useDispatch();

  const {
    users,
    count: usersCount,
    isLoading,
  } = useSelector((state) => state.users);

  const getUsers = useCallback(
    (pagination, options) => {
      dispatch(
        fetchUsers({
          pagination,
          options,
        })
      );
    },
    [dispatch]
  );

  /**
   * is for getting search parameters for users based
   * on search input value and(or) selected group.
   * @param {*} searchInput - contains user input value in search box.
   * @returns params
   */
  const getSearchParams = (searchInput) => {
    let params = {};

    !!searchInput && (params['name'] = searchInput);
    !!searchInput && (params['email'] = searchInput);

    return params;
  };

  /**
   * is for getting all users.
   * This useEffect is called every time
   * something is changed in the dependency array.
   */
  useEffect(() => {
    let params = getSearchParams(searchParams);

    const controler = new AbortController();
    const { signal } = controler;

    const pagination = setPagination(page, perPage);
    const options = {
      params,
      signal,
    };

    getUsers(pagination, options);

    return () => controler.abort();
  }, [getUsers, page, perPage, searchParams]);

  useEffect(() => {
    dispatch(dealershipActions.setCurrentDealership({ dealership: null }));
  }, [dispatch]);

  const onSearchUsersHandler = (value) => {
    setSearchParams(value);
    setPage(1);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  const changePerPageHandler = (value) => {
    setPerPage(value);
    setPage(1);
  };

  const onEditUser = (user) => {
    setSelectedUser(user);
    setToggleUserForm(true);
  };

  const editUserHandler = async (body) => {
    setIsAdding(true);

    await dispatch(
      editUser({
        userId: selectedUser.id,
        body,
      })
    );

    onCloseHandler();
    setIsAdding(false);
  };

  const onCloseHandler = () => {
    setToggleUserForm(false);
    setSelectedUser(null);
  };

  return (
    <>
      <PageHeader title="Users" onSearch={onSearchUsersHandler} />

      <ContentWrapper>
        {!isLoading ? (
          <div className="container-fluid p-0">
            {users.length > 0 && (
              <>
                <div className="flex_between mb-3 gap-2">
                  <Select onChange={changePerPageHandler} value={perPage}>
                    <Option value={6}>6 / Page</Option>
                    <Option value={12}>12 / Page</Option>
                    <Option value={24}>24 / Page</Option>
                  </Select>
                  <PaginationCount
                    perPage={perPage}
                    page={page}
                    total={usersCount}
                  />
                </div>
                <UsersList
                  onEditUser={onEditUser}
                  users={users}
                  isRootMenu={true}
                />
                <div className="d-flex justify-content-sm-end mt-3">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={perPage}
                    showQuickJumper
                    showSizeChanger={false}
                    total={usersCount}
                    onChange={changePageHandler}
                  />
                </div>
              </>
            )}
            {!users.length && <p>There are no users.</p>}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>

      {toggleUserForm && (
        <UserForm
          onEditUser={editUserHandler}
          isVisible={toggleUserForm}
          isSM={isSM}
          isAdding={isAdding}
          onClose={onCloseHandler}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
};

export default Users;
