import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const findLanguage = (lang) => {
  if (lang.indexOf('-') !== -1) {
    lang = lang.split('-')[0];
  }

  let lng = 'en';
  switch (lang) {
    case 'de':
      lng = 'de';
      break;
    default:
      break;
  }

  return lng;
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    return findLanguage(i18n.language);
  });

  const handleLanguageChange = (value) => {
    const lng = findLanguage(value);
    setLanguage(lng);
    i18n.changeLanguage(value);
    window.location.reload();
  };

  return (
    <Select
      value={language}
      style={{ width: 90 }}
      onChange={handleLanguageChange}
      bordered={false}
      className="me-2"
    >
      <Select.Option value="en">EN</Select.Option>
      <Select.Option value="de">DE</Select.Option>
    </Select>
  );
};

export default LanguageSelector;
