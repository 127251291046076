import { createSlice } from '@reduxjs/toolkit';
import { fetchUser, loginUser } from '../actions/auth-actions';

const getToken = () => {
  const token =
    JSON.parse(localStorage.getItem('token')) ||
    JSON.parse(sessionStorage.getItem('token')) ||
    null;

  return token
    ? {
        ...token,
      }
    : null;
};

const isLoggedIn =
  !!localStorage.getItem('token') || !!sessionStorage.getItem('token');

const rememberMe = !!localStorage.getItem('rememberMe') || false;

const initialState = {
  token: getToken(),
  isLoggedIn,
  loggedInUser: null,
  rememberMe,
  isLoading: false,
  isLoadingUser: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logoutHandler(state) {
      state.token = null;
      state.isLoggedIn = false;
      state.loggedInUser = null;
      state.rememberMe = false;
    },
    setLoggedInUser(state, action) {
      const { user } = action.payload;

      state.loggedInUser = user;
    },
    setToken(state, action) {
      const { token } = action.payload;

      state.rememberMe
        ? localStorage.setItem('token', JSON.stringify(token))
        : sessionStorage.setItem('token', JSON.stringify(token));

      state.token = token;
    },
    setUserProfileImage(state, action) {
      const user = action.payload;

      if (state.loggedInUser.id === user.id) {
        state.loggedInUser = user;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (!action.payload) {
          state.isLoading = false;
          return;
        }

        const { token, rememberMe, user } = action.payload;

        const storage = rememberMe ? localStorage : sessionStorage;

        storage.setItem('token', JSON.stringify(token));

        if (rememberMe) {
          localStorage.setItem('rememberMe', rememberMe);
        }

        state.token = token;
        state.loggedInUser = user;
        state.isLoggedIn = true;
        state.rememberMe = rememberMe;
        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.isLoadingUser = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const user = action.payload;

        if (user) {
          state.loggedInUser = user;
        }
        state.isLoadingUser = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoadingUser = false;
      });
  },
});

export const selectLoggedInUser = (state) => state.auth.loggedInUser;

export const authActions = authSlice.actions;

export default authSlice;
