export const TargetType = Object.freeze({
  EXTERIOR: 'EXTERIOR',
  INTERIOR: 'INTERIOR',
  GLASS: 'GLASS',
  WHEELS: 'WHEELS',
  CLOSE_INSPECTION: 'CLOSE_INSPECTION',
  DETAILS: 'DETAILS',
  DETAILS_LICENCE: 'DETAILS_LICENCE',
  DAMAGES: 'DAMAGES',
});
