import { login } from '../../lib/auth';
import { Roles, UserRoles } from '../../lib/constants/roles';
import { getSelf } from '../../lib/user';
import { authActions } from '../slices/auth-slice';
import { dealershipActions } from '../slices/dealership-slice';

import { toastrError, toastrSuccess } from '../../helpers/toastr';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTheme } from '../../helpers/theme';
import { documentActions } from '../slices/document-slice';
import i18n from 'i18next';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password, rememberMe }, { dispatch }) => {
    const token = await login(email, password);

    const user = await getSelf(token);

    if (!canLogin(user, dispatch)) {
      return;
    }

    toastrSuccess(i18n.t('login.loginSuccess'), 2500);

    if (user.type === Roles.DEALERSHIP) {
      window.BrrmChat('init');
      window.BrrmChat('auth', {
        appAccessToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImM0Y2ZlMGEzLWJkMzctNDAxOC05NWVmLTA5MDI1ZDQyYTQ4MiIsImlhdCI6MTcxNTY4NjY5MH0.yLOfmxVV3Tkh4PPjZsC8IDF4TJBBRFtrXvXd4W3IXTY',
        uniqueId: user.id,
        email: user.email,
        name: user.name,
        groupUniqueId: user?.dealerships[0]?.id,
        groupName: user?.dealerships[0]?.name,
        deviceUniqueId: window.navigator.userAgent,
        isGroupAdmin: true
      });
      window.BrrmChat('showWidget');
    }

    return { token, rememberMe, user };
  }
);

export const logoutUser = () => {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('rememberMe');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('dealership_name');
    sessionStorage.removeItem('dealership_logo');

    setTheme();

    dispatch(dealershipActions.removeDealership());
    dispatch(documentActions.setTitle({ title: 'My Autohouse' }));
    dispatch(documentActions.setLogo());
    dispatch(authActions.logoutHandler());

    window.BrrmChat('removeChat', {});
  };
};

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (_, { dispatch }) => {
    const user = await getSelf();

    if (!canLogin(user, dispatch)) {
      return;
    }

    if (user.type === Roles.DEALERSHIP) {
      window.BrrmChat('init');
      window.BrrmChat('auth', {
        appAccessToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImM0Y2ZlMGEzLWJkMzctNDAxOC05NWVmLTA5MDI1ZDQyYTQ4MiIsImlhdCI6MTcxNTY4NjY5MH0.yLOfmxVV3Tkh4PPjZsC8IDF4TJBBRFtrXvXd4W3IXTY',
        uniqueId: user.id,
        email: user.email,
        name: user.name,
        groupUniqueId: user?.dealerships[0]?.id,
        groupName: user?.dealerships[0]?.name,
        deviceUniqueId: window.navigator.userAgent,
        isGroupAdmin: true
      });
      window.BrrmChat('showWidget');
    }

    // window.BrrmChat('onReady', function() {
    //   console.log('on readyyyy')
    // });

    return user;
  }
);

const canLogin = (user, dispatch) => {
  if (user.dealerships && user.dealerships.length === 1) {
    const dealership = user.dealerships[0];

    // ! slucaj ako je dealership null
    // ! to moze biti ako user ima 1 dealership
    // ! i onda se unassign, ostace bez dealershipa

    if (
      user.type === Roles.DEALERSHIP &&
      dealership.user_dealership.user_role === UserRoles.USER
    ) {
      dispatch(logoutUser());

      toastrError(i18n.t('login.loginError'), 2500);
      return false;
    }

    dispatch(dealershipActions.setCurrentDealership({ dealership }));
  }

  return true;
};
