import { ConfigProvider } from 'antd';

export const setTheme = (color = '#5bb9eb') => {
  ConfigProvider.config({
    theme: {
      primaryColor: color,
    },
  });

  document.documentElement.style.setProperty(`--color-brand-primary`, color);
  document.documentElement.style.setProperty(`--color-text-tertiary`, color);
  document.documentElement.style.setProperty(`--color-ui-primary`, color);
  document.documentElement.style.setProperty(
    `--color-ui-primary-500`,
    `${color}80`
  );
};