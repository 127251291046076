export const Roles = Object.freeze({
  ROOT: 'ROOT',
  DEALERSHIP: 'DEALERSHIP',
});

export const UserRoles = Object.freeze({
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
  USER: 'USER'
});
