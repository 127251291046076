import axios from '../api/axios';

export async function getTargets(options) {
  const response = await axios.get(`/targets`, options);

  // Sort targets by position
  const targets = response.data.sort((a, b) => {
    return a.position - b.position;
  });

  return targets;
}

export async function getTarget(targetId) {
  const response = await axios.get(`/targets/${targetId}`);

  return response.data;
}

export async function createTarget(body) {
  const response = await axios.post(`/targets`, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' },
  });

  return response.data;
}

export async function updateTarget(targetId, body) {
  const response = await axios.patch(
    `/targets/${targetId}`,
    JSON.stringify(body),
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response.data;
}

export async function deleteTarget(targetId) {
  const response = await axios.delete(`/targets/${targetId}`);

  return response.data;
}

export async function uploadTargetImage(targetId, body) {
  const response = await axios.post(`/targets/${targetId}/images`, body, {
    headers: { 'Content-Type': 'application/json' },
  });

  return response.data;
}
