import { List } from 'antd';
import DealershipItem from './DealershipItem';

const DealershipsList = ({ dealerships, onDelete, onEdit }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={dealerships}
      renderItem={(item) => (
        <DealershipItem onDelete={onDelete} onEdit={onEdit} item={item} />
      )}
    />
  );
};

export default DealershipsList;
