import './App.css';
import './assets/css/global.css';
import './assets/css/invoices.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, lazy, Suspense } from 'react';

import { fetchUser } from './store/actions/auth-actions';

import PrivateRoute from './helpers/components/PrivateRoute';
import RoleGuard from './helpers/components/RoleGuard';

import Login from './pages/Login';
import Home from './pages/Home';
import Requests from './pages/Requests';
import UserProfile from './pages/UserProfile';
import DealershipDetail from './pages/DealershipDetail';
import GroupUsers from './pages/GroupUsers';
import SelfProfile from './pages/SelfProfile';
import VehicleDetails from './pages/VehicleDetails';
import Targets from './pages/Targets';
import Users from './pages/Users';
import Dealerships from './pages/Dealerships';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Invoices from './pages/Invoices';
import InvoiceItems from './pages/InvoiceItems';
import Features from './pages/Features';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
import Navigation from './components/Navigation/Navigation';
import Spinner from './components/UI/spinner/Spinner';
import ChooseDealershipModal from './components/Dealerships/ChooseDealershipModal';
import { Roles, UserRoles } from './lib/constants/roles';
import { ToastContainer } from 'react-toastify';
import {
  getGroupsStatus,
  selectAllGroups,
  userGroupActions,
} from './store/slices/user-group-slice';
import { selectCurrentDealership } from './store/slices/dealership-slice';
import { fetchGroups } from './store/actions/user-group-actions';
import { setTheme } from './helpers/theme';
import { documentActions } from './store/slices/document-slice';
import { Helmet } from 'react-helmet-async';
import { LoadingSpinner } from './components/UI';
import useConcurrentTransition from './helpers/hooks/useConcurrentTransition';
import Notification from './components/UI/notification/Notification';
import RequestNotification from './components/UI/notification/RequestNotification';

// const Home = lazy(() => import('./pages/Home'));
// const Requests = lazy(() => import('./pages/Requests'));
// const UserProfile = lazy(() => import('./pages/UserProfile'));
// const DealershipDetail = lazy(() => import('./pages/DealershipDetail'));
// const GroupUsers = lazy(() => import('./pages/GroupUsers'));
// const SelfProfile = lazy(() => import('./pages/SelfProfile'));
// const InvoiceItems = lazy(() => import('./pages/InvoiceItems'));
// const Invoices = lazy(() => import('./pages/Invoices'));
// const VehicleDetails = lazy(() => import('./pages/VehicleDetails'));
// const Dealerships = lazy(() => import('./pages/Dealerships'));
// const Register = lazy(() => import('./pages/Register'));
// const Features = lazy(() => import('./pages/Features'));
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
// const ResetPassword = lazy(() => import('./pages/ResetPassword'));
// const Users = lazy(() => import('./pages/Users'));
// const Targets = lazy(() => import('./pages/Targets'));

function App() {
  const { isLoggedIn, loggedInUser } = useSelector((state) => state.auth);
  const error = useSelector((state) => state.error.error);
  const title = useSelector((state) => state.document.title);
  const logo = useSelector((state) => state.document.logo);
  const currentDealership = useSelector(selectCurrentDealership);

  // ! mogao bih mozda da sredim da ne koristim ovaj groups
  // ! nego da iskoristim loading state za grupe
  // ! jer ovako mi se uvek renderuje app.js kad god se
  // ! promeni grupa neka (ili doda nova)
  // ! .... ili da se sredi normalizacija ?
  const groups = useSelector(selectAllGroups);
  const groupsStatus = useSelector(getGroupsStatus);

  const dispatch = useDispatch();

  /**
   * An indicator of whether the user is authenticated.
   * We need to wait for groups to load, because group
   * can appear in side menu as menu item.
   */
  const isAuthenticated =
    (isLoggedIn && !!loggedInUser && !!groups) || !isLoggedIn;

  const showChooseDealership =
    loggedInUser &&
    loggedInUser.type === Roles.DEALERSHIP &&
    !currentDealership;

  const isDealership = (isLoggedIn && !!currentDealership) || !isLoggedIn;

  const isServerError = error && error.code >= 500;
  const isOffline = !!!navigator.onLine;

  useEffect(() => {
    if (isLoggedIn && !loggedInUser) {
      dispatch(fetchUser());
    }
  }, [dispatch, isLoggedIn, loggedInUser]);

  useEffect(() => {
    if (currentDealership && isLoggedIn) {
      dispatch(fetchGroups(currentDealership.id));

      if (currentDealership.label_color) {
        setTheme(currentDealership.label_color);
      }

      currentDealership.logo &&
        dispatch(
          documentActions.setLogo(currentDealership.logo.paths['320x240'])
        );

      dispatch(documentActions.setTitle({ title: currentDealership.name }));
    }
  }, [dispatch, currentDealership, isLoggedIn]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.type === Roles.ROOT) {
      dispatch(userGroupActions.setGroups({ data: [] }));
    }
  }, [dispatch, loggedInUser]);

  const rootRoutes = (
    <>
      <Route path="/dealerships">
        <Route path="" exact element={<Dealerships />} />
        <Route path=":dealershipId">
          <Route path="" element={<DealershipDetail />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="invoices/:id" element={<InvoiceItems />} />
          <Route path="users" element={<Home />} />
          <Route path="users/:id">
            <Route path="" element={<UserProfile />} />
            <Route path="vehicles/:vehicleId" element={<VehicleDetails />} />
          </Route>
        </Route>
      </Route>
      <Route path="/targets" element={<Targets />}></Route>
      <Route path="/features" element={<Features />}></Route>
      <Route path="/users" element={<Users />} />
      <Route path="users/:id">
        <Route path="" element={<UserProfile />} />
        <Route path="vehicles/:vehicleId" element={<VehicleDetails />} />
      </Route>
    </>
  );

  const adminRoutes = (
    <Route element={<RequestNotification />}>
      <Route path="/clients">
        <Route path="" exact element={<Home />} />
        <Route path=":id">
          <Route path="" element={<UserProfile />} />
          <Route path="vehicles/:vehicleId" element={<VehicleDetails />} />
        </Route>
      </Route>
      <Route path="/groups">
        <Route path=":groupId/users">
          <Route path="" exact element={<GroupUsers />} />
          <Route path=":id">
            <Route path="" element={<UserProfile />} />
            <Route path="vehicles/:vehicleId" element={<VehicleDetails />} />
          </Route>
        </Route>
      </Route>
      <Route path="/requests">
        <Route path="" exact element={<Requests />} />
      </Route>
      <Route path="/profile" element={<SelfProfile />} />
      <Route path="invoices" element={<Invoices />} />
      <Route path="invoices/:id" element={<InvoiceItems />} />
    </Route>
  );

  // const location = useConcurrentTransition()

  return (
    <>
      <Helmet defer={false}>
        <title>{title ? title : 'My Autohouse'}</title>
        <link id="favicon" rel="icon" href={logo} />
      </Helmet>

      <ToastContainer />

      <Notification />

      {isServerError && <ServerError error={error} />}

      {/* {isOffline && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            zIndex: '9999',
            width: '100%',
            background: '#d73232',
            textAlign: 'center',
            padding: '4px 0'
          }}
        >
          <span>It seems that you are offline.</span>
        </div>
      )} */}

      {!isAuthenticated && !isServerError && (
        <Spinner splash={true} backdropColor={'white'} />
      )}

      {showChooseDealership && !isServerError && <ChooseDealershipModal />}

      {isAuthenticated && !isServerError && (
        // <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" exact element={<Navigate to="/login" />} />
          <Route element={<PrivateRoute isAuthenticated={isLoggedIn} />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route element={<PrivateRoute isAuthenticated={isLoggedIn} />}>
            <Route element={<RoleGuard role={Roles.ROOT} />}>
              <Route element={<Navigation />}>{rootRoutes}</Route>
            </Route>

            {isDealership && (
              <Route
                element={
                  <RoleGuard
                    role={Roles.DEALERSHIP}
                    userRole={UserRoles.ADMIN}
                  />
                }
              >
                <Route element={<Navigation />}>{adminRoutes}</Route>
              </Route>
            )}
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        //  </Suspense>
      )}
    </>
  );
}

export default App;
