import { Button, Checkbox, Divider, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { toastrError } from '../../../helpers/toastr';
import { TargetType } from '../../../lib/constants/targetType';
import AdditionalRequests from '../../Requests/AdditionalRequests';
import { Modal } from '../../UI';

import VehicleImagesList from './VehicleImagesList';

import classes from './VehicleImages.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RequestStatus } from '../../../lib/constants/requestStatus';
import { useTranslation } from 'react-i18next';

const VehicleImages = ({ targets, requestStatus, isPreview = false }) => {
  const exterior = targets.filter(
    (target) => target.type === TargetType.EXTERIOR
  );
  const interior = targets.filter(
    (target) => target.type === TargetType.INTERIOR
  );
  const closeInspection = targets.filter(
    (target) => target.type === TargetType.CLOSE_INSPECTION
  );
  const glass = targets.filter((target) => target.type === TargetType.GLASS);
  const wheels = targets.filter((target) => target.type === TargetType.WHEELS);
  const details = targets.filter(
    (target) => target.type === TargetType.DETAILS
  );
  const documents = targets.filter(
    (target) => target.type === TargetType.DETAILS_LICENCE
  );
  const damages = targets.filter(
    (target) => target.type === TargetType.DAMAGES
  );

  const additionalReqTargets = targets.filter(
    (target) => target['image_status'] === RequestStatus.ADDITIONAL_REQ
  );

  const { t } = useTranslation();

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [toggleAdditionalRequests, setToggleAdditionalRequests] =
    useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < targets.length);
    setCheckAll(list.length === targets.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? targets : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const deselectAll = () => {
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
  };

  const onAdditionalRequest = () => {
    if (!checkedList.length) {
      toastrError(t('vehicle.vehicleImages.selectImages'));
      return;
    }

    setToggleAdditionalRequests(true);
  };

  return (
    <>
      {!isPreview && (
        <div className={classes.button_container}>
          <div className="flex_between">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              {t('global.checkAll')}
            </Checkbox>

            <div>
              <Space>
                <Tooltip title={t('vehicle.vehicleImages.additionalReqTitle')}>
                  <InfoCircleOutlined className="primary_clr" />
                </Tooltip>
                <Button type="primary" onClick={onAdditionalRequest}>
                  {t('vehicle.vehicleImages.additionalReq')}
                </Button>
              </Space>
            </div>
          </div>
        </div>
      )}

      {requestStatus === RequestStatus.ADDITIONAL_REQ && (
        <div className={classes.additional_request}>
          {t('vehicle.vehicleImages.additionalReqCount', {
            count: additionalReqTargets.length,
          })}
        </div>
      )}

      <Checkbox.Group className="w-100" onChange={onChange} value={checkedList}>
        {exterior.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.exterior')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={exterior} />
          </>
        )}

        {interior.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.interior')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={interior} />
          </>
        )}

        {glass.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.glass')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={glass} />
          </>
        )}

        {wheels.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.wheels')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={wheels} />
          </>
        )}

        {closeInspection.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.closeInspection')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={closeInspection} />
          </>
        )}

        {details.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.details')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={details} />
          </>
        )}

        {damages.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.damages')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={damages} />
          </>
        )}

        {documents.length > 0 && (
          <>
            <Divider orientation="left">
              {t('vehicle.vehicleImages.documents')}
            </Divider>
            <VehicleImagesList isPreview={isPreview} targets={documents} />
          </>
        )}
      </Checkbox.Group>

      {toggleAdditionalRequests && (
        <Modal
          title={t('vehicle.vehicleImages.sendAdditionalReq')}
          onClose={() => setToggleAdditionalRequests(false)}
        >
          <AdditionalRequests
            checkedTargets={checkedList}
            onDeselect={deselectAll}
            onClose={() => setToggleAdditionalRequests(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default VehicleImages;
