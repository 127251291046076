import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupsList from '../components/user-groups/GroupsList';
import GroupForm from '../components/user-groups/GroupForm';
import { Avatar, Button, Col, Divider, Row } from 'antd';
import {
  addGroup,
  editGroup,
  removeGroup,
} from '../store/actions/user-group-actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfirmModal, ContentWrapper } from '../components/UI';
import DealershipProfile from '../components/Dealerships/dealership-profile/DealershipProfile';
import UserProfileData from '../components/Users/user-profile/UserProfileData';
import ProfilePicture from '../components/Users/user-profile/ProfilePicture';
import { uploadUserImage } from '../store/actions/user-actions';
import { toastrSuccess } from '../helpers/toastr';
import { selectAllGroups } from '../store/slices/user-group-slice';
import { selectCurrentDealership } from '../store/slices/dealership-slice';
import { selectLoggedInUser } from '../store/slices/auth-slice';
import { useTranslation } from 'react-i18next';

const SelfProfile = () => {
  const groups = useSelector(selectAllGroups);
  const dealership = useSelector(selectCurrentDealership);
  const loggedInUser = useSelector(selectLoggedInUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const editGroupHandler = (group) => {
    setSelectedGroup(group);
    setIsOpen(true);
  };

  const onDeleteGroup = (group) => {
    setSelectedGroup(group);
    setIsDelete(true);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
    setIsDelete(false);
    setSelectedGroup(null);
  };

  const createGroupHandler = async (group) => {
    setIsLoading(true);

    try {
      await dispatch(addGroup({ id: dealership.id, group }));

      onCloseHandler();
    } catch (err) {}

    setIsLoading(false);
  };

  const updateGroupHandler = async (group) => {
    setIsLoading(true);

    try {
      await dispatch(
        editGroup({
          dealershipId: dealership.id,
          groupId: selectedGroup.id,
          body: group,
        })
      );

      onCloseHandler();
    } catch (err) {}

    setIsLoading(false);
  };

  const deleteGroupHandler = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        removeGroup({ dealershipId: dealership.id, groupId: selectedGroup.id })
      );
    } catch (err) {}

    setIsLoading(false);
    setIsDelete(false);
    setSelectedGroup(null);
  };

  const uploadProfilePictureHandler = async (file) => {
    let formData = new FormData();

    formData.append('profile_picture', file);

    setIsUploading(true);

    await dispatch(
      uploadUserImage({ userId: loggedInUser.id, body: formData })
    );

    toastrSuccess(t('selfProfile.imageUploadSuccess'));

    setIsUploading(false);
  };

  return (
    <>
      <div className="picture_background">
        <ProfilePicture
          editPicture={true}
          onUploadProfilePicture={uploadProfilePictureHandler}
          user={loggedInUser}
          isUploading={isUploading}
        />
      </div>

      <ContentWrapper>
        <div className="a">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={10} lg={{ span: 8 }}>
              <div className="card_wrapper">
                <UserProfileData
                  user={loggedInUser}
                  isSelfProfile={true}
                  canChangePassword={true}
                />
              </div>
            </Col>
            <Col xs={24} md={14} lg={{ span: 14, offset: 2 }}>
              <div className="card_wrapper">
                <DealershipProfile dealership={dealership} />

                <Divider orientation="left">{t('selfProfile.groups')}</Divider>
                <p className="small mb-2">
                  {t('selfProfile.groupsDescription')}
                </p>
                <div className="text-end">
                  <Button onClick={() => setIsOpen(true)} type="primary">
                    {t('selfProfile.newGroup')}
                  </Button>
                </div>
                <GroupsList
                  onDeleteGroup={onDeleteGroup}
                  onEditGroup={editGroupHandler}
                  groups={groups}
                />
              </div>
            </Col>
          </Row>
        </div>
      </ContentWrapper>

      {isOpen && (
        <GroupForm
          onCreateGroup={createGroupHandler}
          onUpdateGroup={updateGroupHandler}
          selectedGroup={selectedGroup}
          onClose={onCloseHandler}
          isLoading={isLoading}
        />
      )}
      {isDelete && (
        <ConfirmModal
          title={t('selfProfile.deleteGroup')}
          buttonText={t('global.form.delete')}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          danger={true}
          description={
            <>
              {t('selfProfile.deleteGroupDescription')}
              <span style={{ fontWeight: 'bold' }}>{selectedGroup.name}</span> ?
            </>
          }
          onClose={onCloseHandler}
          onConfirm={deleteGroupHandler}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default SelfProfile;
