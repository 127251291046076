import { useEffect, useState } from 'react';

const useResize = (myRef, isLoaded = false) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [init, setInit] = useState(true);

  useEffect(() => {
    if (myRef.current && !isLoaded) {
      const handleResize = () => {
        // const newWidth = myRef.current.offsetWidth;
        // if (newWidth !== width) {
        // }
        setWidth(myRef.current.offsetWidth);

        // const newHeight = myRef.current.offsetHeight;
        // if (newHeight !== height) {
        // }
        setHeight(myRef.current.offsetHeight);
      };

      if (init) {
        handleResize();
        setInit(false);
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [myRef, isLoaded, init]);

  return { width, height };
};

export default useResize;
