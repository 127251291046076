import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Is for displaying success toastr
 * @param {*} text - is for display text on toastr (e.g. "Success", "User created")
 * @param {*} autoClose - is for autoClose time
 * @returns
 */
export const toastrSuccess = (text, autoClose = 4000) =>
  toast.success(text, {
    position: 'top-center',
    autoClose,
    hideProgressBar: true,
    progress: undefined,
    transition: Zoom,
  });

/**
 * Is for displaying error toastr
 * @param {*} text - is for display text on toastr (e.g. "Failed")
 * @param {*} autoClose - is for autoClose time
 * @returns
 */
export const toastrError = (text, autoClose = 4000) =>
  toast.error(text, {
    position: 'top-center',
    autoClose,
    hideProgressBar: true,
    progress: undefined,
    transition: Zoom,
  });
