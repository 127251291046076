import axios from '../api/axios';

export async function getAllInvoices({ dealershipId, pagination, options }) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  options['headers'] = headers;

  const response = await axios.get(
    `/dealerships/${dealershipId}/invoices`,
    options
  );

  const data = {
    invoices: response.data,
    count: response.headers['pagination-count'] || 0,
  };

  return data;
}

export async function getInvoice({ dealershipId, invoiceId }) {
  const response = await axios.get(
    `/dealerships/${dealershipId}/invoices/${invoiceId}`
  );

  return response.data;
}

export async function getInvoiceItems({ dealershipId, invoiceId, pagination }) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  const response = await axios.get(
    `/dealerships/${dealershipId}/invoices/${invoiceId}/items`,
    { headers }
  );

  const data = {
    items: response.data,
    count: response.headers['pagination-count'] || 0,
  };

  return data;
}

export async function payInvoice({ dealershipId, invoiceId, body }) {
  const response = await axios.patch(
    `/dealerships/${dealershipId}/invoices/${invoiceId}/pay`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function closeInvoice({ dealershipId, invoiceId }) {
  const response = await axios.patch(
    `/dealerships/${dealershipId}/invoices/${invoiceId}/close`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}
