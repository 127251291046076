import { useSelector, useDispatch } from 'react-redux';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import { dealershipActions } from '../../store/slices/dealership-slice';
import { Modal } from '../UI';
import classes from './ChooseDealershipModal.module.css';

const ChooseDealershipModal = ({ user }) => {
  const loggedInUser = useSelector(selectLoggedInUser);

  const selectedUser = user || loggedInUser;

  const dispatch = useDispatch();

  const selectDealershipHandler = (dealership) => {
    dispatch(dealershipActions.setCurrentDealership({ dealership }));
  };

  // ! prikazati samo dealershipe u kojima je user ADMIN, izbaciti gde je USER
  return (
    <div className={classes.dealership_modal_layer}>
      <Modal exit={false}>
        <h5>Choose Dealership</h5>
        {selectedUser.dealerships.map((dealership, index) => (
          <div
            key={index}
            className={classes.dealership}
            onClick={() => selectDealershipHandler(dealership)}
          >
            <p>
              {index + 1}. {dealership.name}
            </p>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default ChooseDealershipModal;
