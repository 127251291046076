import RequestsList from '../components/Requests/RequestsList';
import {
  Divider,
  Select,
  Pagination,
  Form,
  Input,
  Checkbox,
  Dropdown,
  Menu,
  Space,
  Button,
  Popconfirm,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  editRequest,
  fetchRequests,
  removeRequests,
} from '../store/actions/request-actions';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { RequestStatus } from '../lib/constants/requestStatus';
import {
  PageHeader,
  ConfirmModal,
  ContentWrapper,
  LoadingSpinner,
  ThreeDots,
} from '../components/UI';
import { useMediaQuery } from 'react-responsive';
import PaginationCount from '../components/pagination/PaginationCount';
import { selectAllGroups } from '../store/slices/user-group-slice';
import {
  dealershipActions,
  selectCurrentDealership,
} from '../store/slices/dealership-slice';
import { Status } from '../lib/constants/status';
import { Payments } from '../lib/constants/payments';
import { setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { toastrSuccess } from '../helpers/toastr';

const { Option } = Select;
const { TextArea } = Input;

const Requests = () => {
  const [searchQuery, setSearchQuery] = useSearchParams();

  const [onAccept, setOnAccept] = useState(false);
  const [onReject, setOnReject] = useState(false);
  const [editRequestStatus, setEditRequestStatus] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [reason, setReason] = useState(null);

  const selectedStatus = useMemo(() => {
    return searchQuery.get('status') || 'all';
  }, [searchQuery]);
  // const [selectedStatus, setSelectedStatus] = useState(() => {
  //   return searchQuery.get('status') || 'all';
  // });
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [searchParams, setSearchParams] = useState('');

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const {
    requests,
    count: requestsCount,
    isLoading,
  } = useSelector((state) => state.requests);
  const groups = useSelector(selectAllGroups);
  const currentDealership = useSelector(selectCurrentDealership);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [checkedList, setCheckedList] = useState([]);

  const onChange = (list) => {
    setCheckedList(list);
  };

  const handleCheckAll = () => {
    const filteredRequests = requests.filter((req) =>
      [
        RequestStatus.ACCEPTED,
        RequestStatus.ADDITIONAL_REQ,
        RequestStatus.REJECTED,
      ].includes(req.status)
    );

    setCheckedList(filteredRequests);
  };

  const menu = (
    <Menu style={{ minWidth: '165px' }}>
      <Menu.Item onClick={handleCheckAll} key="0">
        {t('requests.selectAll')}
      </Menu.Item>
    </Menu>
  );

  /**
   * is for getting search parameters for requests based
   * on search input value and(or) selected group.
   * @param {*} searchInput - contains user input value in search box.
   * @param {*} status - contains status of the requests
   * @param {*} selectedGroup - contains selected groupId
   * @returns params
   */
  const getSearchParams = (searchInput, status, selectedGroup) => {
    let params = {};

    !!searchInput && (params['search'] = searchInput);
    selectedGroup !== 'all' &&
      (params['group_id'] = selectedGroup.toString());

    status !== 'all' && (params['status'] = status);

    return params;
  };

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const getDealershipRequests = (signal) => {
      let params = getSearchParams(searchParams, selectedStatus, selectedGroup);

      const pagination = setPagination(page, perPage);

      const options = {
        params,
        signal,
      };

      dispatch(
        fetchRequests({
          dealershipId: currentDealership.id,
          pagination,
          options,
        })
      );
    };

    getDealershipRequests(signal);
    setCheckedList([]);

    return () => controler.abort();
  }, [
    currentDealership.id,
    dispatch,
    page,
    perPage,
    searchParams,
    selectedGroup,
    selectedStatus,
  ]);

  function selectStatusHandler(value) {
    value !== 'all'
      ? setSearchQuery({ status: value })
      : setSearchQuery(searchQuery.delete('status'));
  }

  function selectGroupHandler(value) {
    setSelectedGroup(value);
  }

  const statusText = t('requests.allRequests');

  const onRequestHandler = (request, status) => {
    setSelectedRequest(request);
    if (status === 'ACCEPT') {
      setOnAccept(true);
    } else if (status === 'REJECT') {
      setOnReject(true);
    }
  };

  const onCloseHandler = () => {
    setOnAccept(false);
    setOnReject(false);
    setSelectedRequest(null);
    setReason('');
  };

  const changeRequestStatusHandler = async (status) => {
    setEditRequestStatus(true);

    let body = { status };

    if (status === RequestStatus.REJECTED && reason) {
      body['comment'] = reason;
    }

    try {
      await dispatch(
        editRequest({
          userId: selectedRequest.user_id,
          requestId: selectedRequest.id,
          body,
        })
      );

      // if accepted, decrement credits by amount
      // ! ispitati i da li je admin? nije predvidjeno da root ovo uradi
      // ! jer root nema currentDealership
      // ! ---------------------
      // ! da li ovde treba skinuti kredite i za scan_document
      // ! naci niz feature-a i izvuci cene i onda if(arr.length)....
      if (
        status === Status.ACCEPTED &&
        currentDealership.price_type !== Payments.UNLIMITED
      ) {
        const price = currentDealership.prices.find(
          (price) => price.feature === 'EXCHANGE'
        );

        if (price) {
          dispatch(
            dealershipActions.decrementCredits({ credits: price.price })
          );
        }
      }

      onCloseHandler();
    } catch (err) {}

    setEditRequestStatus(false);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  const changePerPageHandler = (value) => {
    setPerPage(value);
    setPage(1);
  };

  const onSearchRequestsHandler = (value) => {
    setSearchParams(value);
    setPage(1);
  };

  const handleDeleteRequests = async () => {
    const body = {
      request_ids: checkedList.map((request) => request.id),
    };

    try {
      await dispatch(
        removeRequests({ dealershipId: currentDealership.id, body })
      );

      setCheckedList([]);
      toastrSuccess(t('requests.requestsAreDeleted'));

      let params = getSearchParams(searchParams, selectedStatus, selectedGroup);

      const pagination = setPagination(page, perPage);

      const options = {
        params,
      };

      dispatch(
        fetchRequests({
          dealershipId: currentDealership.id,
          pagination,
          options,
        })
      );
    } catch {}
  };

  return (
    <>
      <PageHeader
        title={statusText}
        showButton={false}
        onSearch={onSearchRequestsHandler}
        extras={
          <>
            {!isSM && <div className="separator"></div>}
            <div className={isSM ? 'filter_container mb-3' : ''}>
              {isSM && <label>{t('requests.userGroup')}</label>}
              <Select
                defaultValue="all"
                value={selectedGroup}
                onChange={selectGroupHandler}
                name="groups"
                id="groups"
                bordered={false}
                className="select_box"
              >
                <Option value="all">{t('requests.allGroups')}</Option>
                {!!groups.length &&
                  groups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.name}
                    </Option>
                  ))}
                <Option value="null">{t('users.selfRegistered')}</Option>
              </Select>
            </div>

            {!isSM && <Divider type="vertical"></Divider>}

            <div className={isSM ? 'filter_container' : ''}>
              {isSM && <label>{t('requests.requestStatus')}</label>}
              <Select
                defaultValue="all"
                value={selectedStatus}
                style={{ width: 130 }}
                onChange={selectStatusHandler}
                id="status"
                bordered={false}
                className="select_box"
              >
                <Option value="all">{t('requests.allStatus')}</Option>
                <Option value="PENDING">{t('requests.pending')}</Option>
                <Option value="ACCEPTED">{t('requests.accepted')}</Option>
                <Option value="REJECTED">{t('requests.rejected')}</Option>
                <Option value="ADDITIONAL_REQUEST">
                  {t('requests.additionalRequests')}
                </Option>
              </Select>
            </div>
          </>
        }
      />

      <ContentWrapper>
        {!isLoading ? (
          <>
            {!requests.length && (
              <p className="not_found_text">{t('requests.noRequests')}</p>
            )}
            {requests.length > 0 && (
              <>
                {checkedList.length ? (
                  <div className="flex_between mb-3 gap-2">
                     <span>
                      {t('requests.selectedInfo', {
                        count: checkedList.length,
                      })}
                    </span>
                    <Space>
                      <Button onClick={() => setCheckedList([])}>
                        {t('global.form.dismiss')}
                      </Button>
                      <Popconfirm
                        placement="bottomRight"
                        title={t('requests.deleteRequestsInfo')}
                        onConfirm={handleDeleteRequests}
                        okText={t('global.form.yes')}
                        cancelText={t('global.form.no')}
                      >
                        <Button type="danger">{t('global.form.delete')}</Button>
                      </Popconfirm>
                    </Space>
                  </div>
                ) : (
                  <div className="flex_between mb-3 gap-2">
                    <Select onChange={changePerPageHandler} value={perPage}>
                      <Option value={6}>{t('global.page6')}</Option>
                      <Option value={12}>{t('global.page12')}</Option>
                      <Option value={24}>{t('global.page24')}</Option>
                    </Select>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: '1rem' }}
                    >
                      <PaginationCount
                        perPage={perPage}
                        page={page}
                        total={requestsCount}
                      />
                      <Dropdown
                        placement="bottomRight"
                        trigger={['click']}
                        overlay={menu}
                      >
                        <ThreeDots />
                      </Dropdown>
                    </div>
                  </div>
                )}
                <Checkbox.Group
                  className="w-100"
                  onChange={onChange}
                  value={checkedList}
                >
                  <RequestsList
                    allRequests={requests}
                    onRequest={onRequestHandler}
                  />
                </Checkbox.Group>
                <div className="d-flex justify-content-sm-end mt-3">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={perPage}
                    showQuickJumper
                    showSizeChanger={false}
                    total={requestsCount}
                    onChange={changePageHandler}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>

      {onAccept && (
        <ConfirmModal
          title={t('requests.acceptRequest')}
          icon={
            <CheckCircleOutlined
              style={{ fontSize: '1.3rem', color: 'var(--color-ui-ok)' }}
            />
          }
          buttonText={t('requests.accept')}
          description={<>{t('requests.acceptRequestInfo')}</>}
          onClose={onCloseHandler}
          isLoading={editRequestStatus}
          onConfirm={() => changeRequestStatusHandler(RequestStatus.ACCEPTED)}
        />
      )}

      {onReject && (
        <ConfirmModal
          title={t('requests.rejectRequest')}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          buttonText={t('requests.reject')}
          danger={true}
          description={
            <>
              {t('requests.rejectRequestInfo', {
                userName: selectedRequest.user.name,
                make: selectedRequest.vehicle.make,
                model: selectedRequest.vehicle.model,
              })}
            </>
          }
          form={
            <>
              <Form.Item>
                <TextArea
                  placeholder={t('requests.rejectReason')}
                  autoSize={{
                    minRows: 2,
                    maxRows: 6,
                  }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Item>
            </>
          }
          onClose={onCloseHandler}
          isLoading={editRequestStatus}
          onConfirm={() => changeRequestStatusHandler(RequestStatus.REJECTED)}
        />
      )}
    </>
  );
};

export default Requests;
