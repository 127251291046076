import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createGroup,
  deleteGroup,
  getGroups,
  updateGroup,
} from '../../lib/groups';

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async (id) => {
    const response = await getGroups(id);
    return response;
  }
);

export const addGroup = createAsyncThunk(
  'groups/addGroup',
  async ({ id, group }) => {
    const newGroup = await createGroup(id, group);
    return newGroup;
  }
);

export const editGroup = createAsyncThunk(
  'groups/editGroup',
  async ({ dealershipId, groupId, body }) => {
    const editGroup = await updateGroup(dealershipId, groupId, body);

    return editGroup;
  }
);

export const removeGroup = createAsyncThunk(
  'groups/removeGroup',
  async ({ dealershipId, groupId }) => {
    await deleteGroup(dealershipId, groupId);

    return groupId;
  }
);
