import React from 'react';
import classes from './LoginCard.module.css';

const LoginCard = ({ children, transition }) => {
  const _classes = transition
    ? [classes.login_card, classes.transition]
    : [classes.login_card];

  return <div className={_classes.join(' ')}>{children}</div>;
};

export default LoginCard;
