import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoadingSpinner = () => {
  return (
    <div className="flex_center" style={{ transform: 'translateY(60px)' }}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingSpinner;
