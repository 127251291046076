import React from 'react';

import classes from './AuthLayout.module.css';

const AuthLayout = ({ children }) => {
  return (
    <>
      <div className={classes.auth_background}></div>
      <div className={classes.auth_container}>{children}</div>
    </>
  );
};

export default AuthLayout;
