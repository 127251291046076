import React from 'react';
import GroupItem from './GroupItem';
import { List } from 'antd';

const GroupsList = ({ groups, onEditGroup, onDeleteGroup }) => {

  return (
    <List
      itemLayout="horizontal"
      dataSource={groups}
      renderItem={(item) => (
        <GroupItem
          onDeleteGroup={onDeleteGroup}
          onEditGroup={onEditGroup}
          group={item}
        />
      )}
    />
  );
};

export default GroupsList;
