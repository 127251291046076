import { Checkbox, Col, Image, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useResize from '../../../helpers/hooks/useResize';
import { BlurHashImage } from '../../UI';

import classes from './VehicleImagesList.module.css';

const VehicleImagesList = ({ targets, isPreview = false }) => {
  const [height, setHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const componentRef = useRef();
  const { width } = useResize(componentRef, isLoaded);
  const w = width;
  console.log('rend');
  useEffect(() => {
    if (w) {
      // 1.778 - 16:9
      const h = w / 1.33;
      setHeight(Math.round(h));
    }
  }, [w, componentRef, width]);

  const changeLoadinghandler = (isLoaded) => {
    setIsLoaded(isLoaded);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <Row
      className={classes.test}
      gutter={[8, 8]}
      style={{ overflowY: 'scroll', maxHeight: '170px' }}
    >
      <Image.PreviewGroup>
        {targets.map((target, index) => (
          <Col key={target.id} xs={8} sm={6} md={6} lg={6} xl={6}>
            <div ref={componentRef}>
              <BlurHashImage
                url={target.uploaded_image?.paths?.original}
                blurHash={target.uploaded_image?.blurhash}
                blurhashClass={'vehicle_image'}
                imgHeight={height}
                imgWidth={w}
                loaded={changeLoadinghandler}
                isCustomHeight={true}
                alt="vehicle image"
              />
              {!isPreview && (
                <div className="text-center">
                  <Checkbox value={target} />
                </div>
              )}
            </div>
          </Col>
        ))}
      </Image.PreviewGroup>
    </Row>
  );
};

export default VehicleImagesList;
