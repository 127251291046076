import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isNumberOrZero } from '../../helpers/_util';
import {
  addDealershipPrice,
  editDealershipPrice,
  removeDealershipPrice,
} from '../../store/actions/dealership-actions';
import { ConfirmModal } from '../UI';
import FeaturePriceForm from './FeaturePriceForm';

const FeaturePrices = ({
  prices,
  onClose,
  isVisible,
  isSM,
  allFeatures,
  initFeatures,
  dealershipId,
  onSetDealership,
}) => {
  let content;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [togglePriceForm, setTogglePriceForm] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  initFeatures = initFeatures.map((feature) => {
    if (!prices) {
      return {
        ...feature,
        price: null,
        type: null,
        valid_until: null,
        isDefault: false,
      };
    }

    const featurePrice = prices.find((d) => d.feature === feature.name);

    return {
      ...feature,
      price:
        featurePrice && !featurePrice.is_default ? featurePrice.price : null,

      isDefault: featurePrice && featurePrice.is_default ? true : false,
      valid_until: featurePrice?.valid_until || null,
      type: featurePrice?.type || null,
    };
  });
  // prices = prices.filter((price) => price.name && !price.is_default);

  const onDeleteHandler = (price) => {
    setSelectedFeature(price);
    setToggleDelete(true);
  };

  const onPriceFormHandler = (formType, feature) => {
    setSelectedFeature({ formType, ...feature });
    setTogglePriceForm(true);
  };

  const deletePrice = async () => {
    setIsLoading(true);

    const body = {
      feature: selectedFeature.name.toUpperCase(),
    };

    try {
      const data = await dispatch(
        removeDealershipPrice({ dealershipId, body })
      ).unwrap();

      onSetDealership(data);
      setIsLoading(false);
      onCloseHandler();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onCloseHandler = () => {
    setToggleDelete(false);
    setTogglePriceForm(false);
    setSelectedFeature(null);
  };

  const submitPriceHandler = async (body) => {
    setIsLoading(true);

    try {
      if (selectedFeature.formType === 'edit') {
        const data = await dispatch(
          editDealershipPrice({ dealershipId, body })
        ).unwrap();
        onSetDealership(data);
      } else {
        const data = await dispatch(
          addDealershipPrice({ dealershipId, body })
        ).unwrap();
        onSetDealership(data);
      }

      setIsLoading(false);
      onCloseHandler();
    } catch (err) {
      setIsLoading(false);
    }
  };

  if (initFeatures && initFeatures.length) {
    content = initFeatures.map((feature, index) => (
      <div
        key={index}
        style={{ padding: '5px', background: '#f7f7f7' }}
        className="flex_between mb-2"
      >
        <p>
          {feature.displayName}{' '}
          {!feature.isDefault && isNumberOrZero(feature.price) && (
            <span>- {feature.price} credits</span>
          )}
        </p>
        <Space>
          {(!isNumberOrZero(feature.price) || feature.isDefault) && (
            <Button
              type="primary"
              onClick={() => onPriceFormHandler('add', feature)}
              icon={<PlusOutlined />}
            />
          )}
          {isNumberOrZero(feature.price) && !feature.isDefault && (
            <>
              <Button
                type="ghost"
                onClick={() => onPriceFormHandler('edit', feature)}
                icon={<EditOutlined />}
              />
              <Button
                type="danger"
                onClick={() => onDeleteHandler(feature)}
                icon={<DeleteOutlined />}
              />
            </>
          )}
        </Space>
      </div>
    ));
  } else {
    content = <p>There are no custom prices yet.</p>;
  }

  return (
    <>
      <Drawer
        title={"Dealership's Custom Prices"}
        width={isSM ? '100%' : 545}
        onClose={onClose}
        visible={isVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onClose}>Cancel</Button>}
      >
        {content}
      </Drawer>

      {toggleDelete && (
        <ConfirmModal
          title={'Remove custom price'}
          buttonText={'Remove'}
          description={
            <>
              Are you sure you want to delete a custom price for{' '}
              {selectedFeature.name.toLowerCase()} feature?
            </>
          }
          onClose={onCloseHandler}
          isLoading={isLoading}
          onConfirm={deletePrice}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
        />
      )}

      {togglePriceForm && (
        <FeaturePriceForm
          selectedFeature={selectedFeature}
          onClose={onCloseHandler}
          isLoading={isLoading}
          onSubmitForm={submitPriceHandler}
        />
      )}
    </>
  );
};

export default FeaturePrices;
