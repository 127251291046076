import { Link } from 'react-router-dom';
import { BadgeIcon, BlurHashImage } from '../UI';
import { CarOutlined, PictureOutlined } from '@ant-design/icons';
import classes from './VehicleItem.module.css';
import { RequestStatus } from '../../lib/constants/requestStatus';
import DefaultBadge from '../UI/default-badge/DefaultBadge';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

const VehicleItem = ({ request }) => {
  const { vehicle } = request;

  const { t } = useTranslation();

  const { preview_image: previewImage } = request;

  return (
    <div className="p-3 pb-0" style={{ position: 'relative' }}>
      <Link to={`vehicles/${request.id}`}>
        <div className={`${classes.vehicle_card}`}>
          <div
            className={`${classes.vehicle_content} d-flex align-items-center gap-3`}
          >
            <div className={`${classes.vehicle_image}`}>
              {previewImage ? (
                <>
                  <div className={classes.pictures_number}>
                    <PictureOutlined />
                    <span>{request?.images_count}</span>
                  </div>
                  <BlurHashImage
                    url={previewImage?.paths?.original}
                    blurHash={previewImage?.blurhash}
                    spinner={false}
                    preview={false}
                    imgWidth={160}
                    imgHeight={120}
                    imgClass={'bordered_image'}
                    blurhashClass={'bordered_image'}
                    alt="vehicle image"
                  />
                </>
              ) : (
                <div className={classes.no_image}>
                  <CarOutlined />
                </div>
              )}
            </div>
            <div className={`${classes.vehicle_data}`}>
              {request.status === RequestStatus.ADDITIONAL_REQ && (
                <div>
                  <DefaultBadge
                    color={'#51bb7b'}
                    text={t('vehicle.vehicleImages.additionalReq')}
                  />
                </div>
              )}
              <p
                className="no_wrap_text"
                style={{ color: 'var(--color-ui-primary)' }}
              >
                {vehicle?.make || '-'} {vehicle?.model || '-'}
              </p>
              <p className="small" style={{ color: 'silver' }}>
                {vehicle?.year || '-'}
              </p>
              <p className="my-2 small">
                <BadgeIcon title={t('vehicle.vin')} />
                {vehicle?.vin || '-'}
              </p>
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <p className={`${classes.smoke_color} small`}>
                    {t('vehicle.mileage')}
                  </p>
                  <p>{vehicle?.mileage || '-'} Km</p>
                </div>
                <div className="d-flex flex-column">
                  <p className={`${classes.smoke_color} small`}>
                    {t('vehicle.engineCCM')}
                  </p>
                  <p>{vehicle?.engine_displacement || '-'} CCM</p>
                </div>
                <div className="d-flex flex-column">
                  <p className={`${classes.smoke_color} small`}>
                    {t('vehicle.engineHPKW')}
                  </p>
                  <p>{vehicle?.engine_power || '-'} KW</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Checkbox
        value={request}
        style={{ position: 'absolute', right: '10px', top: '10px' }}
      />
    </div>
  );
};

export default VehicleItem;
