import React from 'react';

const InvoiceRibbon = ({ text, icon, classes }) => {
  const classNames = `flex_center ribbon_invoice ${classes}`;

  return (
    <div className={classNames}>
      {icon}
      <span>{text}</span>
    </div>
  );
};

export default InvoiceRibbon;
