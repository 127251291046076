import React from 'react';
import { Button } from 'antd';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({
  title,
  description,
  icon,
  buttonText,
  onClose,
  onConfirm,
  danger,
  isLoading,
  form
}) => {
  const { t } = useTranslation();
  return (
    <Modal title={title ? title : null} onClose={onClose}>
      <div className="d-flex align-items-center mb-4">
        {icon}
        <p className="ms-3">{description}</p>
      </div>
      {form && (
        <div>{form}</div>
      )}
      <div className="d-flex justify-content-end">
        <Button disabled={isLoading} onClick={onClose}>
          {t('global.form.close')}
        </Button>
        <Button
          loading={isLoading}
          onClick={onConfirm}
          className="ms-2"
          type="primary"
          danger={danger}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
