import { Pagination } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ContentWrapper, LoadingSpinner, PageHeader } from '../components/UI';
import { fetchInvoices } from '../store/actions/invoice-actions';
import { selectCurrentDealership } from '../store/slices/dealership-slice';
import InvoicesList from '../components/invoices/InvoicesList';
import { setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';

const Invoices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentDealership = useSelector(selectCurrentDealership);
  const {
    isLoading,
    count: invoicesCount,
    invoices,
  } = useSelector((state) => state.invoices);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);

  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  let invoicess = [
    {
      amount: 57401,
      credits: 0,
      id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
      number: 69,
      closed: true,
      closedAt: '2021-11-16T22:12:11.695Z',
      paidAmount: '57401',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
      type: 'postpaid',
      createdAt: '2021-09-13T09:13:28.297Z',
      updatedAt: '2021-11-16T22:12:11.696Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
    {
      amount: 57401,
      credits: 10,
      id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
      number: 69,
      closed: false,
      closedAt: '2021-11-16T22:12:11.695Z',
      paidAmount: '57300',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
      type: 'postpaid',
      createdAt: '2021-09-13T09:13:28.297Z',
      updatedAt: '2021-11-16T22:12:11.696Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
    {
      amount: 57401,
      credits: 0,
      id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
      number: 69,
      closed: true,
      closedAt: '2021-11-16T22:12:11.695Z',
      paidAmount: '57401',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
      type: 'prepaid',
      createdAt: '2021-09-13T09:13:28.297Z',
      updatedAt: '2021-11-16T22:12:11.696Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
    {
      amount: 57401,
      credits: 0,
      id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
      number: 69,
      closed: false,
      closedAt: '2021-11-16T22:12:11.695Z',
      paidAmount: '0',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
      type: 'prepaid',
      createdAt: '2021-09-13T09:13:28.297Z',
      updatedAt: '2021-11-16T22:12:11.696Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
    {
      amount: 30,
      credits: 0,
      id: '7f63deb5-e640-4644-8e50-93c1fde648af',
      number: 68,
      closed: true,
      closedAt: '2021-11-17T13:00:33.584Z',
      paidAmount: '30',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/7f63deb5-e640-4644-8e50-93c1fde648af%7D/f9e30b84-a541-4002-8ce7-3236131cf823.csv',
      type: 'postpaid',
      createdAt: '2021-09-13T09:13:28.285Z',
      updatedAt: '2021-11-17T13:00:33.585Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
    {
      amount: 57401,
      credits: 0,
      id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
      number: 69,
      closed: false,
      closedAt: '2021-11-16T22:12:11.695Z',
      paidAmount: '0',
      csvStatus: 'completed',
      csvLink:
        'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
      type: 'postpaid',
      createdAt: '2021-09-13T09:13:28.297Z',
      updatedAt: '2021-11-16T22:12:11.696Z',
      deletedAt: null,
      clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
      customerId: null,
      userId: null,
    },
  ];

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const pagination = setPagination(page, perPage);
    const options = { signal };

    dispatch(
      fetchInvoices({
        dealershipId,
        pagination,
        options,
      })
    );

    return () => controler.abort();
  }, [dispatch, dealershipId, page, perPage]);

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <PageHeader title={t('invoices.invoices')} showSearch={false} />

      <ContentWrapper>
        {!isLoading ? (
          <div className="container-fluid p-0">
            {invoices.length > 0 ? (
              <>
                <InvoicesList invoices={invoices} dealershipId={dealershipId} />
                <div className="d-flex justify-content-sm-end mt-3">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={perPage}
                    showQuickJumper
                    showSizeChanger={false}
                    total={invoicesCount}
                    onChange={changePageHandler}
                  />
                </div>
              </>
            ) : (
              <p>{t('invoices.noInvoices')}</p>
            )}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>
    </>
  );
};

export default Invoices;
