import axios from '../api/axios';

export async function login(email, password) {
  const response = await axios.post(
    '/auth/login',
    JSON.stringify({
      email,
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = response.data;

  return data;
}

export async function sendResetPassword(email) {
  const response = await axios.post(
    '/auth/reset-password',
    JSON.stringify({
      email,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = response.data;

  return data;
}

export async function resetPassword(password, token) {
  const response = await axios.post(
    `/auth/reset-password/${token}`,
    JSON.stringify({
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = response.data;

  return data;
}
