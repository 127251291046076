import axios from '../api/axios';
import store from '../store';
import { errorActions } from '../store/slices/error-slice';

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    const state = store.getState();

    // remove error if any
    store.dispatch(errorActions.removeError())

    // add auth header with jwt if account is logged in and request is to the api url
    const token = state.auth.token;
    const isLoggedIn = token?.access_token;
    // const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
    if (isLoggedIn && request.url !== '/auth/refresh') {
      request.headers.common.Authorization = `Bearer ${token.access_token}`;
    }

    return request;
  });
}
