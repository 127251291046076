import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { requestPermission } from '../../../firebase';
import { Button } from 'antd';
import logo from '../../../assets/img/logo.png';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const RequestNotification = () => {
  const { t } = useTranslation();

  const [showPoup, setShowPopup] = useState(() => {
    if ('Notification' in window && Notification.permission !== 'default') {
      return false;
    }

    // GET from local storage
    // To check and remove the item when it expires
    let storedItem = JSON.parse(localStorage.getItem('popupNotification'));
    if (storedItem && new Date().getTime() > storedItem.expires) {
      localStorage.removeItem('popupNotification');
      storedItem = null;
    }

    return !storedItem;
  });

  const setInStorage = (value) => {
    // Set a key with a value and an expiration time (in milliseconds)
    const expirationTime = new Date().getTime() + 24 * 60 * 60 * 20 * 1000; // 20 days from now
    localStorage.setItem(
      'popupNotification',
      JSON.stringify({ isApprove: value, expires: expirationTime })
    );
  };

  useEffect(() => {
    if (!('Notification' in window)) {
      return console.log('This browser does not support desktop notification');
    }

    if (Notification.permission === 'granted') {
      requestPermission();
    }
  }, []);

  const handlePopup = (value) => {
    setInStorage(value);
    setShowPopup(false);

    value && requestPermission();
  };

  return (
    <>
      {showPoup && (
        <div className="popup-container">
          <div style={{ textAlign: 'end' }}>
            <CloseOutlined onClick={() => setShowPopup(false)} />
          </div>
          <div className="popup-content">
            <div style={{ width: '90px', height: '100%' }}>
              <img src={logo} alt="logo" style={{ width: '100%' }} />
            </div>
            <div className="popup-text">
              {t('notifications.notificationText')}
            </div>
          </div>
          <div className="popup-buttons">
            <Button onClick={() => handlePopup(false)}>
              {t('notifications.noThanks')}
            </Button>
            <Button type="primary" onClick={() => handlePopup(true)}>
              {t('notifications.allow')}
            </Button>
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default RequestNotification;
