import { Row, Col } from 'antd';
import UserItem from './UserItem';

const UsersList = ({
  users,
  onEditUser,
  onRemoveUser,
  onChangeGroup,
  onChangeRole,
  selectedGroup,
  isRootMenu,
}) => {
  return (
    <Row gutter={[8, 8]}>
      {users.map((user, index) => (
        <Col
          key={index}
          className="d-flex"
          xs={24}
          sm={12}
          md={8}
          lg={6}
          xl={6}
          xxl={4}
        >
          <UserItem
            onEditUser={onEditUser}
            onRemoveUser={onRemoveUser}
            user={user}
            onChangeGroup={onChangeGroup}
            onChangeRole={onChangeRole}
            selectedGroupId={selectedGroup}
            isRootMenu={isRootMenu}
          />
        </Col>
      ))}
    </Row>
  );
};

export default UsersList;
