import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import classes from './DealershipItem.module.css';

import { List, Menu, Avatar, Dropdown } from 'antd';
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  TeamOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { BlurHashImage, ThreeDots } from '../UI';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImg from '../../assets/img/logo.svg';
import { Payments } from '../../lib/constants/payments';
import { PRIMARY_DATE_FORMAT } from '../../lib/constants/utils';

const DealershipItem = ({ item, onDelete, onEdit }) => {
  const [visible, setVisible] = useState(false);

  const createdAt = moment(item.created_at).format(PRIMARY_DATE_FORMAT);

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '0':
        break;
      case '3':
        onEdit(item);
        break;
      case 'code':
        // ! CHECK IF HTTPS, clipboard doesn't work on http
        navigator.clipboard.writeText(item.code);
        toast.success('Copied.', {
          position: 'top-center',
          autoClose: 2500,
          hideProgressBar: true,
          progress: undefined,
        });
        break;
      case '4':
        onDelete(item);
        break;
      default:
        break;
    }

    setVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const creditsType =
    item && item?.price_type === Payments.PER_USE
      ? 'PREPAID'
      : item?.price_type;

  const credits =
    item && item?.price_type === Payments.UNLIMITED
      ? `(until: ${moment(item?.unlimited_until).format(PRIMARY_DATE_FORMAT)})`
      : item?.credits
      ? item?.credits
      : '0';

  const menu = (
    <Menu style={{ minWidth: '165px' }} onClick={handleMenuClick}>
      <Menu.Item className={classes.flex_between} key="0">
        <Link to={`${item.id}`}>Profile</Link>
        <UserOutlined />
      </Menu.Item>
      <Menu.Item className={classes.flex_between} key="1">
        <Link to={`${item.id}/users`}>Users</Link>
        <TeamOutlined />
      </Menu.Item>
      <Menu.Item className={classes.flex_between} key="2">
        <Link to={`${item.id}/invoices`}>Invoices</Link>
        <FileTextOutlined />
      </Menu.Item>
      <Menu.Item className={classes.flex_between} key="3">
        Edit
        <EditOutlined />
      </Menu.Item>
      {item.code && <Menu.Divider />}
      {item.code && (
        <Menu.Item className={classes.code} key="code">
          {item.code}
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item className={`${classes.flex_between} delete`} key="4">
        Delete
        <DeleteOutlined />
      </Menu.Item>
    </Menu>
  );

  let avatar = <Avatar src={logoImg} alt="logo_image" />;

  if (item.logo) {
    avatar = (
      <div className={`${classes.round_logo} flex_center`}>
        <BlurHashImage
          url={item.logo.paths.original}
          blurHash={item.logo.blurhash}
          spinner={false}
          preview={false}
          imgWidth={32}
          imgHeight={32}
          imgClass={'logo_image'}
          alt="logo_image"
        />
      </div>
    );
  }

  return (
    <List.Item>
      <List.Item.Meta
        avatar={avatar}
        title={<Link to={`/dealerships/${item.id}`}>{item.name}</Link>}
        description={
          <>
            <p className="small m-0">{createdAt}</p>
            <p>
              {item.country} - {item.city}
            </p>
            <p className="small">
              Payment: {creditsType}: {credits}
            </p>
          </>
        }
      />

      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={visible}
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
      >
        <ThreeDots />
      </Dropdown>
    </List.Item>
  );
};

export default DealershipItem;
