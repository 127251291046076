import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Drawer, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Modal from '../../UI/modal/Modal';

import classes from './PageHeader.module.css';

const { Search } = Input;

const PageHeader = ({
  onClickButton,
  buttonText,
  title,
  icon,
  extras,
  onSearch,
  showButton = true,
  showSearch = true,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const xSmall = useMediaQuery({ query: `(max-width: 575px)` });
  const mobile = useMediaQuery({ query: `(max-width: 425px)` });
  const { t } = useTranslation();

  useEffect(() => {
    if (!xSmall) setToggleSearch(false);
    if (!xSmall) setToggleFilters(false);
  }, [xSmall]);

  const openSearchHandler = () => {
    setToggleSearch(true);
  };

  const onSearchHandler = (value) => {
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <>
      <div className={`${classes.page_header} page_info page_header_card`}>
        <div className="flex_between gap-3">
          <div className="flex_between">
            <h5 style={{ margin: '0', fontWeight: '200' }}>{title}</h5>
            {!xSmall && <>{extras}</>}
          </div>

          <div className="d-flex align-items-center">
            {extras && (
              <>
                <Avatar
                  onClick={() => setToggleFilters(true)}
                  className={classes.search_button}
                  icon={<FilterOutlined />}
                />
                {xSmall && <Divider type="vertical" />}
              </>
            )}
            {showSearch && (
              <>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={t('global.search')}
                  className={`${classes.search_input}`}
                  allowClear
                  onSearch={(value) => onSearch(value)}
                  // style={tablets ? { width: '210px' } : { width: '260px' }}
                />

                <Avatar
                  onClick={openSearchHandler}
                  className={classes.search_button}
                  icon={<SearchOutlined />}
                />
              </>
            )}
          </div>

          {showButton && !xSmall && (
            <div>
              <Button
                icon={icon}
                htmlType="submit"
                onClick={onClickButton}
                type="primary"
                className="flex_center"
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>

      {toggleSearch && xSmall && (
        <Modal
          title={'Search'}
          className={'search_modal'}
          onClose={() => setToggleSearch(false)}
        >
          <div className="p-1">
            <Search
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('global.search')}
              allowClear
              className="w-100"
              onSearch={onSearchHandler}
            />
          </div>
        </Modal>
      )}

      {toggleFilters && xSmall && (
        <Drawer
          closable={false}
          width={mobile ? '100%' : 380}
          visible={toggleFilters}
          onClose={() => setToggleFilters(false)}
        >
          <h4>Filters</h4>
          <div className="p-1">{extras}</div>
          <div className="d-flex justify-content-between filter_buttons">
            <Button type="primary" onClick={() => setToggleFilters(false)}>
              {t('global.form.close')}
            </Button>
          </div>
        </Drawer>
      )}

      {showButton && xSmall && (
        <div className={classes.rounded_button}>
          <Button
            icon={icon}
            htmlType="submit"
            onClick={onClickButton}
            type="primary"
            className="flex_center"
          >
            {!icon && '+'}
          </Button>
        </div>
      )}
    </>
  );
};

export default PageHeader;
