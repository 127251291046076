export const fuelType = [
  'GASOLINE',
  'DIESEL',
  'ELECTRIC',
  'CNG',
  'LPG',
  'HYDROGEN',
  'HYBRID',
  'OTHER',
];
