import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayout from '../components/auth/AuthLayout';
import LoginCard from '../components/login/LoginCard';
import logo from '../assets/img/logo.svg';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { addUser } from '../store/actions/user-actions';
import { Status } from '../lib/constants/status';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

const Register = () => {
  const [registerStatus, setRegisterStatus] = useState(Status.IDLE);
  const [dealership, setDealership] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const code = searchParams.get('code');

  const onFinish = (values) => {
    let newUser = {};

    for (const key in values) {
      let value = values[key];

      newUser[key] = value || undefined;
    }

    delete newUser['confirmPassword'];

    if (code) {
      newUser['code'] = code;
    }

    createUserHandler(newUser);
  };

  const createUserHandler = async (body) => {
    setRegisterStatus(Status.LOADING);

    try {
      const res = await dispatch(addUser(body)).unwrap();

      setDealership(res.dealerships[0].name);
      setRegisterStatus(Status.COMPLETED);
    } catch (err) {
      setRegisterStatus(Status.FAILED);
    }
  };

  const navigateHandler = () => {
    if (isAndroid) {
      const url = 'myautohouse://portal.myautohouse.eu/login';
      window.location.replace(url);

      setTimeout(() => {
        window.location.replace(
          'https://play.google.com/store/apps/details?id=eu.my.autohouse'
        );
      }, 3000);
    } else if (isIOS) {
      window.location.replace('myautohouse://');

      setTimeout(() => {
        window.location.replace(
          'https://apps.apple.com/rs/app/myautohouse/id1623362086?itsct=apps_box_badge&amp;itscg=30200'
        );
      }, 3000);
    } else {
      navigate('/login', { replace: true });
    }
  };

  return (
    <AuthLayout>
      <>
        {registerStatus !== Status.COMPLETED && (
          <LoginCard>
            <div className="flex_between">
              <h3>{t('register.register')}</h3>
              <img
                src={logo}
                className={`img-fluid`}
                style={{ height: '75px' }}
                alt="logo my autohouse"
              />
            </div>
            <Form onFinish={onFinish} form={form} layout="vertical">
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('global.formValidationError.requiredName'),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.name')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('global.formValidationError.requiredEmail'),
                      },
                      {
                        type: 'email',
                        message: t('global.formValidationError.validEmail'),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.email')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'global.formValidationError.requiredPassword'
                        ),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      className="custom_input"
                      type="password"
                      placeholder={t('global.form.password')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t('global.form.confirmPassword'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              t('global.formValidationError.mismatchPasswords')
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      className="custom_input"
                      type="password"
                      placeholder={t('global.form.confirmPassword')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'global.formValidationError.requiredCountry'
                        ),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.country')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: t('global.formValidationError.requiredCity'),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.city')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'global.formValidationError.requiredAddress'
                        ),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.address')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: t('global.formValidationError.requiredPhone'),
                      },
                    ]}
                  >
                    <Input
                      className="custom_input"
                      placeholder={t('global.form.phone')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!code ? (
                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item
                      name="code"
                      rules={[
                        {
                          required: !code ? true : false,
                          message: t('global.formValidationError.requiredCode'),
                        },
                      ]}
                    >
                      <Input
                        className="custom_input"
                        placeholder={t('global.form.referralCode')}
                        suffix={
                          <Tooltip title={t('register.referralCodeTooltip')}>
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <div className="mb-3 d-flex align-items-center gap-2">
                  <p>{t('register.referralCode', { code })}</p>
                  <Tooltip title={t('register.referralCodeTooltip')}>
                    <InfoCircleOutlined
                      style={{
                        color: 'rgba(0,0,0,.45)',
                      }}
                    />
                  </Tooltip>
                </div>
              )}

              <Button
                type="primary"
                loading={registerStatus === Status.LOADING}
                // disabled={disabledSave}
                htmlType="submit"
              >
                {t('register.register')}
              </Button>
              <p className="small">
                {t('register.haveAccount')}
                <span
                  style={{
                    cursor: 'pointer',
                    color: 'var(--color-ui-primary)',
                  }}
                  onClick={navigateHandler}
                >
                  {t('register.signIn')}
                </span>
              </p>
              <p className="mt-3 small">
                {t('register.contactForAccount')}
                <span style={{ color: 'var(--color-ui-primary)' }}>
                  {' '}
                  {t('register.contactUs')}
                </span>
              </p>
            </Form>
          </LoginCard>
        )}

        {registerStatus === Status.COMPLETED && (
          <LoginCard>
            <div style={style}>
              <CheckCircleOutlined
                style={{ fontSize: '2rem', color: 'var(--color-ui-ok)' }}
              />
              <p>
                {t('register.registrationSuccess', { dealership })}
                <span
                  style={{
                    cursor: 'pointer',
                    color: 'var(--color-ui-primary)',
                  }}
                  onClick={navigateHandler}
                >
                  {t('register.loginPage')}
                </span>
                .
              </p>
              <p>{t('register.downloadApp')}</p>
              <div className="d-flex align-items-center">
                <a
                  style={{ width: '140px' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=eu.my.autohouse&hl=en&gl=US"
                >
                  <img
                    className="img-fluid"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
                <a
                  style={{ width: '140px' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/rs/app/myautohouse/id1623362086?itsct=apps_box_badge&amp;itscg=30200"
                >
                  <img
                    className="img-fluid"
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1665792000&h=430aa8100c10c6d4db40679dd1f854ba"
                    alt="Download on the App Store"
                  />
                </a>
              </div>
            </div>
          </LoginCard>
        )}
      </>
    </AuthLayout>
  );
};

export default Register;
