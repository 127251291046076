import axios from '../api/axios';
import store from '../store';
import { errorActions } from '../store/slices/error-slice';
import errorHandler from '../store/actions/error-actions';

export function errorAxiosInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message === 'canceled') return Promise.reject(error);
      /*
       * Unknow Error or 5xx error
       */
      if (!error.response || error.response.status >= 500) {
        let message = 'Unknown error';
        if (navigator.onLine) {
          console.log('online');
          // store.dispatch(errorActions.setError({ code: 500, message: message }));
        } else {
          console.log('offline');
          message = 'Please check your internet connection.';
          // store.dispatch(errorActions.setError({ code: 0, message: message }));
        }
        store.dispatch(errorActions.setError({ code: 500, message: message }));
      }

      // ODBITI PRIKAZIVANJE ERRORA
      // AKO JE NASTALO OD REFRESH TOKENA

      if (
        error.response.status === 401 &&
        (error.response.config.url === '/auth/refresh' ||
          error.response.data.code === 4010004)
      ) {
        return Promise.reject(error);
      }

      if (error.response.status >= 400 && error.response.status < 500) {
        store.dispatch(errorHandler(error));
      }

      return Promise.reject(error);
    }
  );
}
