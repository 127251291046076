
export const initFeatures = [
  {
    name: 'EXCHANGE',
    displayName: 'Exchange',
    description: 'features.exchangeInfo',
  },
  {
    name: 'CHAT',
    displayName: 'Chat',
    description: 'features.chatInfo',
  },
  {
    name: 'SCAN_DOCUMENT',
    displayName: 'Scan Document',
    description: 'features.scanDocumentInfo',
  },
];

export const REQUEST_FEATURES = ['EXCHANGE'];

export const OTHER_FEATURES = ['CHAT', 'SCAN_DOCUMENT'];
