import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DealershipsList from '../components/Dealerships/DealershipsList';
import { useMediaQuery } from 'react-responsive';
import {
  addDealership,
  editDealership,
  fetchDealerships,
  removeDealership,
  uploadLogoFile,
} from '../store/actions/dealership-actions';
import { Select, Pagination } from 'antd';
import DealershipForm from '../components/Dealerships/DealershipForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  ConfirmModal,
  PageHeader,
  ContentWrapper,
  LoadingSpinner,
} from '../components/UI';
import PaginationCount from '../components/pagination/PaginationCount';
import { setPagination } from '../helpers/_util';

const { Option } = Select;

const Dealerships = () => {
  const dispatch = useDispatch();

  const {
    dealerships,
    count: dealershipsCount,
    isLoading,
  } = useSelector((state) => state.dealerships);

  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const [isVisible, setIsVisible] = useState(false);
  const [addDealershipStatus, setAddDealershipStatus] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [selectedDealership, setSelectedDealership] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [searchParams, setSearchParams] = useState('');

  const getDealerships = useCallback(
    async (pagination, signal, params = null) => {
      await dispatch(
        fetchDealerships({
          pagination,
          signal,
          params,
        })
      );
    },
    [dispatch]
  );

  const getSearchParams = (searchInput) => {
    let params = {};

    !!searchInput && (params['name'] = searchInput);
    !!searchInput && (params['email'] = searchInput);

    return params;
  };

  useEffect(() => {
    let params = getSearchParams(searchParams);

    const controler = new AbortController();
    const { signal } = controler;

    const pagination = setPagination(page, perPage);

    getDealerships(pagination, signal, params);

    return () => controler.abort();
  }, [getDealerships, page, perPage, searchParams]);

  const uploadFile = async (file, dealership) => {
    let formData = new FormData();
    formData.append('image', file);

    const data = await dispatch(
      uploadLogoFile({ dealershipId: dealership.id, body: formData })
    ).unwrap();

    return data;
  };

  const createDealerships = async (data, file) => {
    setAddDealershipStatus(true);

    try {
      const dealership = await dispatch(addDealership(data)).unwrap();

      if (file) {
        await uploadFile(file, dealership);
      }

      const params = getSearchParams(searchParams);
      const pagination = setPagination(page, perPage);

      getDealerships(pagination, undefined, params);

      onCloseHandler();
    } catch (err) {}

    setAddDealershipStatus(false);
  };

  const onCloseHandler = () => {
    setIsVisible(false);
    setToggleDelete(false);
    setSelectedDealership(null);
  };

  const onOpenHandler = () => {
    setIsVisible(true);
  };

  const onDeleteDealership = (dealership) => {
    setSelectedDealership(dealership);
    setToggleDelete(true);
  };

  const deleteDealershipHandler = async () => {
    await dispatch(removeDealership(selectedDealership.id));

    setPage(1);
    onCloseHandler();
  };

  const editDealershipHandler = (dealership) => {
    setSelectedDealership(dealership);
    setIsVisible(true);
  };

  const updateDealershipHandler = async (body) => {
    setAddDealershipStatus(true);

    await dispatch(
      editDealership({ dealershipId: selectedDealership.id, body })
    );

    onCloseHandler();
    setAddDealershipStatus(false);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  const onSearchUsersHandler = (value) => {
    setSearchParams(value);
    setPage(1);
  };

  const changePerPageHandler = (value) => {
    setPerPage(value);
    setPage(1);
  };

  return (
    <>
      <PageHeader
        buttonText={'+ New Dealership'}
        title={'Dealerships'}
        onClickButton={onOpenHandler}
        onSearch={onSearchUsersHandler}
      />

      <ContentWrapper>
        {isLoading && <LoadingSpinner />}

        {!isLoading && dealerships.length > 0 && (
          <>
            <div className="flex_between mb-3 gap-2">
              <Select onChange={changePerPageHandler} value={perPage}>
                <Option value={6}>6 / Page</Option>
                <Option value={12}>12 / Page</Option>
                <Option value={24}>24 / Page</Option>
              </Select>
              <PaginationCount
                perPage={perPage}
                page={page}
                total={dealershipsCount}
              />
            </div>
            <DealershipsList
              dealerships={dealerships}
              onDelete={onDeleteDealership}
              onEdit={editDealershipHandler}
            />
            <div className="d-flex justify-content-sm-end mt-3">
              <Pagination
                defaultCurrent={page}
                pageSize={perPage}
                showQuickJumper
                showSizeChanger={false}
                total={dealershipsCount}
                onChange={changePageHandler}
              />
            </div>
          </>
        )}

        {!isLoading && !dealerships.length && (
          <p className="not_found_text">No dealerships found.</p>
        )}
      </ContentWrapper>

      {isVisible && (
        <DealershipForm
          onCreateDealerships={createDealerships}
          onUpdateDealership={updateDealershipHandler}
          isVisible={isVisible}
          isSM={isSM}
          isLoading={addDealershipStatus}
          onClose={onCloseHandler}
          selectedDealership={selectedDealership}
        />
      )}

      {toggleDelete && (
        <ConfirmModal
          title={'Delete Dealership'}
          buttonText={'Delete'}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          danger={true}
          description={
            <>
              Are you sure you want to delete dealership{' '}
              <span style={{ fontWeight: 'bold' }}>
                {selectedDealership.name}
              </span>{' '}
              ?
            </>
          }
          onClose={onCloseHandler}
          onConfirm={deleteDealershipHandler}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Dealerships;
