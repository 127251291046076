import { createSlice } from '@reduxjs/toolkit';

import { toastrError } from '../../helpers/toastr';

const initialState = {
  error: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;

      // if (state.error.code !== 500 && state.error.code !== 0 ) {
      if (state.error.code !== 500) {
        toastrError(state.error.message, 10000);
      }
    },
    removeError(state, action) {
      state.error = null;
    },
  },
});

export const errorActions = errorSlice.actions;

export default errorSlice;
