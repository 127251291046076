import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createTarget,
  deleteTarget,
  getTargets,
  updateTarget,
  uploadTargetImage,
} from '../../lib/target';

export const fetchTargets = createAsyncThunk(
  'targets/fetchTargets',
  async (options = {}) => {
    const data = await getTargets(options);

    return data;
  }
);

export const addTarget = createAsyncThunk(
  'targets/addTarget',
  async (body, { rejectWithValue }) => {
    try {
      const response = await createTarget(body);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editTarget = createAsyncThunk(
  'targets/editTarget',
  async ({ targetId, body }, { rejectWithValue }) => {
    try {
      const response = await updateTarget(targetId, body);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadTargetFile = createAsyncThunk(
  'targets/uploadTargetFile',
  async ({ targetId, body }, { rejectWithValue }) => {
    try {
      const response = await uploadTargetImage(targetId, body);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeTarget = createAsyncThunk(
  'targets/removeTarget',
  async (targetId) => {
    const response = await deleteTarget(targetId);

    return targetId;
  }
);
