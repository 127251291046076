import { Row, Col } from 'antd';
import RequestItem from './RequestItem';

const RequestsList = ({ allRequests, onRequest }) => {
  return (
    <Row gutter={[8, 8]}>
      {allRequests.map((request, index) => (
        <Col key={index} xs={24}>
          <RequestItem request={request} onRequest={onRequest} />
        </Col>
      ))}
    </Row>
  );
};

export default RequestsList;
