import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions/auth-actions';

import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import logo from '../../assets/img/logo.svg';
import classes from './LoginForm.module.css';
import { Link } from 'react-router-dom';
import LoginCard from './LoginCard';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const emailRef = useRef();
  const passwordRef = useRef();
  const rememberMeRef = useRef();

  const [isValid, setIsValid] = useState(true);

  const isLoading = useSelector((state) => state.auth.isLoading);

  const submitHandler = async (e) => {
    e.preventDefault();

    const email = emailRef.current.state?.value;
    const password = passwordRef.current.state?.value;
    const rememberMe = rememberMeRef.current.state.checked;

    if (!email || !password) {
      setIsValid(false);
      return;
    }

    if (!email.trim() || !password.trim()) {
      setIsValid(false);
      return;
    }

    setIsValid(true);

    dispatch(
      loginUser({ email: email.trim(), password: password.trim(), rememberMe })
    );
  };

  return (
    <LoginCard>
      <div className="text-center">
        <h3>{t('global.welcome')}</h3>
        <img
          src={logo}
          className={`img-fluid ${classes.logo}`}
          alt="logo my autohouse"
        />
      </div>
      <form onSubmit={submitHandler}>
        <Form.Item>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t('login.username')}
            name="username"
            size="large"
            ref={emailRef}
            className="custom_input"
          />
        </Form.Item>

        <Form.Item>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            name="password"
            placeholder={t('global.form.password')}
            size="large"
            ref={passwordRef}
            className="custom_input"
          />
        </Form.Item>

        <Form.Item>
          <Checkbox ref={rememberMeRef}>{t('login.rememberMe')}</Checkbox>
        </Form.Item>

        {!isValid && (
          <p className={`small ${classes.error}`}>{t('login.formError')}</p>
        )}

        <Form.Item>
          <Button
            loading={isLoading}
            size="large"
            htmlType="submit"
            type="primary"
            className="w-100"
          >
            {isLoading ? t('login.loading') : t('login.login')}
          </Button>
          <p className="mt-1">
            {t('login.or')}
            <Link to={'/register'} replace>
              {t('login.registerNow')}
            </Link>
          </p>
        </Form.Item>

        <Form.Item>
          <Link to={'/forgot'} className="w-100">
            {t('login.forgotPassword')}
          </Link>
        </Form.Item>
      </form>
    </LoginCard>
  );
};

export default LoginForm;
