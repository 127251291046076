import React, { useState } from 'react';
import { Avatar, Dropdown, Menu, Select, Button } from 'antd';
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  PhoneOutlined,
  HomeOutlined,
  UserSwitchOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

import { ThreeDots, Card, Modal } from '../UI';
import { Link, useParams } from 'react-router-dom';

import classes from './UserItem.module.css';

import user1 from '../../assets/img/user1.jpg';
import user2 from '../../assets/img/user2.jpg';
import user3 from '../../assets/img/user3.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../store/slices/user-slice';
import { Roles } from '../../lib/constants/roles';
import { editUserAssigment } from '../../store/actions/user-actions';
import { UserRoles } from '../../lib/constants/roles';
import DefaultBadge from '../UI/default-badge/DefaultBadge';
import UserText from './UserText';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';
import { selectAllGroups } from '../../store/slices/user-group-slice';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const UserItem = ({
  user,
  isProfile,
  onEditUser,
  onRemoveUser,
  selectedGroupId,
  isRootMenu = false,
}) => {
  const loggedInUser = useSelector(selectLoggedInUser);
  const groups = useSelector(selectAllGroups);
  const currentDealership = useSelector(selectCurrentDealership);

  const { t } = useTranslation();

  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  const [toggleChangeRole, setToggleChangeRole] = useState(false);
  const [toggleChangeGroup, setToggleChangeGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const dispatch = useDispatch();
  const ref = React.createRef();

  const userDealership = user.dealerships.find(
    (dealership) => dealership.id === dealershipId
  );

  const isAdmin =
    !!userDealership &&
    userDealership.user_dealership?.user_role === UserRoles.ADMIN;

  const menu = (
    <Menu style={{ minWidth: '165px' }}>
      {!isAdmin && !isRootMenu && (
        <Menu.Item className={classes.flex_between} key="0">
          <Link to={`${user.id}`}>{t('users.user.profile')}</Link>
          <UserOutlined />
        </Menu.Item>
      )}
      {loggedInUser.type === Roles.ROOT && (
        <Menu.Item
          onClick={() => onEditUser(user)}
          className={classes.flex_between}
          key="1"
        >
          {t('global.edit')}
          <EditOutlined />
        </Menu.Item>
      )}
      {!isRootMenu && (
        <>
          <Menu.Item
            onClick={() => setToggleChangeGroup(true)}
            className={classes.flex_between}
            key="2"
          >
            {t('users.user.changeGroup')}
            <UserSwitchOutlined />
          </Menu.Item>
          <Menu.Item
            onClick={() => setToggleChangeRole(true)}
            className={classes.flex_between}
            key="3"
          >
            {t('users.user.changeRole')}
            <UserSwitchOutlined />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() => onRemoveUser(user)}
            className={`${classes.flex_between} delete`}
            key="4"
          >
            {t('global.form.remove')}
            <DeleteOutlined />
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const users = [user1, user2, user3];
  const index = Math.floor(Math.random() * 3 + 1);

  /**
   * is for changing user group (e.g. Basic -> VIP)
   */
  const changeGroupHandler = async () => {
    const group_id = selectedGroup !== 'self' ? selectedGroup : null;

    setIsLoading(true);

    await dispatch(
      editUserAssigment({ userId: user.id, dealershipId, body: { group_id } })
    );

    // remove user from array if new group is different from selected group
    if (selectedGroupId !== 'all' && group_id !== selectedGroupId) {
      dispatch(userActions.remove({ userId: user.id }));
    }

    setToggleChangeGroup(false);
    setSelectedGroup(null);
    setIsLoading(false);
  };

  const changeRoleHandler = async () => {
    setIsLoading(true);

    await dispatch(
      editUserAssigment({
        userId: user.id,
        dealershipId,
        body: { user_role: selectedRole },
      })
    );

    setToggleChangeRole(false);
    setSelectedRole(null);
    setIsLoading(false);
  };
  const userCityCountry = `${user.city || '-'}, ${user.country || '-'}`;

  return (
    <>
      <Card
        isProfile={isProfile}
        className={!isProfile && 'hover_card'}
        style={{
          flexGrow: '1',
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className="p-2"
          style={{ position: 'relative', wordBreak: 'break-all' }}
        >
          <div style={{ position: 'absolute', right: '5px' }}>
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={menu}
            >
              <ThreeDots ref={ref} />
            </Dropdown>
          </div>

          <div className="pt-3 text-center">
            <Avatar
              className={classes.avatar}
              src={users[index - 1]}
              alt="user_image"
            />
          </div>
          <div className="mt-3 text-center">
            <p className="user_name">{user.name}</p>
            <p className={`${classes.user_email} small`}>{user.email}</p>
            {!!userDealership && !isRootMenu && (
              <DefaultBadge
                text={
                  userDealership.user_dealership?.group?.name ||
                  t('users.selfRegistered')
                }
              />
            )}
            {isAdmin && !isRootMenu && (
              <DefaultBadge text={'Admin'} isAdmin={true} />
            )}
          </div>
          <div style={{ height: '25px' }}></div>
          <div className="small mb-2">
            <UserText
              icon={<HomeOutlined className="primary_clr" />}
              text={userCityCountry}
            />

            <UserText
              icon={<EnvironmentOutlined className="primary_clr" />}
              text={user.address}
            />

            <UserText
              icon={<PhoneOutlined className="primary_clr" />}
              text={user.phone}
            />
          </div>
        </div>
        {!isAdmin && (
          <>
            <div className={classes.divider}></div>
            {!isRootMenu && (
              <div className={`${classes.view_profile} text-center`}>
                <Link to={`${user.id}`}>{t('users.user.viewProfile')}</Link>
              </div>
            )}

            {isRootMenu && (
              <>
                {user.dealerships.length > 0 ? (
                  user.dealerships.map((dealership) => (
                    <div className="p-2" key={dealership.id}>
                      {dealership.user_dealership.user_role ===
                      UserRoles.USER ? (
                        <Link
                          to={`/dealerships/${dealership.id}/users/${user.id}`}
                        >
                          {dealership.name}
                        </Link>
                      ) : (
                        <p>
                          {t('users.user.adminOrModerator', {
                            dealershipName: dealership.name,
                          })}
                        </p>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="p-2">{t('users.user.withoutDealership')}</p>
                )}
              </>
            )}
          </>
        )}
      </Card>

      {toggleChangeGroup && (
        <Modal
          title={user.name}
          onClose={() => {
            setToggleChangeGroup(false);
            setSelectedGroup(null);
          }}
        >
          <div className="d-flex align-items-center">
            <p>{t('users.userGroups')} </p>
            <Select
              defaultValue={
                user.dealerships[0].user_dealership.group_id || 'self'
              }
              style={{ width: 130 }}
              onChange={(value) => setSelectedGroup(value)}
              name="groups"
              id="groups"
              bordered={false}
            >
              {!!groups.length &&
                groups.map((group) => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              <Option value="self">{t('users.selfRegistered')}</Option>
            </Select>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              disabled={isLoading}
              onClick={() => {
                setToggleChangeGroup(false);
                setSelectedGroup(null);
              }}
            >
              {t('global.form.close')}
            </Button>
            <Button
              loading={isLoading}
              onClick={changeGroupHandler}
              className="ms-2"
              type="primary"
            >
              {t('global.form.save')}
            </Button>
          </div>
        </Modal>
      )}

      {toggleChangeRole && (
        <Modal
          title={user.name}
          onClose={() => {
            setToggleChangeRole(false);
            setSelectedRole(null);
          }}
        >
          <div className="d-flex align-items-center">
            <p>{t('users.user.roles')}</p>
            <Select
              defaultValue={userDealership.user_dealership.user_role}
              style={{ width: 130 }}
              onChange={(value) => setSelectedRole(value)}
              name="groups"
              id="groups"
              bordered={false}
            >
              <Option value="USER">{t('users.user.user')}</Option>
              <Option value="ADMIN">Admin</Option>
              {/* <Option value="MODERATOR">Moderator</Option> */}
            </Select>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              disabled={isLoading}
              onClick={() => {
                setToggleChangeRole(false);
                setSelectedRole(null);
              }}
            >
              {t('global.form.close')}
            </Button>
            <Button
              loading={isLoading}
              onClick={changeRoleHandler}
              className="ms-2"
              type="primary"
            >
              {t('global.form.save')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserItem;
