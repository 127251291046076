import classes from './Spinner.module.css';
import { Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Backdrop } from '../modal/Modal';
import ReactDOM from 'react-dom';
import logo from '../../../assets/img/logo.png';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner = (props) => {
  const portalElement = document.getElementById('overlays');

  const customName = JSON.parse(sessionStorage.getItem('dealership_name'))
  const customLogo = JSON.parse(sessionStorage.getItem('dealership_logo'))

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop backdropColor={props.backdropColor} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <div className={classes.spinner_container}>
          {props.splash && (
            <div className={classes.splash_container}>
              <Image
                preview={false}
                width={180}
                src={customLogo || logo}
                alt="logo my autohouse"
              />
              <h2 className='my-2'>{customName || 'MY AUTOHOUSE'}</h2>
              <p className="mb-2">WELCOME</p>
            </div>
          )}
          <Spin indicator={antIcon} />
          {props.text && <p>{props.text}</p>}
        </div>,
        portalElement
      )}
    </>
  );
};

export default Spinner;
