import { Button, DatePicker, Space } from 'antd';
import React, { useState } from 'react';
import {
  isFutureDate,
  isNotZeroDate,
  isPastDate,
  setEndOfDay,
} from '../../helpers/_util';
import { Modal } from '../UI';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const dateFormatList = ['DD.MM.YYYY', 'DD/MM/YYYY'];

const FeatureDateValidationForm = ({
  displayName,
  featureName,
  onClose,
  featureDetails,
  onDateValidation,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(() => {
    return isNotZeroDate(featureDetails.valid_until)
      ? moment(featureDetails.valid_until)
      : '';
  });

  const onChangeDate = (date) => {
    const selectedDate = setEndOfDay(date);
    setDate(selectedDate);
  };

  return (
    <Modal title={`${displayName} feature`} onClose={onClose}>
      <p className="mb-3">{t('features.setValidationDateInfo')}</p>
      <Space>
        <DatePicker
          value={date}
          onChange={onChangeDate}
          format={dateFormatList}
          disabledDate={(date) =>
            isPastDate(date.toDate()) ||
            isFutureDate(
              date.toDate(),
              new Date(featureDetails.dealership_valid_until)
            )
          }
          placeholder={t('global.validUntil')}
        />

        <Button
          type="primary"
          onClick={() => onDateValidation(featureName, date)}
        >
          {t('global.form.update')}
        </Button>
      </Space>
    </Modal>
  );
};

export default FeatureDateValidationForm;
