import React from 'react';

const ContentWrapper = ({ children }) => {
  return (
    <div
      style={{
        padding: '24px 30px',
        marginBottom: '55px',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
