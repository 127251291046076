import axios from '../api/axios';

export async function getGroups(dealershipId) {
  const response = await axios.get(`/dealerships/${dealershipId}/groups`);

  return response.data;
}

export async function createGroup(dealershipId, body) {
  const response = await axios.post(
    `dealerships/${dealershipId}/groups`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function updateGroup(dealershipId, groupId, body) {
  const response = await axios.patch(
    `dealerships/${dealershipId}/groups/${groupId}`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function deleteGroup(dealershipId, groupId) {
  const response = await axios.delete(
    `dealerships/${dealershipId}/groups/${groupId}`
  );

  return response.data;
}
