import moment from 'moment';

export const setPagination = (page, perPage) => {
  return {
    offset: (page - 1) * perPage,
    limit: +perPage,
  };
};

export const isNotZeroDate = (dateToCheck) => {
  if (!dateToCheck || !moment(dateToCheck).isValid()) {
    return false;
  }

  // Parse the time string into a Date object
  const date = new Date(dateToCheck);

  // Check if the date is 0001-01-01
  return !!!(
    date.getFullYear() === 1 &&
    date.getMonth() === 0 &&
    date.getDate() === 1
  );
};

export const isPastDate = (date) => {
  const now = new Date();

  return date.getTime() < now.getTime();
};

export const isFutureDate = (currentDate, date) => {
  return currentDate.getTime() > date.getTime();
};

export const isExpiredDate = (date) => {
  return isNotZeroDate(date) && isPastDate(new Date(date));
};

export const setEndOfDay = (date) => {
  if (!date) return undefined;

  const selectedDate = moment(date).endOf('day');

  // it can also be returned selectedDate.toISOString()
  return selectedDate;
};

export const isNumberOrZero = (value) => {
  if (value === null) {
    return false;
  }

  const num = Number(value);
  return typeof num === 'number' && isFinite(num);
};
