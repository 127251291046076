import { Divider, Space, Switch } from 'antd';
import React from 'react'

const PermissionItem = ({permission, onChange, isLoading}) => {

  return (
    <div className="flex_between mb-3">
      <div className="d-flex flex-column">
        <span>{permission.name}</span>
        <span className="small">{permission.description}</span>
      </div>

      <Space>
        <Divider type="vertical"></Divider>
        <Switch
          name={permission.backendName}
          loading={isLoading}
          checked={permission.isChecked}
          onChange={(checked, e) => onChange(permission.backendName, checked, e)}
        />
      </Space>
    </div>
  );
}

export default PermissionItem