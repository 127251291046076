import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { toastrSuccess } from '../../helpers/toastr';
import {
  addDealership,
  editDealership,
  fetchDealership,
  fetchDealershipPermissions,
  fetchDealerships,
  removeDealership,
  removeLogo,
  uploadLogoFile,
} from '../actions/dealership-actions';

const initialState = {
  dealerships: [],
  isLoading: false,
  dealership: null,
  currentDealership: null,
  count: 0,
  permissions: null,
};

const dealershipSlice = createSlice({
  name: 'dealerships',
  initialState,
  reducers: {
    setCurrentDealership(state, action) {
      const { dealership } = action.payload;

      // if (state.currentDealership && state.currentDealership !== dealership)
      state.currentDealership = dealership;
      if (dealership) {
        dealership.logo &&
          sessionStorage.setItem(
            'dealership_logo',
            JSON.stringify(dealership.logo.paths.original)
          );
        sessionStorage.setItem(
          'dealership_name',
          JSON.stringify(dealership.name)
        );
      }
    },
    removeDealership(state, action) {
      state.currentDealership = null;
    },
    decrementCredits(state, action) {
      const { credits } = action.payload;

      state.currentDealership.credits -= credits;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDealerships.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchDealerships.fulfilled, (state, action) => {
        const { dealerships, count } = action.payload;

        state.isLoading = false;
        state.dealerships = dealerships;
        state.count = count;
      })
      .addCase(fetchDealerships.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchDealership.fulfilled, (state, action) => {
        state.dealership = action.payload;
      })
      .addCase(addDealership.fulfilled, (state, action) => {
        const newDealership = action.payload;

        state.dealerships.push(newDealership);

        toastrSuccess(`${newDealership.name} created.`);
      })
      .addCase(fetchDealershipPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
      })
      .addCase(removeDealership.fulfilled, (state, action) => {
        const dealershipId = action.payload;

        state.dealerships = state.dealerships.filter(
          (dealership) => dealership.id !== dealershipId
        );

        toastrSuccess('Successfully deleted.');
      })
      .addMatcher(
        isAnyOf(
          editDealership.fulfilled,
          uploadLogoFile.fulfilled,
          removeLogo.fulfilled
        ),
        (state, action) => {
          const index = state.dealerships.findIndex(
            (dealership) => dealership.id === action.payload.id
          );

          if (~index) {
            state.dealerships[index] = action.payload;
          }

          toastrSuccess(`Success.`);
        }
      );
  },
});

export const selectAllDealerships = (state) => state.dealerships.dealerships;
export const selectDealershipsCount = (state) => state.dealerships.count;
export const selectPermissions = (state) => state.dealerships.permissions;
export const selectCurrentDealership = (state) =>
  state.dealerships.currentDealership;

export const selectDealershipById = (state, dealershipId) =>
  state.dealerships.dealerships.find(
    (dealership) => dealership.id === dealershipId
  );

export const dealershipActions = dealershipSlice.actions;

export default dealershipSlice;
