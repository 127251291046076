import { Button, Col, Image, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastrError, toastrSuccess } from '../../helpers/toastr';
import { sendAdditionalRequest } from '../../store/actions/request-actions';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';

const AdditionalRequests = ({ checkedTargets, onClose, onDeselect }) => {
  const dispatch = useDispatch();
  const currentDealership = useSelector(selectCurrentDealership);

  const { id: userId, vehicleId, dealershipId: dealership_id } = useParams();
  const dealershipId = dealership_id || currentDealership?.id;

  const { t } = useTranslation();
  const [reasons, setReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changeInputHandler = (index, target, e) => {
    setReasons((prevReasons) => {
      prevReasons[index] = { ...target, reason: e.target.value };

      return prevReasons;
    });
  };

  const sendRequest = async () => {
    console.log(checkedTargets);
    console.log(reasons);
    const targets = reasons;

    const isEmpty = targets.length
      ? targets.length === checkedTargets.length
        ? !targets.every((r) => r.reason && r.reason !== '')
        : true
      : true;

    if (isEmpty) {
      toastrError(t('vehicle.vehicleImages.additionalReqError'));
      return;
    }

    setIsLoading(true);

    requestAll(targets)
      .then((res) => {
        console.log(res);
        if (res.every((item) => item.status === 'fulfilled')) {
          toastrSuccess(t('vehicle.vehicleImages.additionalReqSuccess'));
          setIsLoading(false);
          onDeselect();
          onClose();
        } else {
          const countErrors = res.filter(
            (item) => item.status === 'rejected'
          ).length;
          toastrError(
            t('vehicle.vehicleImages.additionalReqFailed', {
              countErrors,
            })
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  const requestAll = async (targets) => {
    return await Promise.allSettled(
      targets.map(async (target) => {
        const data = await dispatch(
          sendAdditionalRequest({
            dealershipId,
            userId,
            requestId: vehicleId,
            imageId: target.id,
            body: { description: target.reason },
          })
        ).unwrap();

        return data;
      })
    );
  };

  return (
    <div
      style={{
        maxHeight: ' 400px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {checkedTargets.map((target, index) => (
        <Row key={target.id} gutter={16} className="mb-3">
          <Col sm={4}>
            <Image src={target.uploaded_image?.paths?.original} />
          </Col>
          <Col sm={20}>
            <Input.TextArea
              // rows={3}
              className="h-100"
              placeholder={t('vehicle.vehicleImages.enterAdditionalReqReason')}
              value={target.reason}
              onChange={(e) => changeInputHandler(index, target, e)}
            />
          </Col>
        </Row>
      ))}
      <Button type="primary" loading={isLoading} onClick={sendRequest}>
        {t('vehicle.vehicleImages.sendReq')}
      </Button>
    </div>
  );
};

export default AdditionalRequests;
