import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Divider, Space, Switch, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isExpiredDate, isNotZeroDate, isPastDate } from '../../helpers/_util';
import { Roles } from '../../lib/constants/roles';
import { PRIMARY_DATE_FORMAT } from '../../lib/constants/utils';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import moment from 'moment';
import { useState } from 'react';
import FeatureDateValidationForm from './FeatureDateValidationForm';
import { useTranslation } from 'react-i18next';

const FeatureItem = ({
  feature,
  userFeatures = [],
  onChange,
  isLoading,
  prices,
  isUserFeature,
  onDateValidation,
}) => {
  const { t } = useTranslation();
  const [toggleFeature, setToggleFeature] = useState(false);

  const loggedInUser = useSelector(selectLoggedInUser);

  const _name = feature.name.toLowerCase();

  const isChecked = userFeatures.some(
    (feature) => feature.toLowerCase() === _name
  );

  const featureDetails = prices?.find(
    (price) => price.feature.toLowerCase() === _name
  );

  const price = featureDetails
    ? `(credits: ${featureDetails.price} ${
        loggedInUser.type === Roles.ROOT && featureDetails.is_default
          ? ' - default'
          : ''
      })`
    : isChecked
    ? '(without price)'
    : '';

  const isExpired = useMemo(() => {
    return featureDetails ? isExpiredDate(featureDetails.valid_until) : true;
  }, [featureDetails]);

  const openFeatureDateHandler = () => {
    setToggleFeature(true);
  };

  const onDateValidationHandler = (name, date) => {
    setToggleFeature(false);
    onDateValidation(name, date);
  };

  return (
    <>
      <div className="flex_between mb-3 gap-3">
        <div className="d-flex flex-column">
          <p>
            {feature.displayName}
            <span className="small ms-2" style={{ fontWeight: '100' }}>
              {price}
            </span>
          </p>

          {isNotZeroDate(featureDetails?.valid_until) && (
            <div className="d-flex align-items-center gap-2">
              <p
                className="small"
                style={{
                  color: isExpired
                    ? 'var(--color-ui-danger)'
                    : 'var(--color-ui-ok)',
                }}
              >
                {t('global.validUntil')}
                <span>
                  {moment(featureDetails.valid_until).format(
                    PRIMARY_DATE_FORMAT
                  )}
                </span>
              </p>

              {isExpired ? (
                <Tooltip title={t('features.expiredDate')}>
                  <CloseCircleOutlined
                    style={{ color: 'var(--color-ui-danger)' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t('features.valid')}>
                  <CheckCircleOutlined
                    style={{ color: 'var(--color-ui-ok)' }}
                  />
                </Tooltip>
              )}
            </div>
          )}
          <span className="small">{t(feature.description)}</span>
        </div>

        <Space className="gap-1">
          {isChecked &&
            isUserFeature &&
            featureDetails &&
            isNotZeroDate(featureDetails.valid_until) &&
            !isPastDate(new Date(featureDetails.valid_until)) && (
              <Tooltip
                className="d-flex feature-date"
                title={t('features.setValidationDate')}
              >
                <CalendarOutlined
                  onClick={openFeatureDateHandler}
                  style={{ color: 'var(--color-ui-primary)', fontSize: '1rem' }}
                />
              </Tooltip>
            )}
          <Divider type="vertical"></Divider>
          <Switch
            name={_name}
            loading={isLoading}
            checked={isChecked}
            onChange={(checked, e) => onChange(_name, checked, e)}
          />
        </Space>
      </div>

      {toggleFeature && (
        <FeatureDateValidationForm
          displayName={feature.displayName}
          featureName={_name}
          onClose={() => setToggleFeature(false)}
          featureDetails={featureDetails}
          onDateValidation={onDateValidationHandler}
        />
      )}
    </>
  );
};

export default FeatureItem;
