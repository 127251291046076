import axios from '../api/axios';

export async function createUserRequest(userId, body) {
  const response = await axios.post(
    `/users/${userId}/requests`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function searchUserRequests(dealershipId, userId, params) {
  const response = await axios.get(
    `/dealerships/${dealershipId}/users/${userId}/requests`,
    { params }
  );

  return response.data;
}

export async function getDealershipRequests(dealershipId, pagination, options) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  options['headers'] = headers;

  const response = await axios.get(
    `/dealerships/${dealershipId}/requests`,
    options
  );

  const data = {
    requests: response.data,
    count: response.headers['pagination-count'] || 0,
  };

  return data;
}

export async function getUserRequests(
  dealershipId,
  userId,
  pagination,
  options = {}
) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  options['headers'] = headers;

  const response = await axios.get(
    `dealerships/${dealershipId}/users/${userId}/requests`,
    options
  );

  const data = {
    requests: response.data,
    count: response.headers['pagination-count'] || 0,
  };

  return data;
}

export async function getUserRequest(userId, requestId, options) {
  const response = await axios.get(
    `/users/${userId}/requests/${requestId}`,
    options
  );

  return response.data;
}

export async function updateUserRequest(userId, requestId, body) {
  const response = await axios.patch(
    `/users/${userId}/requests/${requestId}`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function createAdditionalRequest(
  dealershipId,
  userId,
  reqeustId,
  imageId,
  body
) {
  const response = await axios.post(
    `dealerships/${dealershipId}/users/${userId}/requests/${reqeustId}/images/${imageId}/additional_request`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function deleteRequests(dealershipId, body) {
  const response = await axios.delete(
    `/dealerships/${dealershipId}/requests`,
    { data: body },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function deleteUserRequests(userId, body) {
  const response = await axios.delete(
    `/users/${userId}/requests`,
    { data: body },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}
