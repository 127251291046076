import { createSlice } from '@reduxjs/toolkit';
import { toastrSuccess } from '../../helpers/toastr';
import {
  editRequest,
  fetchRequests,
  fetchUserRequest,
  removeRequests,
} from '../actions/request-actions';
import i18n from 'i18next';

const initialState = {
  requests: [],
  isLoading: true,
  request: null,
  count: 0,
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setRequest(state, action) {
      const { data } = action.payload;

      state.request = data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        const { requests, count } = action.payload;

        state.requests = requests;
        state.count = count;
        state.isLoading = false;
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchUserRequest.fulfilled, (state, action) => {
        const data = action.payload;

        state.request = data;
      })
      .addCase(editRequest.fulfilled, (state, action) => {
        const data = action.payload;

        const index = state.requests.findIndex((req) => req.id === data.id);

        if (~index) {
          state.requests[index] = data;
        }

        toastrSuccess(i18n.t('global.success'));
      })
      .addCase(removeRequests.fulfilled, (state, action) => {
        const { deleted_request_ids } = action.payload;

        const allRequests = [...state.requests].filter(
          (request) => !deleted_request_ids.includes(request.id)
        );

        // state.requests = allRequests;
      });
  },
});

export const selectAllRequests = (state) => state.requests.requests;
export const selectRequestsCount = (state) => state.requests.count;

export const requestActions = requestSlice.actions;

export default requestSlice;
