import React from 'react';
import PermissionItem from './PermissionItem';

const PermissionsList = ({ permissions, onChange, isLoading }) => {
  return permissions.map((permission, index) => (
    <PermissionItem
      key={index}
      permission={permission}
      onChange={onChange}
      isLoading={isLoading}
    />
  ));
};

export default PermissionsList;
