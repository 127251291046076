import { CheckOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useRef } from 'react';
import { useState } from 'react';
import AuthLayout from '../components/auth/AuthLayout';
import LoginCard from '../components/login/LoginCard';
import { sendResetPassword } from '../lib/auth';
import { Status } from '../lib/constants/status';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const initText = i18n.t('forgotPassword.title');

const ForgotPassword = () => {
  const [resetStatus, setResetStatus] = useState(Status.IDLE);

  const { t } = useTranslation();
  const emailRef = useRef();

  const sendEmailHandler = async (e) => {
    e.preventDefault();

    const email = emailRef.current.state?.value;

    if (!email) {
      return;
    }

    if (!email.trim()) {
      return;
    }

    setResetStatus(Status.LOADING);

    try {
      await sendResetPassword(email.trim());

      setResetStatus(Status.COMPLETED);
    } catch (err) {
      setResetStatus(Status.FAILED);
    }
  };

  let content;

  if (resetStatus === Status.COMPLETED) {
    content = (
      <div className="text-center mb-2">
        <p>
          {t('forgotPassword.resetSuccess')}
          <CheckOutlined
            style={{
              fontSize: '1.3rem',
              margin: '0 3px',
              color: 'var(--color-ui-ok)',
            }}
          />
        </p>
      </div>
    );
  } else {
    content = (
      <>
        <div className="text-center mb-2">
          <p>{initText}</p>
        </div>
        <form onSubmit={sendEmailHandler}>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder={t('global.form.email')}
              size="large"
              ref={emailRef}
              className="custom_input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={resetStatus === Status.LOADING}
              size="large"
              htmlType="submit"
              type="primary"
              className="w-100"
            >
              {t('forgotPassword.continue')}
            </Button>
          </Form.Item>
        </form>
      </>
    );
  }

  return (
    <>
      <AuthLayout>
        <LoginCard transition={true}>{content}</LoginCard>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;
