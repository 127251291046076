import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { Select, Pagination } from 'antd';
import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import UsersList from '../components/Users/UsersList';
import UserForm from '../components/Users/UserForm';
import {
  addUser,
  editUser,
  editUserAssigment,
  fetchDealershipUsers,
  getUsers,
  removeUser,
} from '../store/actions/user-actions';
import { Roles } from '../lib/constants/roles';
import {
  PageHeader,
  LoadingSpinner,
  ContentWrapper,
  ConfirmModal,
} from '../components/UI';
import PaginationCount from '../components/pagination/PaginationCount';
import { selectAllGroups } from '../store/slices/user-group-slice';
import { selectCurrentDealership } from '../store/slices/dealership-slice';
import { selectLoggedInUser } from '../store/slices/auth-slice';
import { fetchGroups } from '../store/actions/user-group-actions';
import { toastrSuccess } from '../helpers/toastr';
import { setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Home = () => {
  const currentDealership = useSelector(selectCurrentDealership);
  const loggedInUser = useSelector(selectLoggedInUser);
  const { users, count: usersCount } = useSelector((state) => state.users);

  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const groups = useSelector(selectAllGroups);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [toggleUserForm, setToggleUserForm] = useState(false);
  const [toggleRemoveUser, setToggleRemoveUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchParams, setSearchParams] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const getDealershipUsers = useCallback(
    async (pagination, options, isRoot = false) => {
      setIsLoading(true);

      await dispatch(
        fetchDealershipUsers({
          dealershipId,
          pagination,
          options,
        })
      );
      isRoot && (await dispatch(fetchGroups(dealershipId)));

      setIsLoading(false);
    },
    [dealershipId, dispatch]
  );

  /**
   * is for getting search parameters for users based
   * on search input value and(or) selected group.
   * @param {*} searchInput - contains user input value in search box.
   * @param {*} selectedGroup - contains selected groupId
   * @returns params
   */
  const getSearchParams = (searchInput, selectedGroup) => {
    let params = {};

    !!searchInput && (params['name'] = searchInput);
    !!searchInput && (params['email'] = searchInput);
    selectedGroup !== 'all' &&
      (params['group_id'] = selectedGroup.toString());

    return params;
  };

  /**
   * is for getting dealership users.
   * This useEffect is called every time
   * something is changed in the dependency array.
   */
  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    let params = getSearchParams(searchParams, selectedGroup);

    const options = {
      params,
      signal,
    };

    const pagination = setPagination(page, perPage);

    if (loggedInUser.type === Roles.DEALERSHIP && currentDealership) {
      getDealershipUsers(pagination, options);
    } else if (loggedInUser.type === Roles.ROOT) {
      getDealershipUsers(pagination, options, true);
    }

    return () => controler.abort();
  }, [
    currentDealership,
    loggedInUser.type,
    dispatch,
    getDealershipUsers,
    page,
    perPage,
    searchParams,
    selectedGroup,
  ]);

  const onCloseHandler = () => {
    setToggleUserForm(false);
    setToggleRemoveUser(false);
    setSelectedUser(null);
  };

  const onOpenHandler = () => {
    setToggleUserForm(true);
  };

  const onEditUser = (user) => {
    setSelectedUser(user);
    setToggleUserForm(true);
  };

  const editUserHandler = async (body, dealershipUserBody = null) => {
    setIsAdding(true);

    if (Object.keys(body).length > 0) {
      await dispatch(
        editUser({
          userId: selectedUser.id,
          body,
        })
      );
    }

    if (Object.keys(dealershipUserBody).length > 0) {
      await dispatch(
        editUserAssigment({
          userId: selectedUser.id,
          dealershipId,
          body: dealershipUserBody,
          isCurrent: true
        })
      );
    }

    onCloseHandler();
    setIsAdding(false);
  };

  const createUserHandler = async (body, additionalInfo) => {
    setIsAdding(true);

    body['dealership_id'] = dealershipId;

    try {
      const newUser = await dispatch(addUser(body)).unwrap();

      if (Object.keys(additionalInfo).length > 0) {
        await dispatch(
          editUserAssigment({
            userId: newUser.id,
            dealershipId,
            body: additionalInfo
          })
        );
      }

      toastrSuccess(t('users.newUserCreated'));

      const params = getSearchParams(searchParams, selectedGroup);
      const pagination = setPagination(page, perPage);

      getDealershipUsers(pagination, { params });

      onCloseHandler();
    } catch (err) {}

    setIsAdding(false);
  };

  const onRemoveUser = (user) => {
    setSelectedUser(user);
    setToggleRemoveUser(true);
  };

  const removeUserHandler = async () => {
    setIsAdding(true);

    try {
      await dispatch(removeUser({ userId: selectedUser.id, dealershipId }));

      onCloseHandler();
    } catch (err) {}

    setIsAdding(false);
  };

  const selectGroupHandler = (value) => {
    setSelectedGroup(value);
    setPage(1);
  };

  const onSearchUsersHandler = (value) => {
    setSearchParams(value);
    setPage(1);

    // getDealershipUsers({ offset: 0, limit: perPage }, params);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);

    // getDealershipUsers({
    //   offset: (pageNumber - 1) * perPage,
    //   limit: perPage,
    // });
  };

  const changePerPageHandler = (value) => {
    setPerPage(value);
    setPage(1);
  };

  return (
    <>
      {/* <button onClick={change}>CHANGE</button> */}

      <PageHeader
        buttonText={t('users.newUser')}
        icon={<UserAddOutlined />}
        title={t('users.users')}
        onClickButton={onOpenHandler}
        onSearch={onSearchUsersHandler}
        extras={
          <>
            {!isSM && <div className="separator"></div>}
            <div className={isSM ? 'filter_container mb-3' : ''}>
              {isSM && <label>{t('users.userGroups')}</label>}
              <Select
                value={selectedGroup}
                style={{ width: 130 }}
                onChange={selectGroupHandler}
                bordered={false}
                className="select_box"
              >
                <Option value="all">{t('users.allUsers')}</Option>
                {!!groups.length &&
                  groups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.name}
                    </Option>
                  ))}
                <Option value="null">{t('users.selfRegistered')}</Option>
              </Select>
            </div>
          </>
        }
      />

      <ContentWrapper>
        {!isLoading && (
          <div className="container-fluid p-0">
            {users.length > 0 && (
              <>
                <div className="flex_between mb-3 gap-2">
                  <Select onChange={changePerPageHandler} value={perPage}>
                    <Option value={6}>{t('global.page6')}</Option>
                    <Option value={12}>{t('global.page12')}</Option>
                    <Option value={24}>{t('global.page24')}</Option>
                  </Select>
                  <PaginationCount
                    perPage={perPage}
                    page={page}
                    total={usersCount}
                  />
                </div>
                <UsersList
                  onRemoveUser={onRemoveUser}
                  onEditUser={onEditUser}
                  users={users}
                  selectedGroup={selectedGroup}
                />
                <div className="d-flex justify-content-sm-end mt-3">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={perPage}
                    showQuickJumper
                    showSizeChanger={false}
                    total={usersCount}
                    onChange={changePageHandler}
                  />
                </div>
              </>
            )}
            {!users.length && <p>{t('users.noUsers')}</p>}
          </div>
        )}
        {isLoading && <LoadingSpinner />}
      </ContentWrapper>

      {toggleUserForm && (
        <UserForm
          onCreateUser={createUserHandler}
          onEditUser={editUserHandler}
          isVisible={toggleUserForm}
          isSM={isSM}
          isAdding={isAdding}
          onClose={onCloseHandler}
          selectedUser={selectedUser}
          dealershipId={dealershipId}
          // res={response}
        />
      )}

      {toggleRemoveUser && (
        <ConfirmModal
          title={t('users.removeFromDealership')}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          buttonText={t('global.form.remove')}
          danger={true}
          description={
            <>
              {t('users.removeUserConfirmation', { name: selectedUser.name })}
            </>
          }
          onClose={onCloseHandler}
          onConfirm={removeUserHandler}
        />
      )}
    </>
  );
};

export default Home;
