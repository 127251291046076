import { useEffect, useState, useCallback } from 'react';
import UsersList from '../components/Users/UsersList';
import UserForm from '../components/Users/UserForm';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  addUser,
  editUserAssigment,
  fetchDealershipUsers,
  getUsers,
  removeUser,
} from '../store/actions/user-actions';
import {
  PageHeader,
  ConfirmModal,
  ContentWrapper,
  LoadingSpinner,
} from '../components/UI';
import { Select, Pagination } from 'antd';
import PaginationCount from '../components/pagination/PaginationCount';
import { selectCurrentDealership } from '../store/slices/dealership-slice';
import { selectAllGroups } from '../store/slices/user-group-slice';
import { toastrSuccess } from '../helpers/toastr';
import { setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const GroupUsers = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleRemoveUser, setToggleRemoveUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [title, setTtile] = useState('');
  const [searchParams, setSearchParams] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const { groupId } = useParams();
  const groups = useSelector(selectAllGroups);
  const currentDealership = useSelector(selectCurrentDealership);

  const {
    users,
    count: usersCount,
    isLoading,
  } = useSelector((state) => state.users);

  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCloseHandler = () => {
    setToggleForm(false);
    setToggleRemoveUser(false);
    setSelectedUser(null);
  };

  const onOpenHandler = () => {
    setToggleForm(true);
  };

  const getDealershipUsers = useCallback(
    (pagination, options) => {
      dispatch(
        fetchDealershipUsers({
          dealershipId: currentDealership.id,
          pagination,
          options,
        })
      );
    },
    [currentDealership.id, dispatch]
  );

  /**
   * is for getting search parameters for users based
   * on search input value
   * @param {*} searchInput - contains user input value in search box.
   * @returns params
   */
  const getSearchParams = (searchInput) => {
    let params = {};

    !!searchInput && (params['name'] = searchInput);
    !!searchInput && (params['email'] = searchInput);

    return params;
  };

  useEffect(() => {
    const group = groups.find((group) => group.id === groupId);

    if (group) {
      setTtile(group.name);
    }
  }, [groupId, groups]);

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    let params = getSearchParams(searchParams);
    params['group_id'] = groupId;

    const options = {
      params,
      signal,
    };

    const pagination = setPagination(page, perPage);

    getDealershipUsers(pagination, options);
  }, [
    currentDealership,
    dispatch,
    getDealershipUsers,
    groupId,
    page,
    perPage,
    searchParams,
  ]);

  const createUserHandler = async (body, additionalInfo) => {
    setIsAdding(true);

    body['dealership_id'] = dealershipId;

    try {
      const newUser = await dispatch(addUser(body)).unwrap();

      if (Object.keys(additionalInfo).length > 0) {
        await dispatch(
          editUserAssigment({
            userId: newUser.id,
            dealershipId,
            body: additionalInfo
          })
        );
      }

      toastrSuccess(t('users.newUserCreated'));

      let params = getSearchParams(searchParams);
      params['group_id'] = groupId;

      const pagination = setPagination(page, perPage);

      getDealershipUsers(pagination, { params });

      onCloseHandler();
    } catch (err) {}

    setIsAdding(false);
  };

  const onRemoveUser = (user) => {
    setSelectedUser(user);
    setToggleRemoveUser(true);
  };

  const removeUserHandler = async () => {
    setIsAdding(true);

    try {
      await dispatch(
        removeUser({
          userId: selectedUser.id,
          dealershipId: currentDealership.id,
        })
      );

      onCloseHandler();
    } catch (err) {}

    setIsAdding(false);
  };

  const onSearchUsersHandler = (value) => {
    setSearchParams(value);
    setPage(1);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  const changePerPageHandler = (value) => {
    setPerPage(value);
    setPage(1);
  };

  return (
    <>
      <PageHeader
        buttonText={t('users.newUser')}
        icon={<UserAddOutlined />}
        title={title}
        onClickButton={onOpenHandler}
        onSearch={onSearchUsersHandler}
      />

      <ContentWrapper>
        {!isLoading ? (
          <div className="container-fluid p-0">
            {users.length > 0 && (
              <>
                <div className="flex_between mb-3 gap-2">
                  <Select onChange={changePerPageHandler} value={perPage}>
                    <Option value={6}>{t('global.page6')}</Option>
                    <Option value={12}>{t('global.page12')}</Option>
                    <Option value={24}>{t('global.page24')}</Option>
                  </Select>
                  <PaginationCount
                    perPage={perPage}
                    page={page}
                    total={usersCount}
                  />
                </div>
                <UsersList onRemoveUser={onRemoveUser} users={users} />
                <div className="d-flex justify-content-sm-end mt-3">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={perPage}
                    showQuickJumper
                    showSizeChanger={false}
                    total={usersCount}
                    onChange={changePageHandler}
                  />
                </div>
              </>
            )}
            {!users.length && <p>{t('users.noUsers')}</p>}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>

      {toggleForm && (
        <UserForm
          onCreateUser={createUserHandler}
          isVisible={toggleForm}
          isSM={isSM}
          isAdding={isAdding}
          onClose={onCloseHandler}
          groupId={groupId}
          // res={response}
        />
      )}

      {toggleRemoveUser && (
        <ConfirmModal
          title={t('users.removeFromDealership')}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          buttonText={t('global.form.remove')}
          danger={true}
          description={
            <>
              {t('users.removeUserConfirmation', { name: selectedUser.name })}
            </>
          }
          onClose={onCloseHandler}
          onConfirm={removeUserHandler}
        />
      )}
    </>
  );
};

export default GroupUsers;
