import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUserRequest } from '../lib/request';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchUserRequest } from '../store/actions/request-actions';
import { ContentWrapper, LoadingSpinner } from '../components/UI';
import VehicleInfo from '../components/Vehicles/VehicleInfo';
import VehicleImages from '../components/Vehicles/vehicle-images/VehicleImages';

const VehicleDetails = ({ selectedUserId, selectedVehicleId }) => {
  const request = useSelector((store) => store.requests.request);

  const { id: userId, vehicleId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const vehicle = request?.vehicle;

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const getRequest = async (signal) => {
      const options = { signal };

      await dispatch(
        fetchUserRequest({
          userId: selectedUserId || userId,
          requestId: selectedVehicleId || vehicleId,
          options,
        })
      );

      setIsLoading(false);
    };

    getRequest(signal);

    return () => controler.abort();
  }, [dispatch, selectedUserId, selectedVehicleId, userId, vehicleId]);

  const targets =
    !!request && request?.targets.length
      ? request.targets.filter((target) => target.uploaded_image)
      : [];

  return (
    <ContentWrapper>
      {!isLoading ? (
        <>
          {selectedVehicleId ? (
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <VehicleInfo vehicle={vehicle} isPreview={!!selectedVehicleId} />
              </Col>
              <Col
                xs={24}
              >
                {targets.length ? (
                  <VehicleImages
                    targets={targets}
                    requestStatus={request?.status}
                    isPreview={!!selectedVehicleId}
                  />
                ) : (
                  <p className="not_found_text">{t('vehicle.noImages')}</p>
                )}
              </Col>
            </Row>
          ) : (
            <Row gutter={[8, 8]}>
              <Col xs={24} md={10} lg={{ span: 10 }}>
                <VehicleInfo vehicle={vehicle} />
              </Col>
              <Col
                xs={24}
                md={{ span: 12, offset: 2 }}
                lg={{ span: 12, offset: 2 }}
              >
                {targets.length ? (
                  <VehicleImages
                    targets={targets}
                    requestStatus={request?.status}
                  />
                ) : (
                  <p className="not_found_text">{t('vehicle.noImages')}</p>
                )}
              </Col>
            </Row>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </ContentWrapper>
  );
};

export default VehicleDetails;
