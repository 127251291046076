import { Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  BadgeIcon,
  ContentWrapper,
  LoadingSpinner,
  PageHeader,
} from '../components/UI';
import {
  fetchInvoice,
  fetchInvoiceItems,
} from '../store/actions/invoice-actions';
import { selectInvoiceById } from '../store/slices/invoice-slice';
import moment from 'moment';
import { InvoiceType } from '../lib/constants/invoiceType';
import { selectCurrentDealership } from '../store/slices/dealership-slice';
import { setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';

const InvoiceItems = () => {
  const itemss = [
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
    {
      created_at: '2022-09-14T20:03:42.813+02:00',
      invoice_id: '0378cbd0-470a-45a7-b09b-7d88f2085ef1',
      invoice: null,
      amount: 1,
      features: ['CHAT'],
      description: '',
      vehicle_id: '1d934d0c-954c-4964-a255-53ba405441f2',
      name: 'Dejan',
      email: 'brdaic@outlook.com',
      vin: 'DAO4738V456DFD33',
    },
  ];

  const inv = {
    amount: 57401,
    credits: 0,
    id: 'aad7901f-80d9-4203-8de8-c50fd8b6ba50',
    number: 69,
    closed: false,
    closedAt: '2021-11-16T22:12:11.695Z',
    paidAmount: 2,
    csvStatus: 'completed',
    csvLink:
      'https://catch-bucket-dev.s3.eu-west-1.amazonaws.com/invoices/aad7901f-80d9-4203-8de8-c50fd8b6ba50%7D/3457ccc8-72b5-4e94-9804-7914a690389d.csv',
    type: 'postpaid',
    createdAt: '2021-09-13T09:13:28.297Z',
    updatedAt: '2021-11-16T22:12:11.696Z',
    deletedAt: null,
    clientId: 'ecc82418-90f0-4044-ab67-ca3a4cff53e8',
    customerId: null,
    userId: null,
  };

  const { dealershipId: dealership_id, id: invoiceId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [items, setItems] = useState();
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isLoadingInvoiceItems, setIsLoadingInvoiceItems] = useState(true);
  const [invoice, setInvoice] = useState(
    useSelector((state) => selectInvoiceById(state, invoiceId))
  );
  const currentDealership = useSelector(selectCurrentDealership);

  const isLoading = isLoadingInvoice || isLoadingInvoiceItems;

  const dealershipId = dealership_id || currentDealership?.id;

  useEffect(() => {
    const getInvoice = async () => {
      setIsLoadingInvoice(true);

      try {
        const data = await dispatch(
          fetchInvoice({ dealershipId, invoiceId })
        ).unwrap();

        setInvoice(data);
      } catch (err) {
      } finally {
        setIsLoadingInvoice(false);
      }
    };

    if (!invoice) {
      getInvoice();
    }
  }, [dealershipId, dispatch, invoice, invoiceId]);

  const getItems = useCallback(async () => {
    try {
      const pagination = setPagination(page, perPage);

      const data = await dispatch(
        fetchInvoiceItems({ dealershipId, invoiceId, pagination })
      ).unwrap();

      setItems(data);
    } catch (err) {
    } finally {
      setIsLoadingInvoiceItems(false);
    }
  }, [dealershipId, dispatch, invoiceId, page, perPage]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const columns = [
    {
      title: t('invoices.invoiceItem.date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (item) => <>{moment(item).format('DD/MM/YYYY HH:mm:ss')}</>,
    },
    {
      title: t('users.user.user'),
      dataIndex: 'name',
      key: 'created_at',
    },
    {
      title: t('global.form.email'),
      dataIndex: 'email',
      key: 'created_at',
    },
    {
      title: t('invoices.invoiceItem.credits'),
      dataIndex: 'amount',
      key: 'created_at',
    },
    {
      title: t('invoices.invoiceItem.features'),
      key: 'created_at',
      dataIndex: 'features',
      render: (_, { features }) => (
        <>
          {features.map((feature, index) => {
            return <Tag key={index}>{feature.toUpperCase()}</Tag>;
          })}
        </>
      ),
    },
    {
      title: t('vehicle.vin'),
      dataIndex: 'vin',
      key: 'created_at',
    },
  ];

  const title = invoice
    ? t('invoices.invoiceNumber', { invoiceNumber: invoice.invoice_number })
    : 'Invoice';

  const status =
    invoice && invoice.type.toUpperCase() === InvoiceType.POSTPAID
      ? invoice.closed
        ? t('invoices.paid')
        : +invoice.paidAmount === 0
        ? t('invoices.opened')
        : t('invoices.partial')
      : invoice?.closed
      ? t('invoices.closed')
      : t('invoices.opened');

  return (
    <>
      <PageHeader title={title} showSearch={false} />
      <ContentWrapper>
        {!isLoading ? (
          <>
            {invoice ? (
              <>
                <div className="mb-3">
                  <p className="small" style={{ fontWeight: '200' }}>
                    {t('invoices.invoiceItem.startDate')}
                    <span className="ms-2">
                      {moment(invoice.created_at).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                  </p>
                  {invoice.closed && (
                    <p className="small" style={{ fontWeight: '200' }}>
                      {t('invoices.invoiceItem.closeDate')}
                      <span className="ms-2">
                        {moment(invoice.closedAt).format('DD/MM/YYYY HH:mm:ss')}
                      </span>
                    </p>
                  )}
                  <p>
                    {t('invoices.invoiceItem.status')}
                    <Tag className="ms-2" color="green">
                      {status}
                    </Tag>
                  </p>
                </div>
                <Table
                  columns={columns}
                  dataSource={items.items}
                  scroll={{ x: true }}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: perPage,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: items.count,
                  }}
                  onChange={({ current }) => setPage(current)}
                />
              </>
            ) : (
              <p>Error</p>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>
    </>
  );
};

export default InvoiceItems;
