import { Button, Col, Divider, InputNumber, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DealershipProfile from '../components/Dealerships/dealership-profile/DealershipProfile';
import FeaturesList from '../components/Settings/FeaturesList';
import PermissionsList from '../components/Settings/PermissionsList';
import {
  BadgeIcon,
  ContentWrapper,
  LoadingSpinner,
  Modal,
} from '../components/UI';
import { toastrSuccess } from '../helpers/toastr';
import { initFeatures } from '../lib/constants/features';
import { Payments } from '../lib/constants/payments';
import { availablePermissions } from '../lib/constants/permissions';
import {
  addDealershipPermissions,
  getDealershipPermissions,
} from '../lib/dealership';
import {
  editDealership,
  fetchDealership,
} from '../store/actions/dealership-actions';
import { selectDealershipById } from '../store/slices/dealership-slice';
import moment from 'moment';
import { useRef } from 'react';
import FeaturePrices from '../components/features/FeaturePrices';
import { PRIMARY_DATE_FORMAT } from '../lib/constants/utils';

const DealershipDetail = () => {
  const { dealershipId } = useParams();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFeature, setIsLoadingFeature] = useState(false);
  const [isLoadingPermission, setIsLoadingPermission] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [dealershipDermissions, setDealershipPermissions] = useState([]);
  const [dealership, setDealership] = useState(
    useSelector((state) => selectDealershipById(state, dealershipId))
  );
  const [toggleCredits, setToggleCredits] = useState(false);
  const [toggleFeaturePrices, setToggleFeaturePrices] = useState(false);

  const creditsInput = useRef();

  /**
   * if dealership is 'undefined', dispatch an action
   * to get it from backend, e.g. when reload the page.
   */
  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const getDealership = async (signal) => {
      setIsLoading(true);

      const options = { signal };

      try {
        const data = await dispatch(
          fetchDealership(dealershipId, options)
        ).unwrap();

        setDealership(data);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    if (!dealership) {
      getDealership(signal);
    }
  }, [dispatch, dealershipId, dealership]);

  const setPermissions = (data) => {
    const permissions = availablePermissions.map((permission) => {
      const isChecked = data[permission.backendName] || false;
      return { ...permission, isChecked };
    });

    setDealershipPermissions(permissions);
  };

  const loadDealershipPermissions = useCallback(
    async (options = {}) => {
      setIsLoadingPermissions(true);

      try {
        const data = await getDealershipPermissions(dealershipId, options);

        setPermissions(data);
      } catch (err) {}

      setIsLoadingPermissions(false);
    },
    [dealershipId]
  );

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    loadDealershipPermissions({ signal });

    return () => controler.abort();
  }, [loadDealershipPermissions]);

  const onChangeFeatureHandler = async (name, checked, e) => {
    setIsLoadingFeature(true);

    const dealershipFeatures = dealership.features || [];

    const features = checked
      ? [...dealershipFeatures, name.toUpperCase()]
      : dealershipFeatures.filter((f) => f !== name.toUpperCase());

    try {
      const response = await dispatch(
        editDealership({ dealershipId, body: { features } })
      ).unwrap();

      setDealership(response);
    } catch (err) {}

    setIsLoadingFeature(false);
  };

  const changePermissionHandler = async (name, checked, e) => {
    let body = {};
    body[name] = checked;

    setIsLoadingPermission(true);

    try {
      const data = await addDealershipPermissions(dealershipId, body);

      setPermissions(data);
      toastrSuccess(`Permission ${checked ? 'Enabled' : 'Disabled'}`);
    } catch (err) {}

    setIsLoadingPermission(false);
  };

  const assignCredits = async () => {
    try {
      const res = await dispatch(
        editDealership({
          dealershipId: dealership.id,
          body: { credits: +creditsInput.current.defaultValue },
        })
      ).unwrap();

      setToggleCredits(false);
      setDealership(res);
    } catch (err) {}
  };

  const creditsType =
    dealership && dealership?.price_type === Payments.PER_USE
      ? 'PREPAID'
      : dealership?.price_type;

  const credits =
    dealership && dealership?.price_type === Payments.UNLIMITED
      ? `Unlimited (until: ${moment(dealership?.unlimited_until).format(
          PRIMARY_DATE_FORMAT
        )})`
      : dealership?.credits
      ? dealership?.credits
      : '0';

  let content;

  if (!isLoading && dealership) {
    content = (
      <>
        <DealershipProfile dealership={dealership} />
        <Col className="my-3" xs={24} md={12}>
          <div className="flex_between">
            <div>
              <BadgeIcon title={'CREDITS'} />
              <span>{credits}</span>
              <p className="small mt-2">
                Credits type:{' '}
                <span style={{ fontWeight: '500' }}>{creditsType}</span>
              </p>
            </div>
            {dealership.price_type !== Payments.UNLIMITED && (
              <Button
                type="default"
                onClick={() => setToggleCredits(true)}
                className="d-flex align-items-center"
              >
                Assign credits
              </Button>
            )}
          </div>
        </Col>
        <Row gutter={[16, 16]} justify="space-between">
          <Col xs={24} md={11}>
            <Divider orientation="left">Features</Divider>
            {dealership.features && (
              <Button
                type="default"
                onClick={() => setToggleFeaturePrices(true)}
                className="d-flex align-items-center mb-3"
              >
                Features prices
              </Button>
            )}
            <FeaturesList
              onChange={onChangeFeatureHandler}
              features={initFeatures}
              userFeatures={dealership.features}
              prices={dealership?.prices || []}
              isLoading={isLoadingFeature}
            />
          </Col>
          <Col xs={24} md={11}>
            <Divider orientation="left">Permissions</Divider>
            {!isLoadingPermissions ? (
              <PermissionsList
                permissions={dealershipDermissions}
                onChange={changePermissionHandler}
                isLoading={isLoadingPermission}
              />
            ) : (
              <p>Loading permissions...</p>
            )}
          </Col>
        </Row>
      </>
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  }

  return (
    <>
      <ContentWrapper>{content}</ContentWrapper>
      {toggleCredits && (
        <Modal
          title={'Change Credits'}
          onClose={() => {
            setToggleCredits(false);
          }}
        >
          <div className="d-flex align-items-center">
            <InputNumber ref={creditsInput} />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              disabled={isLoading}
              onClick={() => {
                setToggleCredits(false);
              }}
            >
              Close
            </Button>
            <Button
              loading={isLoading}
              onClick={assignCredits}
              className="ms-2"
              type="primary"
            >
              Submit
            </Button>
          </div>
        </Modal>
      )}
      {toggleFeaturePrices && (
        <FeaturePrices
          prices={dealership.prices}
          allFeatures={dealership.features}
          initFeatures={initFeatures}
          onClose={() => setToggleFeaturePrices(false)}
          isVisible={toggleFeaturePrices}
          dealershipId={dealershipId}
          onSetDealership={(dealership) => setDealership(dealership)}
        />
      )}
    </>
  );
};

export default DealershipDetail;
