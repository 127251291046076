import { CheckOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../lib/auth';
import { Status } from '../lib/constants/status';
import { useTranslation } from 'react-i18next';

import classes from './css/ResetPassword.module.css';

const ResetPassword = () => {
  const [resetStatus, setResetStatus] = useState(Status.IDLE);

  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const formItemLayout = {
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    const password = values['password'];

    resetPasswordHandler(password);
  };

  const resetPasswordHandler = async (password) => {
    setResetStatus(Status.LOADING);

    try {
      await resetPassword(password, token);

      setResetStatus(Status.COMPLETED);

      setTimeout(() => {
        return navigate('/login');
      }, 2500);
    } catch (err) {
      setResetStatus(Status.FAILED);
    }
  };

  let content;

  if (resetStatus === Status.COMPLETED) {
    content = (
      <>
        <div className={classes.success_password}>
          {t('global.successPasswordChange')}
          <CheckOutlined
            style={{
              fontSize: '1.3rem',
              margin: '0 3px',
              color: 'var(--color-ui-ok)',
            }}
          />{' '}
          {t('global.redirecting')}
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div className={classes.txt_icon}>
          <LockOutlined
            className="site-form-item-icon primary_clr"
            style={{ fontSize: '3rem' }}
          />
          <p>{t('global.resetPassword')}</p>
        </div>
        <Form onFinish={onFinish} form={form} {...formItemLayout}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('global.formValidationError.requiredPassword'),
              },
            ]}
            hasFeedback
          >
            <Input
              type="password"
              placeholder={t('global.form.enterNewPassword')}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: t('global.form.confirmPassword') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('global.formValidationError.mismatchPasswords'))
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={t('global.form.enterConfirmPassword')}
            />
          </Form.Item>

          <Button
            className="w-100"
            type="primary"
            loading={resetStatus === Status.LOADING}
            htmlType="submit"
          >
            {t('global.resetPassword')}
          </Button>
        </Form>
      </>
    );
  }

  return (
    <div
      className="w-100 d-flex justify-content-center"
      style={{ height: '100vh', backgroundColor: '#fff' }}
    >
      <div className={classes.form_wrapper}>{content}</div>
    </div>
  );
};

export default ResetPassword;
