import { useEffect, useState } from 'react';

import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Drawer,
  Form,
  Col,
  Row,
  Input,
  Button,
  Space,
  Select,
  Typography,
  Divider,
} from 'antd';
import classes from './UserForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addGroup } from '../../store/actions/user-group-actions';
import { selectAllGroups } from '../../store/slices/user-group-slice';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';
import { useTranslation } from 'react-i18next';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import { Roles } from '../../lib/constants/roles';

const { Option } = Select;

const UserForm = ({
  onCreateUser,
  onEditUser,
  isVisible,
  isSM,
  isAdding,
  onClose,
  selectedUser,
  dealershipId,
  groupId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const groups = useSelector(selectAllGroups);
  const currentDealership = useSelector(selectCurrentDealership);
  const loggedInUser = useSelector(selectLoggedInUser);
  const [name, setName] = useState('');
  const [dealership, setDealership] = useState(currentDealership);
  const [selectUserType, setSelectUserType] = useState(() => {
    const dealership = selectedUser?.dealerships?.find(
      (dealership) => dealership.id === dealershipId
    );

    if (dealership) {
      return dealership.user_dealership.user_role;
    }

    return null;
  });

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();

    try {
      dispatch(addGroup({ id: dealershipId, group: { name } })).unwrap();

      setName('');
    } catch (err) {}
  };

  const onFinish = (values) => {
    const moreInfoFields = ['contact_email'];

    const { moreInfoValues, basicValues } = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (moreInfoFields.includes(key)) {
          acc.moreInfoValues[key] = value;
        } else {
          acc.basicValues[key] = value;
        }
        return acc;
      },
      { moreInfoValues: {}, basicValues: {} }
    );

    if (!selectedUser) {
      onFinishCreate(basicValues, moreInfoValues);
    } else {
      onFinishEdit(basicValues, moreInfoValues);
    }
  };

  const allValuesAreUndefined = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        return false; // Found a key with a non-undefined value
      }
    }
    return true; // All values are undefined
  };

  const onFinishCreate = (values, moreInfoValues) => {
    let newUser = {};
    let additionalInfo = {};

    for (const key in values) {
      let value = values[key];

      newUser[key] = value || undefined;
    }

    for (const key in moreInfoValues) {
      let value = moreInfoValues[key];

      additionalInfo[key] = value || undefined;
    }

    if (allValuesAreUndefined(additionalInfo)) {
      additionalInfo = {};
    }

    delete newUser['confirmPassword'];

    onCreateUser(newUser, additionalInfo);
  };

  const onFinishEdit = (values, moreInfoValues) => {
    let user = {};

    Object.keys(selectedUser).forEach((key) => {
      if (key in values) {
        if (selectedUser[key] !== values[key]) {
          user[key] = values[key] || null;
        }
      }
    });

    let updatedValues = {};

    if (moreInfoValues) {
      Object.keys(dealership.user_dealership).forEach((key) => {
        if (key in moreInfoValues) {
          if (dealership.user_dealership[key] !== moreInfoValues[key]) {
            updatedValues[key] =
              moreInfoValues[key] && moreInfoValues[key] !== ''
                ? moreInfoValues[key]
                : null;
          }
        }
      });
    }

    if (
      Object.keys(user).length === 0 &&
      user.constructor === Object &&
      Object.keys(updatedValues).length === 0 &&
      updatedValues.constructor === Object
    ) {
      return;
    }

    onEditUser(user, updatedValues);
  };

  useEffect(() => {
    if (selectedUser) {
      const currentDealership = selectedUser.dealerships.find(
        (dealership) => dealership.id === dealershipId
      );

      if (currentDealership) {
        setDealership(currentDealership);
      }
    }
  }, [dealershipId, selectedUser]);

  const onSubmitHandler = () => {
    form.submit();
  };

  const initialValues = () => {
    let values = {};

    if (selectedUser) {
      values['name'] = selectedUser.name;
      values['email'] = selectedUser.email;
      values['country'] = selectedUser.country;
      values['city'] = selectedUser.city;
      values['address'] = selectedUser.address;
      values['phone'] = selectedUser.phone;

      const dealership = selectedUser.dealerships.find(
        (dealership) => dealership.id === dealershipId
      );

      if (dealership) {
        values['user_role'] = dealership.user_dealership.user_role;
        values['group_id'] = dealership.user_dealership.group_id;
        values['contact_email'] = dealership.user_dealership?.contact_email;
      }
    }

    if (groupId) {
      values['group_id'] = groupId;
    }

    return values;
  };

  return (
    <Drawer
      title={
        selectedUser
          ? t('users.user.form.editUser')
          : t('users.user.form.createNewUser')
      }
      width={isSM ? '100%' : 545}
      onClose={onClose}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>{t('global.form.cancel')}</Button>
          <Button
            type="primary"
            loading={isAdding}
            // disabled={disabledSave}
            onClick={onSubmitHandler}
            htmlType="submit"
          >
            {t('global.form.submit')}
          </Button>
        </Space>
      }
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        initialValues={initialValues()}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label={t('global.form.name')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredName'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.enterUserName')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label={t('global.form.email')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredEmail'),
                },
                {
                  type: 'email',
                  message: t('global.formValidationError.validEmail'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.createNewUser')} />
            </Form.Item>
          </Col>
        </Row>
        {!selectedUser && (
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label={t('global.form.password')}
                rules={[
                  {
                    required: true,
                    message: t('global.formValidationError.requiredPassword'),
                  },
                ]}
                hasFeedback
              >
                <Input
                  type="password"
                  placeholder={t('users.user.form.enterPassword')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="confirmPassword"
                label={t('global.form.confirmPassword')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, message: t('global.form.confirmPassword') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t('global.formValidationError.mismatchPasswords')
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type="password"
                  placeholder={t('users.user.form.enterConfirmPassword')}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!selectedUser && (
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="user_role"
                label={t('users.user.form.userRole')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('users.user.form.selectUserRole')}
                  allowClear
                  onChange={(value) => setSelectUserType(value)}
                >
                  <Option value="USER">{t('users.user.user')}</Option>
                  <Option value="ADMIN">Admin</Option>
                  {/* <Option value="MODERATOR">Moderator</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="group_id" label="User Group">
                <Select
                  placeholder={t('users.user.form.selectGroup')}
                  allowClear
                  disabled={!!groupId}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space align="center" style={{ padding: '0 8px 4px' }}>
                        <Input
                          placeholder={t('users.user.form.newGroup')}
                          value={name}
                          onChange={onNameChange}
                        />
                        <Typography.Link
                          onClick={addItem}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <PlusOutlined /> {t('users.user.form.addGroup')}
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {!!groups.length &&
                    groups.map((group) => (
                      <Option key={group.id} value={group.id}>
                        {group.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="country"
              label={t('global.form.country')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredCountry'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.enterCountry')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="city"
              label={t('global.form.city')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredCity'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.enterCity')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="address"
              label={t('global.form.address')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredAddress'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.enterAddress')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="phone"
              label={t('global.form.phone')}
              rules={[
                {
                  required: true,
                  message: t('global.formValidationError.requiredPhone'),
                },
              ]}
            >
              <Input placeholder={t('users.user.form.enterPhone')} />
            </Form.Item>
          </Col>
        </Row>
        {dealershipId && selectUserType === 'ADMIN' && (
          <>
            <Divider></Divider>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="contact_email"
                  label={t('users.user.form.contactEmail')}
                  rules={[
                    {
                      type: 'email',
                      message: t('global.formValidationError.validEmail'),
                    },
                  ]}
                  extra={t('users.user.form.contactEmailInfo')}
                >
                  <Input placeholder={t('users.user.form.enterContactEmail')} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {/* {!dealershipId && (
          <>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="contact_email"
                  label="Contact E-mail"
                  rules={[
                    {
                      type: 'email',
                      message: t('global.formValidationError.validEmail'),
                    },
                  ]}
                  extra="Email for receiving request notifications"
                >
                  <Input placeholder="Enter contact email..." />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="contact_email"
                  label="Contact E-mail"
                  rules={[
                    {
                      type: 'email',
                      message: t('global.formValidationError.validEmail'),
                    },
                  ]}
                  extra="Email for receiving request notifications"
                >
                  <Input placeholder="Enter contact email..." />
                </Form.Item>
              </Col>
            </Row>
          </>
        )} */}
      </Form>
    </Drawer>
  );
};

export default UserForm;
