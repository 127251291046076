import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
  lang: i18next.language || 'en',
};

const antdSlice = createSlice({
  name: 'antd',
  initialState: initialState,
  reducers: {
    setLang(state, action) {
      const { lang } = action.payload;

      state.lang = lang;
    },
  },
});

export const selectLang = (state) => state.antd.lang;

export const antdActions = antdSlice.actions;

export default antdSlice;
