import { useState } from 'react';

import {
  ExclamationCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Drawer,
  Form,
  Col,
  Row,
  Input,
  Button,
  Space,
  Upload,
  Select,
  message,
  DatePicker,
  Image,
  Switch,
} from 'antd';
import { Payments } from '../../lib/constants/payments';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  removeLogo,
  uploadLogoFile,
} from '../../store/actions/dealership-actions';
import { Status } from '../../lib/constants/status';
import Overlay from '../UI/overlay/Overlay';
import { ChromePicker } from 'react-color';

import classes from './DealershipForm.module.css';
import { ConfirmModal } from '../UI';
import { isPastDate } from '../../helpers/_util';

const { Dragger } = Upload;
const { Option } = Select;
const defaultColor = '#5bb9eb';

const DealershipForm = ({
  selectedDealership,
  onCreateDealerships,
  onUpdateDealership,
  onClose,
  isVisible,
  isLoading,
  isSM,
}) => {
  const [disabledSave, setDisabledSave] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [toggleRemove, setToggleRemove] = useState(false);
  const [color, setColor] = useState(
    selectedDealership && selectedDealership.label_color
      ? selectedDealership.label_color
      : defaultColor
  );
  const [formStatus, setFormStatus] = useState(Status.IDLE);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [dealership, setDealership] = useState(selectedDealership);
  const [paymentType, setPaymentType] = useState(
    selectedDealership ? selectedDealership.price_type : Payments.PER_USE
  );

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = () => {
    let values = {};

    values['price_type'] = paymentType;

    if (selectedDealership) {
      values['name'] = selectedDealership.name;
      values['email'] = selectedDealership.email;
      values['country'] = selectedDealership.country;
      values['city'] = selectedDealership.city;
      values['address'] = selectedDealership.address;
      values['phone'] = selectedDealership.phone;
      values['price_type'] = selectedDealership.price_type;
      values['full_details_preview'] = selectedDealership.full_details_preview;
      if (selectedDealership.price_type === Payments.UNLIMITED) {
        values['unlimited_until'] = moment(selectedDealership.unlimited_until);
      }
    }

    return values;
  };

  const uploadFile = async (file, dealership) => {
    let formData = new FormData();
    formData.append('image', file);

    const data = await dispatch(
      uploadLogoFile({ dealershipId: dealership.id, body: formData })
    ).unwrap();

    return data;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const props = {
    name: 'file',
    customRequest(e) {
      selectedDealership ? uploadFileHandler(e.file) : setSelectedFile(e.file);
    },
    beforeUpload: async (file) => {
      if (!selectedDealership) {
        const url = await getBase64(file);
        const thumbUrl = await getBase64(file);

        const newFile = { ...file, url, thumbUrl };
        setFileList([newFile]);
        // return false;
      }
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();

      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const uploadFileHandler = async (file) => {
    setFormStatus(Status.LOADING);

    try {
      const data = await uploadFile(file, selectedDealership);

      setDealership(data);
      setFormStatus(Status.COMPLETED);
    } catch (err) {
      setFormStatus(Status.FAILED);
    }
  };

  const onFinish = (values) => {
    const data = values['unlimited_until']
      ? {
          ...values,
          unlimited_until: new Date(
            values['unlimited_until'].format('YYYY-MM-DD')
          ).toISOString(),
        }
      : values;

    !selectedDealership ? onFinishCreate(data) : onFinishEdit(data);
  };

  const onFinishCreate = (values) => {
    let newDealership = {};

    for (const key in values) {
      let value = values[key];

      newDealership[key] = value || undefined;
    }

    if (color !== defaultColor) {
      newDealership['label_color'] = color;
    }

    onCreateDealerships(newDealership, selectedFile);
    // form.resetFields();
  };

  const onFinishEdit = (values) => {
    let dealership = {};

    Object.keys(selectedDealership).forEach((key) => {
      if (key in values) {
        if (selectedDealership[key] !== values[key]) {
          dealership[key] = values[key] ?? null;
        }
      }
    });

    if (selectedDealership.label_color) {
      if (selectedDealership.label_color !== color) {
        dealership['label_color'] = color !== defaultColor ? color : null;
      }
    } else {
      if (color !== defaultColor) {
        dealership['label_color'] = color;
      }
    }

    if (
      Object.keys(dealership).length === 0 &&
      dealership.constructor === Object
    ) {
      return;
    }

    onUpdateDealership(dealership);
  };

  const onSubmitHandler = () => {
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.name !== undefined &&
      allValues.email !== undefined &&
      allValues.name !== '' &&
      allValues.email !== ''
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  };

  const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: 'Please select date.',
        format: 'YYYY-MM-DD',
      },
    ],
  };

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const removeLogoFile = async () => {
    setFormStatus(Status.LOADING);

    try {
      const data = await dispatch(removeLogo(selectedDealership.id)).unwrap();

      setDealership(data);
    } catch (err) {}

    setFormStatus(Status.COMPLETED);
    setToggleRemove(false);
  };

  return (
    <>
      <Drawer
        title={
          selectedDealership ? 'Edit Dealership' : 'Create a new Dealership'
        }
        width={isSM ? '100%' : 545}
        onClose={onClose}
        visible={isVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="primary"
              loading={isLoading}
              disabled={disabledSave}
              onClick={onSubmitHandler}
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          initialValues={initialValues()}
          onValuesChange={onValuesChange}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required.' }]}
              >
                <Input placeholder="Enter Dealership name..." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { required: true, message: 'E-mail is required.' },
                  { type: 'email', message: 'E-mail must be valid.' },
                ]}
              >
                <Input placeholder="Enter E-mail address..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="country" label="Country">
                <Input placeholder="Enter country..." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="city" label="City">
                <Input placeholder="Enter city..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="address" label="Address">
                <Input placeholder="Enter address..." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: true, message: 'Phone is reqired' }]}
              >
                <Input placeholder="Enter phone number..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                rules={[
                  { required: true, message: 'Payment Type is required.' },
                ]}
                name="price_type"
                label="Payment"
              >
                <Select
                  placeholder="Select Payment"
                  onChange={(value) => setPaymentType(value)}
                >
                  <Option value={'PER_USE'}>Prepaid</Option>
                  <Option value={'MONTHLY'}>Monthly</Option>
                  <Option value={'UNLIMITED'}>Unlimited</Option>
                </Select>
              </Form.Item>
            </Col>
            {paymentType === Payments.UNLIMITED && (
              <Col xs={24} sm={12}>
                <Form.Item
                  className="w-100"
                  name="unlimited_until"
                  label="Unlimited Until"
                  {...config}
                >
                  <DatePicker
                    disabledDate={(date) => isPastDate(date.toDate())}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={24}>
              <div
                className="flex_between mb-3"
                style={{ alignItems: 'initial' }}
              >
                <div className="d-flex flex-column">
                  <span>Full details preview</span>
                  <span className="small">
                    Can admin see full details request?
                  </span>
                </div>
                <Form.Item name="full_details_preview" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="Color Theme">
                <div className="flex_between">
                  <div className={classes.swatch} onClick={handleClick}>
                    <div
                      className={classes.color}
                      style={{ backgroundColor: color }}
                    />
                  </div>
                  <Button type="ghost" onClick={() => setColor(defaultColor)}>
                    Default
                  </Button>
                </div>
                {displayColorPicker ? (
                  <div className={classes.popover}>
                    <ChromePicker
                      color={color}
                      onChangeComplete={handleChange}
                    />
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {(!dealership || (dealership && !dealership.logo)) && (
                <div style={{ minHeight: '90px' }}>
                  {formStatus === Status.LOADING && (
                    <Overlay text={'Uploading...'} />
                  )}
                  {isLoading && selectedFile && (
                    <p style={{ color: '#d72121' }} className="small">
                      Please wait while the logo is uploading...
                    </p>
                  )}
                  <Dragger
                    {...props}
                    listType="picture"
                    fileList={fileList}
                    maxCount={1}
                    disabled={formStatus === Status.LOADING}
                  >
                    <p className="ant-upload-drag-icon mb-2">
                      <InboxOutlined style={{ fontSize: '32px' }} />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to upload Tutorial File
                    </p>
                  </Dragger>
                </div>
              )}
              {dealership && dealership.logo && (
                <div className="flex_between">
                  <div className="d-flex flex-column align-items-center">
                    <Image width={100} src={dealership.logo.paths.original} />
                    <span
                      className="small mt-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setToggleRemove(true)}
                    >
                      remove x
                    </span>
                  </div>
                  <Upload {...props} showUploadList={false}>
                    <Button
                      loading={formStatus === Status.LOADING}
                      disabled={formStatus === Status.LOADING}
                      icon={<UploadOutlined />}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </Drawer>

      {toggleRemove && (
        <ConfirmModal
          title={'Delete Logo'}
          buttonText={'Remove'}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          danger={true}
          description={<>Are you sure you want to remove logo ?</>}
          onClose={() => setToggleRemove(false)}
          onConfirm={removeLogoFile}
          isLoading={formStatus === Status.LOADING}
        />
      )}
    </>
  );
};

export default DealershipForm;
