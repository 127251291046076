import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const style = {
  position: 'absolute',
  zIndex: 9,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#000',
  opacity: 0.5,
  color: '#fff',
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Overlay = ({ text, spinner = true }) => {
  return (
    <div style={style}>
      {spinner && <Spin indicator={antIcon} />}{' '}
      {text && <p className="ms-2">{text}</p>}
    </div>
  );
};

export default Overlay;
