import { Col, Row } from 'antd';
import React from 'react';
import InvoiceItem from './InvoiceItem';

const InvoicesList = ({ invoices, dealershipId }) => {
  return (
    <Row gutter={[8, 8]}>
      {invoices.map((invoice, index) => (
        <Col
          key={index}
          className="d-flex"
          xs={24}
          sm={12}
          md={8}
          lg={8}
          xl={6}
          xxl={4}
        >
          <InvoiceItem invoice={invoice} dealershipId={dealershipId} />
        </Col>
      ))}
    </Row>
  );
};

export default InvoicesList;
