import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';
import { selectLoggedInUser } from '../../store/slices/auth-slice';

function RoleGuard({ role, userRole }) {
  const loggedInUser = useSelector(selectLoggedInUser);
  const currentDealership = useSelector(selectCurrentDealership);

  if (role !== loggedInUser.type) {
    return <Navigate to="/login" />;
  }

  if (userRole && userRole !== currentDealership.user_dealership.user_role) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

export default RoleGuard;
