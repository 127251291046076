import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Roles } from '../../lib/constants/roles';
import { selectLoggedInUser } from '../../store/slices/auth-slice';

function PrivateRoute({ isAuthenticated }) {
  const loggedInUser = useSelector(selectLoggedInUser);
  let location = useLocation();

  if (!isAuthenticated && location.pathname !== '/login') {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthenticated && location.pathname === '/login') {
    const from = location.state?.from?.pathname;
    const search = location.state?.from?.search || '';

    let path = from || '';

    switch (loggedInUser.type) {
      case Roles.ROOT:
        path = from || '/dealerships';
        break;
      case Roles.DEALERSHIP:
        path = from || '/clients';
        break;
      default:
        break;
    }

    path += search;

    return <Navigate replace to={path} />;
  }

  return <Outlet />;
}

export default PrivateRoute;
