import {
  addDealershipFeaturePrice,
  createDealership,
  deleteDealership,
  deleteDealershipPrice,
  deleteLogo,
  getDealership,
  getDealershipPermissions,
  getDealerships,
  updateDealership,
  updateDealershipPrice,
  uploadLogo,
} from '../../lib/dealership';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchDealerships = createAsyncThunk(
  'dealerships/fetchDealerships',
  async ({ pagination, signal, params = null }) => {
    const data = await getDealerships(pagination, signal, params);

    return data;
  }
);

export const fetchDealership = createAsyncThunk(
  'dealerships/fetchDealership',
  async (dealershipId, options = {}) => {
    const data = await getDealership(dealershipId, options);

    return data;
  }
);

export const editDealership = createAsyncThunk(
  'dealerships/editDealership',
  async ({ dealershipId, body }) => {
    const response = await updateDealership(dealershipId, body);

    return response;
  }
);

export const addDealership = createAsyncThunk(
  'dealerships/addDealership',
  async (dealership, { rejectWithValue }) => {
    try {
      const response = await createDealership(dealership);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeDealership = createAsyncThunk(
  'dealerships/removeDealership',
  async (dealershipId) => {
    await deleteDealership(dealershipId);

    return dealershipId;
  }
);

export const fetchDealershipPermissions = createAsyncThunk(
  'dealerships/fetchDealershipPermissions',
  async (dealershipId, options = {}) => {
    const response = await getDealershipPermissions(dealershipId, options);

    return response;
  }
);

export const editDealershipPrice = createAsyncThunk(
  'dealerships/editDealership',
  async ({ dealershipId, body }) => {
    const response = await updateDealershipPrice(dealershipId, body);

    return response;
  }
);

export const addDealershipPrice = createAsyncThunk(
  'dealerships/editDealership',
  async ({ dealershipId, body }) => {
    const response = await addDealershipFeaturePrice(dealershipId, body);

    return response;
  }
);

export const removeDealershipPrice = createAsyncThunk(
  'dealerships/editDealership',
  async ({ dealershipId, body }) => {
    const response = await deleteDealershipPrice(dealershipId, body);

    return response;
  }
);

export const uploadLogoFile = createAsyncThunk(
  'dealerships/uploadLogoFile',
  async ({ dealershipId, body }, { rejectWithValue }) => {
    try {
      const response = await uploadLogo(dealershipId, body);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeLogo = createAsyncThunk(
  'dealerships/removeLogo',
  async (dealershipId, { rejectWithValue }) => {
    try {
      const response = await deleteLogo(dealershipId);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
