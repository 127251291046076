import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { toastrSuccess } from '../../helpers/toastr';
import {
  editUser,
  editUserAssigment,
  fetchUsers,
  removeUser,
  switchUserFeature,
  uploadUserImage,
} from '../actions/user-actions';

const initialState = {
  users: [],
  isLoading: true,
  count: 0,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser(state, action) {
      const { newUser } = action.payload;

      // state.users.push(newUser);
      state.users = [newUser, ...state.users].slice(0, -1);
      state.count++;
    },
    remove(state, action) {
      const { userId } = action.payload;

      state.users = state.users.filter((user) => user.id !== userId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { users, count } = action.payload;

        state.users = users;
        state.count = count;
        state.isLoading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        // const {meta} = action
        // if (meta?.arg?.signal?.aborted) return;
        state.isLoading = false;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        const { id } = action.payload;

        // ! kad se obrise user, mozda je bolje da se ponovo pozove ruta za get usera
        // ! jer baguje onda count ako se odradi samo ovako
        // ! mozda je i okej ovako, proveriti !
        state.users = state.users.filter((user) => user.id !== id);
        state.count = state.count - 1;

        toastrSuccess('Successfully removed.');
      })
      .addMatcher(
        isAnyOf(
          editUser.fulfilled,
          uploadUserImage.fulfilled,
          editUserAssigment.fulfilled,
          switchUserFeature.fulfilled
        ),
        (state, action) => {
          const index = state.users.findIndex(
            (user) => user.id === action.payload.id
          );

          if (~index) {
            state.users[index] = action.payload;
          }
        }
      );
  },
});

export const selectAllUsers = (state) => state.users.users;
export const selectUsersCount = (state) => state.users.count;

export const selectUserById = (state, userId) =>
  state.users.users.find((user) => user.id === userId);

export const userActions = userSlice.actions;

export default userSlice;
