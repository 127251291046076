import { createSelector, createSlice } from '@reduxjs/toolkit';
import { toastrSuccess } from '../../helpers/toastr';
import { Status } from '../../lib/constants/status';
import {
  addGroup,
  editGroup,
  fetchGroups,
  removeGroup,
} from '../actions/user-group-actions';
import i18n from 'i18next';

const initialState = {
  groups: [],
  status: Status.IDLE, // idle | loading | complete | failed
};

const userGroupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action) {
      const { data } = action.payload;
      state.groups = data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGroups.pending, (state, action) => {
        state.status = Status.LOADING;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = Status.COMPLETED;
        state.groups = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.status = Status.FAILED;
      })
      .addCase(addGroup.fulfilled, (state, action) => {
        const newGroup = action.payload;

        state.groups.push(newGroup);

        toastrSuccess(
          i18n.t('global.successCreated', {
            name: newGroup.name,
          })
        );
      })
      .addCase(editGroup.fulfilled, (state, action) => {
        const editGroup = action.payload;

        const index = state.groups.findIndex(
          (group) => group.id === editGroup.id
        );

        if (~index) {
          state.groups[index] = editGroup;
        }

        toastrSuccess(
          i18n.t('global.successEdited', {
            name: editGroup.name,
          })
        );
      })
      .addCase(removeGroup.fulfilled, (state, action) => {
        const groupId = action.payload;

        state.groups = state.groups.filter((group) => group.id !== groupId);

        toastrSuccess(i18n.t('global.successDeleted'));
      });
  },
});

export const selectAllGroups = (state) => state.groups.groups;
export const getGroupsStatus = (state) => state.groups.status;

export const selectPinnedGroups = createSelector([selectAllGroups], (groups) =>
  groups.filter((group) => group.pinned)
);

export const userGroupActions = userGroupSlice.actions;

export default userGroupSlice;
