import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/UI/content-wrapper/ContentWrapper';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <Result
        status="404"
        title="404"
        subTitle={t('errorPages.subTitle404')}
        extra={
          <Button type="primary">
            <Link to="/" replace>
              {t('global.backHome')}
            </Link>
          </Button>
        }
      />
    </ContentWrapper>
  );
};

export default NotFound;
