import React from 'react';
import { Card } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const { Meta } = Card;

const badgeStyle = {
  fontSize: '0.7rem',
  padding: '1px 3px',
  borderRadius: '2px',
  color: '#c70b15',
  backgroundColor: 'rgba(235, 91, 91, 51%)',
};

const TargetItem = ({ target, onEdit, onDelete, onChangeImage, onSetting }) => {
  const type =
    target.type.charAt(0).toUpperCase() + target.type.toLowerCase().slice(1) ||
    '/';

  const coverFile = target.target_image ? (
    <img
      alt="target"
      onClick={() => onChangeImage(target)}
      src={target.target_image.paths['320x240']}
    />
  ) : (
    <div
      className="flex_center"
      style={{ minHeight: '130px', background: '#ffe4e4' }}
    >
      <p>No target file.</p>
    </div>
  );

  return (
    <Card
      cover={coverFile}
      actions={[
        <SettingOutlined key="setting" onClick={() => onSetting(target)} />,
        <EditOutlined key="edit" onClick={() => onEdit(target)} />,
        <DeleteOutlined key="ellipsis" onClick={() => onDelete(target)} />,
      ]}
    >
      <Meta
        className="mb-2"
        title={target.name}
        description={`${target.position} - ${type}`}
      />

      {target.required && <span style={badgeStyle}>Required target</span>}
    </Card>
  );
};

export default TargetItem;
