import React from 'react';
import { List, Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import classes from './GroupItem.module.css';
import ThreeDots from '../UI/three-dots/ThreeDots';

const GroupItem = ({ group, onEditGroup, onDeleteGroup }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu style={{ minWidth: '165px' }}>
      <Menu.Item
        onClick={() => onEditGroup(group)}
        className={classes.flex_between}
        key="1"
      >
        {t('global.edit')}
        <EditOutlined />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={() => onDeleteGroup(group)}
        className={`${classes.flex_between} delete`}
        key="2"
      >
        {t('global.form.delete')}
        <DeleteOutlined />
      </Menu.Item>
    </Menu>
  );

  return (
    <List.Item>
      <List.Item.Meta
        title={<p>{group.name}</p>}
        description={group.description}
      />
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <ThreeDots />
      </Dropdown>
    </List.Item>
  );
};

export default GroupItem;
