import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Space } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmModal,
  ContentWrapper,
  LoadingSpinner,
  Modal,
  PageHeader,
} from '../components/UI';
import { toastrSuccess } from '../helpers/toastr';
import { initFeatures } from '../lib/constants/features';
import {
  addDefaultPrice,
  deleteDefaultpPrice,
  getDefaultPrices,
  updateDefaultPrice,
} from '../lib/features';

const Features = () => {
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [togglePriceForm, setTogglePriceForm] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const loadDefaultPrices = useCallback(async (signal) => {
    setIsLoading(true);

    try {
      const data = await getDefaultPrices({ signal });

      let allFeatures = initFeatures.map((feature) => {
        const featurePrice = data.find((d) => d.name === feature.name);

        return { ...feature, price: featurePrice?.price || null };
      });

      setFeatures(allFeatures);
    } catch (err) {}

    setIsLoading(false);
  }, []);

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    loadDefaultPrices(signal);

    return () => controler.abort();
  }, [loadDefaultPrices]);

  const onDeleteHandler = (price) => {
    setSelectedFeature(price);
    setToggleDelete(true);
  };

  const onEditPriceHandler = (feature) => {
    setSelectedFeature(feature);
    form.setFieldsValue({
      price: feature.price,
    });
    setTogglePriceForm(true);
  };

  const deletePrice = async () => {
    setIsLoading(true);

    try {
      const data = await deleteDefaultpPrice({
        feature: selectedFeature.name.toUpperCase(),
      });
      setFeatures((prevFeatures) => {
        const features = prevFeatures.map((feature) => {
          return feature.name.toLowerCase() ===
            selectedFeature.name.toLowerCase()
            ? { ...feature, price: null }
            : feature;
        });

        return features;
      });

      setIsLoading(false);
      toastrSuccess('Success');
      onCloseHandler();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onCloseHandler = () => {
    setToggleDelete(false);
    setTogglePriceForm(false);
    setSelectedFeature(null);
  };

  const onSubmitHandler = () => {
    form.submit();
  };

  const setFeaturePrices = (data) => {
    setFeatures((prevFeatures) => {
      const features = prevFeatures.map((feature) => {
        return feature.name.toLowerCase() === data.feature.toLowerCase()
          ? { ...feature, price: data.price }
          : feature;
      });

      return features;
    });
  };

  const onFinish = async (values) => {
    const { price } = values;
    const body = { feature: selectedFeature.name.toUpperCase(), price };

    setIsLoading(true);

    try {
      if (selectedFeature.price) {
        const data = await updateDefaultPrice(body);
        setFeaturePrices(data);
      } else {
        const data = await addDefaultPrice(body);
        setFeaturePrices(data);
      }

      setIsLoading(false);
      toastrSuccess('Success');
      onCloseHandler();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeader title={'Features Default Price'} showSearch={false} />
      <ContentWrapper>
        {!isLoading ? (
          <>
            {features.map((feature, index) => (
              <div key={index} className="flex_between mb-3">
                <div className="d-flex flex-column">
                  <p>
                    {feature.displayName}{' '}
                    <span className="small" style={{ fontWeight: '100' }}>
                      {feature.price
                        ? `(credits: ${feature.price})`
                        : '(without price)'}
                    </span>
                  </p>
                  <span className="small">{t(feature.description)}</span>
                </div>

                <Space>
                  {!feature.price && (
                    <Button
                      type="primary"
                      onClick={() => onEditPriceHandler(feature)}
                      icon={<PlusOutlined />}
                    />
                  )}
                  {feature.price && (
                    <>
                      <Button
                        type="ghost"
                        onClick={() => onEditPriceHandler(feature)}
                        icon={<EditOutlined />}
                      />
                      <Button
                        type="danger"
                        onClick={() => onDeleteHandler(feature)}
                        icon={<DeleteOutlined />}
                      />
                    </>
                  )}
                </Space>
              </div>
            ))}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>

      {toggleDelete && (
        <ConfirmModal
          title={'Remove custom price'}
          buttonText={'Remove'}
          description={
            <>
              Are you sure you want to delete a custom price for{' '}
              {selectedFeature.name.toLowerCase()} feature?
            </>
          }
          onClose={onCloseHandler}
          isLoading={isLoading}
          onConfirm={deletePrice}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
        />
      )}

      {togglePriceForm && (
        <Modal
          title={
            selectedFeature.price
              ? 'Edit price'
              : `Set price for ${selectedFeature.name} feature`
          }
          onClose={onCloseHandler}
        >
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="price"
                  rules={[{ required: true, message: 'Price is required.' }]}
                >
                  <InputNumber className="w-100" placeholder="Price" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onSubmitHandler}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default Features;
