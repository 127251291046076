import axios from '../api/axios';

export async function getDealerships(pagination, signal, params) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  const response = await axios.get('/dealerships', { params, headers, signal });

  const data = {
    dealerships: response?.data,
    count: response?.headers['pagination-count'] || 0,
  };

  return data;
}

export async function getDealership(dealershipId, options) {
  const response = await axios.get(`/dealerships/${dealershipId}`, options);

  return response.data;
}

export async function updateDealership(dealershipId, body) {
  const response = await axios.patch(
    `/dealerships/${dealershipId}`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function createDealership(body) {
  const response = await axios.post('/dealerships', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function deleteDealership(dealershipId) {
  const response = await axios.delete(
    `/dealerships/dealership/${dealershipId}`
  );

  return response.data;
}

export async function getUserDealerships(userId) {
  const response = await axios.get(`/users/${userId}/dealerships`);

  return response.data;
}

export async function getDealershipPermissions(dealershipId, options) {
  const response = await axios.get(
    `/dealerships/${dealershipId}/permissions`,
    options
  );

  return response.data;
}

export async function addDealershipPermissions(dealershipId, body) {
  const response = await axios.post(
    `/dealerships/${dealershipId}/permissions`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function addDealershipFeaturePrice(dealershipId, body) {
  const response = await axios.post(
    `/dealerships/${dealershipId}/price`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function updateDealershipPrice(dealershipId, body) {
  const response = await axios.patch(
    `/dealerships/${dealershipId}/price`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function deleteDealershipPrice(dealershipId, body) {
  const response = await axios.delete(`/dealerships/${dealershipId}/price`, {
    data: {
      ...body,
    },
  });

  return response.data;
}

export async function uploadLogo(dealershipId, body) {
  const response = await axios.post(`/dealerships/${dealershipId}/logo`, body, {
    headers: { 'Content-Type': 'application/json' },
  });

  return response.data;
}

export async function deleteLogo(dealershipId) {
  const response = await axios.delete(`/dealerships/${dealershipId}/logo`);

  return response.data;
}
