import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTarget,
  editTarget,
  fetchTargets,
  removeTarget,
  uploadTargetFile,
} from '../store/actions/target-actions';
import { Divider, Select } from 'antd';
import TargetsList from '../components/targets/TargetsList';
import TargetForm from '../components/targets/TargetForm';
import { useMediaQuery } from 'react-responsive';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { bodyType } from '../lib/constants/bodyType';
import {
  ConfirmModal,
  PageHeader,
  Modal,
  ContentWrapper,
  LoadingSpinner,
} from '../components/UI';
import {
  getTargetsStatus,
  selectAllTargets,
} from '../store/slices/target-slice';
import { Status } from '../lib/constants/status';

const { Option } = Select;

const Targets = () => {
  const dispatch = useDispatch();

  const targets = useSelector(selectAllTargets);
  const targetsStatus = useSelector(getTargetsStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [toggleTargetForm, setToggleTargetForm] = useState(false);
  const [toggleDeleteTarget, setToggleDeleteTarget] = useState(false);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedBodyType, setSelectedBodyType] = useState('all');
  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const filteredTargets = useMemo(() => {
    let filtered =
      selectedBodyType === 'all'
        ? targets
        : targets.filter((target) =>
            target.body_types.includes(selectedBodyType)
          );

    return filtered.filter((target) =>
      target.name.toLowerCase().includes(searchValue)
    );
  }, [searchValue, selectedBodyType, targets]);

  useEffect(() => {
    if (targetsStatus === Status.IDLE) {
      dispatch(fetchTargets());
    }
  }, [dispatch, targetsStatus]);

  const onCloseHandler = () => {
    setToggleTargetForm(false);
    setToggleDeleteTarget(false);
    setToggleSetting(false);
    setSelectedTarget(null);
  };

  const onOpenHandler = () => {
    setToggleTargetForm(true);
  };

  const editTargetHandler = (target) => {
    setSelectedTarget(target);
    onOpenHandler();
  };

  const onDeleteTarget = (target) => {
    setSelectedTarget(target);
    setToggleDeleteTarget(true);
  };

  const deleteTargetHandler = async () => {
    setIsLoading(true);

    try {
      await dispatch(removeTarget(selectedTarget.id));
    } catch (err) {}

    setIsLoading(false);
    setToggleDeleteTarget(false);
    setSelectedTarget(null);
  };

  const changeTypeHandler = (value) => {
    setSelectedBodyType(value);
  };

  const openSettingHandler = (target) => {
    setSelectedTarget(target);
    setToggleSetting(true);
  };

  let content;
  if (targetsStatus === Status.LOADING) {
    content = <LoadingSpinner />;
  } else if (targetsStatus === Status.COMPLETED) {
    content = (
      <div className="container-fluid p-0">
        <TargetsList
          onEditTarget={editTargetHandler}
          onDeleteTarget={onDeleteTarget}
          onSetting={openSettingHandler}
          targets={filteredTargets}
        />
      </div>
    );
  }

  const onSearchTargetsHandler = (value) => {
    setSearchValue(value);
  };

  return (
    <>
      <PageHeader
        buttonText={'+ New Target'}
        title={'Targets'}
        onClickButton={onOpenHandler}
        onSearch={onSearchTargetsHandler}
        extras={
          <>
            {!isSM && <div className="separator"></div>}
            <Select
              defaultValue="all"
              style={{ width: 130 }}
              onChange={changeTypeHandler}
              bordered={false}
              className="select_box"
            >
              <Option value="all">All Targets</Option>
              {bodyType.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </>
        }
      />

      <ContentWrapper>{content}</ContentWrapper>

      {toggleTargetForm && (
        <TargetForm
          isVisible={toggleTargetForm}
          isSM={isSM}
          onClose={onCloseHandler}
          selectedTarget={selectedTarget}
          isLoading={isLoading}
          // res={response}
        />
      )}

      {toggleSetting && (
        <Modal title={'Target Information'} onClose={onCloseHandler}>
          <Divider orientation="left">Body Types</Divider>
          {!!selectedTarget.body_types && selectedTarget.body_types.join(', ')}
        </Modal>
      )}

      {toggleDeleteTarget && (
        <ConfirmModal
          title={'Delete Target'}
          buttonText={'Delete'}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '1.3rem', color: '#fe4d4f' }}
            />
          }
          danger={true}
          description={
            <>
              Are you sure you want to delete target{' '}
              <span style={{ fontWeight: 'bold' }}>{selectedTarget.name}</span>{' '}
              ?
            </>
          }
          onClose={onCloseHandler}
          onConfirm={deleteTargetHandler}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Targets;
