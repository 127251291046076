import React, { useRef, useState } from 'react';

import { ReactComponent as FuelIcon } from '../../assets/img/vehicle/fuel_type_icon.svg';
import { ReactComponent as Gearbox } from '../../assets/img/vehicle/gearbox.svg';
import { ReactComponent as EnginePower } from '../../assets/img/vehicle/engine_power_icon.svg';
import { ReactComponent as EngineCapacity } from '../../assets/img/vehicle/engine_capacity_icon.svg';
import { ReactComponent as Mileage } from '../../assets/img/vehicle/mileage_icon.svg';
import { ReactComponent as Wheel } from '../../assets/img/vehicle/steering_wheel_icon.svg';
import { ReactComponent as BodyType } from '../../assets/img/vehicle/body_type_icon.svg';
import { BadgeIcon, BlurHashImage } from '../UI';
import useResize from '../../helpers/hooks/useResize';
import { Divider, Space } from 'antd';
import moment from 'moment';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import VehicleText from './VehicleText';
import { PRIMARY_DATE_FORMAT } from '../../lib/constants/utils';
import { useTranslation } from 'react-i18next';

const confirmStyle = {
  display: 'flex',
  gap: ' 8px',
  alignItems: 'center',
  border: '1px solid #24b35c',
  borderRadius: ' 8px',
  padding: '6px',
  marginTop: '1rem',
};

const VehicleInfo = ({ vehicle, isPreview = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const componentRef = useRef();
  const { width } = useResize(componentRef, isLoaded);

  const { t } = useTranslation();

  const changeLoadinghandler = (isLoaded) => {
    setIsLoaded(isLoaded);
  };

  const createdAt = moment(vehicle?.created_at).format(PRIMARY_DATE_FORMAT);

  return (
    <>
      {!isPreview && (
        <>
          <Divider orientation="left">{t('vehicle.basicInfo')}</Divider>
          {vehicle?.vin_image_id && (
            <div
              className="mb-2"
              style={{ flexBasis: '100%' }}
              ref={componentRef}
            >
              <BlurHashImage
                url={vehicle?.vin_image?.paths.original}
                blurHash={vehicle?.vin_image?.blurhash}
                blurhashClass={'vehicle_image'}
                imgHeight={70}
                imgWidth={width}
                loaded={changeLoadinghandler}
                isCustomHeight={true}
                imgClass={'vin_image'}
                alt="vin image"
              />
            </div>
          )}
        </>
      )}

      <h6>
        <BadgeIcon title={t('vehicle.vin')} />
        {vehicle?.vin || '/'}
      </h6>

      <p className="m-0 small" style={{ color: 'silver' }}>
        {createdAt}
      </p>
      <div className="my-3">
        <p className="m-0">
          {vehicle?.make} - {vehicle?.model}
        </p>
        {vehicle?.year && <p className="m-0 small">{vehicle?.year}</p>}
      </div>
      <div className="my-3">
        <VehicleText
          label={t('vehicle.bodyType')}
          text={vehicle?.body_type}
          icon={<BodyType style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.fuel')}
          text={vehicle?.fuel_type}
          icon={<FuelIcon style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.enginePower')}
          text={`${vehicle?.engine_power} KW`}
          icon={<EnginePower style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.engineCapacity')}
          text={`${vehicle?.engine_displacement} CCM`}
          icon={<EngineCapacity style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.mileage')}
          text={vehicle?.mileage}
          icon={<Mileage style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.firstRegistration')}
          text={vehicle?.date_first_registration || '-'}
          icon={
            <CalendarOutlined style={{ color: 'var(--color-ui-primary)' }} />
          }
        />
        <VehicleText
          label={t('vehicle.nationalCode')}
          text={vehicle?.national_code || '-'}
          icon={<BodyType style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.gearbox')}
          text={vehicle?.gearbox_type}
          icon={<Gearbox style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        <VehicleText
          label={t('vehicle.trim')}
          text={vehicle?.trim}
          icon={<Wheel style={{ fill: 'var(--color-ui-primary)' }} />}
        />
        {vehicle?.final_confirmation && (
          <div style={confirmStyle}>
            <CheckCircleOutlined
              style={{ fontSize: '1.5rem', color: 'var(--color-ui-ok)' }}
            />
            <p className="small m-0">{t('vehicle.noDamageConfirmation')}</p>
          </div>
        )}
      </div>
      <Divider orientation="left">{t('vehicle.moreInformation')}</Divider>
      <Space className="mb-3">
        <InfoCircleOutlined className="primary_clr flex_center" />
        <p className="small">{t('vehicle.scanDocumentInfo')}</p>
      </Space>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('global.form.name')}</p>
        <p>{vehicle?.name || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.lastName')}</p>
        <p>{vehicle?.last_name || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959', flex: 1 }}>{t('global.form.address')}</p>
        <p style={{ flex: 2, textAlign: 'right' }}>{vehicle?.address || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.vehicleType')}</p>
        <p>{vehicle?.class_vehicle_type || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.registered')}</p>
        <p>{vehicle?.authorized || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.totalMass')}</p>
        <p>{vehicle?.total_mass || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.totalWeight')}</p>
        <p>{vehicle?.total_weight || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>
          {t('vehicle.form.trailerWithBreaks')}
        </p>
        <p>{vehicle?.trailer_load_used || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>
          {t('vehicle.form.trailerWithoutBreaks')}
        </p>
        <p>{vehicle?.unbridled || '-'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.serviced')}</p>
        <p>{vehicle?.serviced ? 'Yes' : 'No'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.leasable')}</p>
        <p>{vehicle?.leasable ? 'Yes' : 'No'}</p>
      </div>
      <div className="flex_between mb-1">
        <p style={{ color: '#595959' }}>{t('vehicle.form.winterTyres')}</p>
        <p>{vehicle?.winter_tyres ? 'Yes' : 'No'}</p>
      </div>
      {vehicle?.description && (
        <div className="mb-1">
          <Divider orientation="left">
            {t('vehicle.form.detailedDescription')}
          </Divider>
          <p>{vehicle.description}</p>
        </div>
      )}
    </>
  );
};

export default VehicleInfo;
