import axios from '../api/axios';

export async function getSelf(token = null) {
  let headers = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token.access_token}`;
  }

  const response = await axios.get('/users/me', { headers });

  return response.data;
}

export async function getAllUsers(pagination, options) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  options['headers'] = headers;

  const response = await axios.get('/users', options);

  const data = {
    users: response?.data,
    count: response?.headers['pagination-count'] || 0,
  };

  return data;
}

export async function getDealershipUsers(dealershipId, pagination, options) {
  const headers = {
    'Pagination-Limit': pagination.limit,
    'Pagination-Offset': pagination.offset,
  };

  options['headers'] = headers;

  const response = await axios.get(
    `/dealerships/${dealershipId}/users`,
    options
  );

  const data = {
    users: response?.data,
    count: response?.headers['pagination-count'] || 0,
  };

  return data;
}

export async function createUser(body) {
  const response = await axios.post('/users', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function getUserById(userId, options = {}) {
  const response = await axios.get(`/users/${userId}`, options);

  return response.data;
}

export async function updateUser(userId, body) {
  const response = await axios.patch(`/users/${userId}`, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function updateUserAssignment(userId, dealershipId, body) {
  const response = await axios.patch(
    `/users/${userId}/dealerships/${dealershipId}`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function assignUserFeature(userId, dealershipId, body) {
  const response = await axios.post(
    `/users/${userId}/dealerships/${dealershipId}/feature`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function updateUserFeature(userId, dealershipId, body) {
  const response = await axios.patch(
    `/users/${userId}/dealerships/${dealershipId}/feature`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function deleteUserFeature(userId, dealershipId, body) {
  const response = await axios.delete(
    `/users/${userId}/dealerships/${dealershipId}/feature`,
    { data: body },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}

export async function unassignUserFromDealership(userId, dealershipId) {
  const response = await axios.delete(
    `users/${userId}/dealerships/${dealershipId}`
  );

  return response.data;
}

export async function uploadProfilePicture(userId, body) {
  const response = await axios.post(`/users/${userId}/profile-picture`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}
