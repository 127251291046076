import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CarOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider } from 'antd';
import { useSelector } from 'react-redux';

import classes from './RequestItem.module.css';
import { Card, Modal, BlurHashImage } from '../UI';
import moment from 'moment';
import { RequestStatus } from '../../lib/constants/requestStatus';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';
import DefaultBadge from '../UI/default-badge/DefaultBadge';
import { PRIMARY_DATE_FORMAT } from '../../lib/constants/utils';
import { useTranslation } from 'react-i18next';
import VehicleDetails from '../../pages/VehicleDetails';

const RequestItem = ({ request, onRequest }) => {
  const [toggleInfo, setToggleInfo] = useState(false);
  const [toggleAllInfo, setToggleAllInfo] = useState(false);

  const currentDealership = useSelector(selectCurrentDealership);

  const { t } = useTranslation();
  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  const createdAt = moment(request.created_at).format(PRIMARY_DATE_FORMAT);

  const asteriskValue = (value) => {
    const index = value.length > 3 ? 3 : 1;
    let str = value.slice(0, index) + '*'.repeat(value.slice(index).length);

    return str.length < 11 ? str : str.slice(0, 10);
  };

  const asteriskEmail = (value) => {
    return value
      ? value.replace(
          /\B.+@/g,
          (c) =>
            c
              .split('')
              .slice(0, -1)
              .map((v) => '*')
              .join('') + '@'
        )
      : value;
  };

  // ! AKO JE SELF REGISTERED I AKO JOS NIJE ODOBREN ZAHTEV,
  // ! KORISTI ATERISK

  const name =
    request.status === 'PENDING' && !currentDealership.full_details_preview
      ? asteriskValue(request.user.name)
      : request.user.name;
  const email =
    request.status === 'PENDING' && !currentDealership.full_details_preview
      ? asteriskEmail(request.user.email)
      : request.user.email;
  const country =
    request.status === 'PENDING' &&
    !currentDealership.full_details_preview &&
    request.user.country
      ? asteriskValue(request.user.country)
      : request.user.country || '-';
  const city =
    request.status === 'PENDING' &&
    !currentDealership.full_details_preview &&
    request.user.city
      ? asteriskValue(request.user.city)
      : request.user.city || '-';

  const { preview_image: previewImage } = request;

  const userDealership = request.user.dealerships.find(
    (dealership) => dealership.id === dealershipId
  );

  const canPreview = ![
    RequestStatus.ACCEPTED,
    RequestStatus.ADDITIONAL_REQ,
  ].includes(request.status);

  let content = (
    <div className={`${classes.request_data} flex_between`}>
      <div style={{ flexBasis: '100%' }} className={`p-2 ${classes.user_data}`}>
        <p>{name}</p>
        <p className="mb-1 small">{email}</p>
        {!!userDealership && (
          <span className={classes.badge}>
            {userDealership.user_dealership?.group?.name ||
              t('users.selfRegistered')}
          </span>
        )}
      </div>
      <div style={{ flexBasis: '100%' }} className={`p-2 ${classes.user_data}`}>
        <p>{country}</p>
        <p className="small">{city}</p>
      </div>
      <div style={{ flexBasis: '100%' }} className="p-2">
        <p>
          {request.vehicle.make} {request.vehicle.model}
        </p>

        <p className={`${classes.smoked_text} small`}>
          {request.vehicle?.year}
        </p>
        <p className={`${classes.smoked_text} small`}>{request.vehicle.vin}</p>
        {request.status === RequestStatus.ADDITIONAL_REQ && (
          <div>
            <DefaultBadge
              color={'#51bb7b'}
              text={t('vehicle.vehicleImages.additionalReq')}
            />
          </div>
        )}
        {!currentDealership.full_details_preview && (
          <p
            className={`${classes.more_info} small`}
            onClick={() => setToggleInfo(true)}
          >
            {t('global.moreInfo')}
          </p>
        )}
      </div>
      <div style={{ flexBasis: '100%' }} className={classes.image}>
        {previewImage ? (
          <BlurHashImage
            url={previewImage?.paths?.original}
            blurHash={previewImage?.blurhash}
            spinner={false}
            imgClass={'request_image bordered_image'}
            blurhashClass={'bordered_image'}
            imgWidth={110}
            imgHeight={62}
            preview={canPreview}
            alt="vehicle image"
          />
        ) : (
          <div className={classes.no_image}>
            <CarOutlined />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Card>
        <p className={classes.vehicle_created}>
          {t('global.created')} {createdAt}
        </p>
        <div className={`${classes.request_container} flex_between`}>
          {[RequestStatus.ACCEPTED, RequestStatus.ADDITIONAL_REQ].includes(
            request.status
          ) ? (
            <Link
              className="w-100"
              style={{ color: '#000' }}
              to={`/clients/${request.user.id}/vehicles/${request.id}`}
            >
              {content}
            </Link>
          ) : (
            <div className="d-flex flex-column w-100">
              {content}
              {request.status === RequestStatus.PENDING &&
                currentDealership.full_details_preview && (
                  <Button
                    style={{ alignSelf: 'center', marginBottom: '1rem' }}
                    onClick={() => setToggleAllInfo(true)}
                    type="primary"
                  >
                    {t('requests.seeMoreDetails')}
                  </Button>
                )}
            </div>
          )}
          {request.status === RequestStatus.PENDING && (
            <div className={`${classes.status}`}>
              <CheckOutlined
                className={`${classes.icon} ${classes.green} me-2`}
                onClick={() => onRequest(request, 'ACCEPT')}
              />
              <CloseOutlined
                className={`${classes.icon} ${classes.red}`}
                onClick={() => onRequest(request, 'REJECT')}
              />
            </div>
          )}
          {[RequestStatus.ACCEPTED, RequestStatus.ADDITIONAL_REQ].includes(
            request.status
          ) && (
            <div style={{ display: 'flex', alignSelf: 'stretch' }}>
              <Checkbox
                value={request}
                style={{ position: 'absolute', right: '10px' }}
              />
              <div className={`${classes.status} d-flex flex-column`}>
                <p className=" small">{t('requests.accepted')}!</p>
                <CheckOutlined className={`${classes.green}`} />
              </div>
            </div>
          )}
          {request.status === RequestStatus.REJECTED && (
            <div style={{ display: 'flex', alignSelf: 'stretch' }}>
              <Checkbox
                value={request}
                style={{ position: 'absolute', right: '10px' }}
              />
              <div className={`${classes.status} d-flex flex-column`}>
                <p className=" small">{t('requests.rejected')}!</p>
                <CloseOutlined className={`${classes.red}`} />
              </div>
            </div>
          )}
        </div>
      </Card>

      {toggleInfo && (
        <Modal
          title={t('requests.requestInformation')}
          onClose={() => setToggleInfo(false)}
        >
          <p className="mb-3">
            {t('global.created')} {createdAt}
          </p>
          <h5>{t('global.userInformation')}</h5>
          <div className="p-2">
            <p>{name}</p>
            <p className="mb-1 small">{email}</p>
            {!!userDealership && (
              <span className={classes.badge}>
                {userDealership.user_dealership?.group?.name ||
                  t('users.selfRegistered')}
              </span>
            )}
          </div>
          <div className="p-2">
            <p>{country || '/'}</p>
            <p className="small">{city || '/'}</p>
          </div>
          <Divider />
          <h5>{t('global.vehicleInformation')}</h5>
          <div className="p-2">
            <p>
              {request.vehicle.make} {request.vehicle.model}
            </p>
            <p className={`${classes.smoked_text} small`}>
              {request.vehicle?.year}
            </p>
            <p className={`${classes.smoked_text} small`}>
              {request.vehicle.vin}
            </p>
          </div>
        </Modal>
      )}

      {toggleAllInfo && (
        <Modal
          title={t('requests.requestInformation')}
          onClose={() => setToggleAllInfo(false)}
        >
          <div style={{ overflow: 'auto', height: ' 400px' }}>
            <VehicleDetails
              selectedUserId={request.user.id}
              selectedVehicleId={request.id}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default RequestItem;
