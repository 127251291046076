import { errorActions } from '../slices/error-slice';
import i18n from 'i18next';

const errorHandler = (err) => {
  return (dispatch) => {
    if (!err.response) {
      return;
    }

    if (err.response.status) {
      let newError = {
        code: err.response.status,
        errorCode: null,
        message: i18n.t('errors.defaultError'),
      };

      switch (err.response.status) {
        case 400:
          newError.message = i18n.t('errors.badRequest');
          break;
        case 401:
          if (err.response.data.code === 4010000) {
            newError.message = i18n.t('errors.notAuthorized');
          } else if (err.response.data.code === 4010001) {
            newError.message = i18n.t('errors.wrongCredentials');
          }
          break;
        case 403:
          if (err.response.data.code === 4030000) {
            newError.message = i18n.t('errors.notAccess');
          } else if (err.response.data.code === 4030004) {
            newError.message = i18n.t('errors.notAssignedUser');
          } else if (err.response.data.code === 4030005) {
            newError.message = i18n.t('errors.notAdmin');
          } else if (err.response.data.code === 4030009) {
            newError.message = i18n.t('errors.expiredLink');
          } else if (err.response.data.code === 4030010) {
            newError.message = i18n.t('errors.noCredits');
          } else if (err.response.data.code === 4030012) {
            newError.message = i18n.t('errors.subscriptionExpired');
          } else if (err.response.data.code === 4030013) {
            const feature =
              err.response.data.variables['Feature'].toLowerCase();
            newError.message = i18n.t('errors.noFeature', { feature });
          }
          break;
        case 404:
          if (err.response.data.code === 4040000) {
            newError.message = i18n.t('errors.notFound');
          } else if (err.response.data.code === 4040001) {
            newError.message = i18n.t('errors.dealershipNotFound');
          } else if (err.response.data.code === 4040002) {
            newError.message = i18n.t('errors.incorrectCode');
          } else if (err.response.data.code === 4040003) {
            newError.message = i18n.t('errors.userNotFound');
          } else if (err.response.data.code === 4040007) {
            newError.message = i18n.t('errors.requestNotFound');
          } else if (err.response.data.code === 4040008) {
            newError.message = i18n.t('errors.userNotAssigned');
          }
          break;
        case 409:
          if (err.response.data.code === 4090001) {
            newError.message = i18n.t('errors.userExists', {
              email: err.response.data.variables['Email'],
            });
          } else if (err.response.data.code === 4090003) {
            newError.message = i18n.t('errors.userAssigned');
          } else if (err.response.data.code === 4090004) {
            newError.message =
              'Target with given position already exists for some of the given body types';
          } else if (err.response.data.code === 4090006) {
            newError.message = i18n.t('errors.invoiceNotFound');
          } else if (err.response.data.code === 4090011) {
            newError.message = 'Price type mismatch with valid until time';
          }
          break;
        default:
          break;
      }

      dispatch(errorActions.setError(newError));
    }
  };
};

export default errorHandler;
