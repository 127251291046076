import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Drawer,
  Form,
  Col,
  Row,
  Input,
  Button,
  Space,
  Select,
  Divider,
  Upload,
  InputNumber,
  Image,
  Switch,
} from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { bodyType } from '../../lib/constants/bodyType';
import { Status } from '../../lib/constants/status';
import {
  addTarget,
  editTarget,
  uploadTargetFile,
} from '../../store/actions/target-actions';
import Overlay from '../UI/overlay/Overlay';

const { Option } = Select;
const { Dragger } = Upload;

const TargetForm = ({
  isVisible,
  isSM,
  onClose,
  selectedTarget,
  isLoading,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [addTargetStatus, setAddTargetStatus] = useState(Status.IDLE);
  const [target, setTarget] = useState(selectedTarget);
  const [fileList, setFileList] = useState([]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    !selectedTarget ? onFinishCreate(values) : onFinishEdit(values);
  };

  const onFinishCreate = (values) => {
    let newTarget = {};

    let tutorial_message = {};

    for (const key in values) {
      let value = values[key];

      if (languages.includes(key) && value) {
        tutorial_message[key] = value || undefined;
      }

      if (!languages.includes(key)) {
        newTarget[key] = value || undefined;
      }
    }

    if (
      Object.keys(tutorial_message).length !== 0 &&
      tutorial_message.constructor === Object
    ) {
      newTarget['tutorial_message'] = tutorial_message;
    }

    createTargetHandler(newTarget, selectedFile);
  };

  const uploadFile = async (file, target) => {
    let formData = new FormData();
    formData.append('target_image', file);

    const data = await dispatch(
      uploadTargetFile({ targetId: target.id, body: formData })
    ).unwrap();

    return data;
  };

  const createTargetHandler = async (data, file) => {
    setAddTargetStatus(Status.LOADING);

    try {
      const target = await dispatch(addTarget(data)).unwrap();

      if (file) {
        await uploadFile(file, target);
      }

      setAddTargetStatus(Status.COMPLETED);
      onClose();
    } catch (err) {
      setAddTargetStatus(Status.FAILED);
    }
  };

  const onFinishEdit = (values) => {
    let target = {};

    let tutorial_message = {};

    Object.keys(selectedTarget).forEach((key) => {
      if (key in values) {
        if (selectedTarget[key] !== values[key]) {
          switch (key) {
            case 'required':
              target[key] = values[key];
              break;
            default:
              target[key] = values[key] || null;
              break;
          }
        }
      }
    });

    for (const key in values) {
      let value = values[key];

      if (
        languages.includes(key) &&
        selectedTarget.tutorial_message &&
        selectedTarget.tutorial_message[key] !== value
      ) {
        tutorial_message[key] = value || null;
      }
    }

    if (
      Object.keys(tutorial_message).length !== 0 &&
      tutorial_message.constructor === Object
    ) {
      target['tutorial_message'] = tutorial_message;
    }

    if (Object.keys(target).length === 0 && target.constructor === Object) {
      return;
    }

    updateTargetHandler(target);
  };

  const updateTargetHandler = async (body) => {
    setAddTargetStatus(Status.LOADING);

    try {
      await dispatch(
        editTarget({ targetId: selectedTarget.id, body })
      ).unwrap();

      setAddTargetStatus(Status.COMPLETED);
      onClose();
    } catch (err) {
      setAddTargetStatus(Status.FAILED);
    }
  };

  const onSubmitHandler = () => {
    form.submit();
  };

  const initialValues = () => {
    let values = {};

    if (selectedTarget) {
      for (const key in selectedTarget) {
        values[key] = selectedTarget[key];
      }

      values['body_types'] = selectedTarget.body_types || [];
      values['en'] = selectedTarget.tutorial_message['en'];
      values['de'] = selectedTarget.tutorial_message['de'];
    }

    return values;
  };

  const languages = ['en', 'de'];

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const props = {
    name: 'file',
    customRequest(e) {
      selectedTarget ? uploadFileHandler(e.file) : setSelectedFile(e.file);
    },
    beforeUpload: async (file) => {
      if (!selectedTarget) {
        const url = await getBase64(file);
        const thumbUrl = await getBase64(file);

        const newFile = { ...file, url, thumbUrl };
        setFileList([newFile]);
      }
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();

      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const uploadFileHandler = async (file) => {
    setAddTargetStatus(Status.LOADING);

    try {
      const data = await uploadFile(file, selectedTarget);

      setTarget(data);
      setAddTargetStatus(Status.COMPLETED);
    } catch (err) {
      setAddTargetStatus(Status.FAILED);
    }
  };

  return (
    <Drawer
      title={selectedTarget ? 'Edit Target' : 'Create a new Target'}
      width={isSM ? '100%' : 545}
      onClose={onClose}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 50 }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            loading={addTargetStatus === Status.LOADING}
            onClick={onSubmitHandler}
            htmlType="submit"
          >
            Submit
          </Button>
        </Space>
      }
    >
      <Form
        onFinish={onFinish}
        form={form}
        {...formItemLayout}
        initialValues={initialValues()}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Name is required.' }]}
            >
              <Input placeholder="Enter Target name..." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="position"
              label="Position"
              rules={[{ required: true, message: 'Position is required.' }]}
            >
              <InputNumber className="w-100" placeholder="Enter Position..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="type"
              label="Target Type"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select a target type" allowClear>
                <Option value="EXTERIOR">Exterior</Option>
                <Option value="INTERIOR">Interior</Option>
                <Option value="GLASS">Glass</Option>
                <Option value="WHEELS">Wheels</Option>
                <Option value="DETAILS">Details</Option>
                <Option value="CLOSE_INSPECTION">Close Inspection</Option>
                <Option value="DETAILS_LICENCE">Documents</Option>
                <Option value="DAMAGES">Damages</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="body_types"
              label="Body Types"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select Body Types"
                virtual={false}
              >
                {bodyType.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              name="required"
              label="Required target"
              labelCol={{ span: 6 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Tutorial Messages</Divider>
        <Row gutter={16}>
          <Col xs={24}>
            {languages.map((lang, index) => (
              <Form.Item
                key={index}
                name={lang}
                label={lang.toUpperCase()}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                rules={[{ required: true }]}
              >
                <Input placeholder={`Enter ${lang} language...`} />
              </Form.Item>
            ))}
          </Col>
        </Row>
      </Form>
      <Divider orientation="left">Tutorial Image</Divider>
      <Row className="mt-3">
        <Col span={24}>
          {(!target || (target && !target.target_image)) && (
            <div style={{ minHeight: '90px' }}>
              {addTargetStatus === Status.LOADING && selectedFile && (
                <p style={{ color: '#d72121' }} className="small">
                  Please wait while the image is uploading...
                </p>
              )}
              <Dragger
                {...props}
                listType="picture"
                fileList={fileList}
                maxCount={1}
                disabled={addTargetStatus === Status.LOADING}
              >
                <p className="ant-upload-drag-icon mb-2">
                  <InboxOutlined style={{ fontSize: '32px' }} />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to upload Tutorial File
                </p>
              </Dragger>
            </div>
          )}
          {target && target.target_image && (
            <div className="flex_between">
              {/* // ! dodati da preview bude originalna ?? */}
              <Image width={100} src={target.target_image.paths['640x480']} />
              <Upload {...props} showUploadList={false}>
                <Button
                  loading={addTargetStatus === Status.LOADING}
                  disabled={addTargetStatus === Status.LOADING}
                  icon={<UploadOutlined />}
                >
                  Click to Upload
                </Button>
              </Upload>
            </div>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default TargetForm;
