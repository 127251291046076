import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { toastrSuccess } from '../../helpers/toastr';
import { Status } from '../../lib/constants/status';
import {
  addTarget,
  editTarget,
  fetchTargets,
  removeTarget,
  uploadTargetFile,
} from '../actions/target-actions';

const initialState = {
  targets: [],
  status: Status.IDLE, // idle | pending | completed | failed
};

const targetSlice = createSlice({
  name: 'targets',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTargets.pending, (state, action) => {
        state.status = Status.LOADING;
      })
      .addCase(fetchTargets.fulfilled, (state, action) => {
        const data = action.payload;

        state.targets = data;
        state.status = Status.COMPLETED;
      })
      .addCase(fetchTargets.rejected, (state, action) => {
        state.status = Status.FAILED;
      })
      .addCase(addTarget.fulfilled, (state, action) => {
        const newTarget = action.payload;

        state.targets.unshift(newTarget);

        state.targets = state.targets.sort((a, b) => {
          return a.position - b.position;
        });

        toastrSuccess(`Successfully created a new target`);
      })
      .addCase(removeTarget.fulfilled, (state, action) => {
        const targetId = action.payload;

        state.targets = state.targets.filter(
          (target) => target.id !== targetId
        );

        toastrSuccess('Successfully deleted.');
      })
      .addMatcher(
        isAnyOf(editTarget.fulfilled, uploadTargetFile.fulfilled),
        (state, action) => {
          const editTarget = action.payload;

          const index = state.targets.findIndex(
            (target) => target.id === editTarget.id
          );

          if (~index) {
            state.targets[index] = editTarget;
          }

          toastrSuccess(`Success`);
        }
      );
  },
});

export const selectAllTargets = (state) => state.targets.targets;
export const getTargetsStatus = (state) => state.targets.status;

export const targetActions = targetSlice.actions;

export default targetSlice;
