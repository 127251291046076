import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { createDevice } from './lib/notifications';

// const firebaseConfig = {
//   apiKey: 'AIzaSyD-1jSKO_JOxYvC6aS0beE089PSzz_RXEk',
//   authDomain: 'notification-test-1a764.firebaseapp.com',
//   projectId: 'notification-test-1a764',
//   storageBucket: 'notification-test-1a764.appspot.com',
//   messagingSenderId: '718779927016',
//   appId: '1:718779927016:web:c6c320c85648fd5d867c5d',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyBqhP0kWIrTEwDogXaFXiVvIpleGIQ5tFI',
  authDomain: 'myautohouse-ca877.firebaseapp.com',
  projectId: 'myautohouse-ca877',
  storageBucket: 'myautohouse-ca877.appspot.com',
  messagingSenderId: '919876712445',
  appId: '1:919876712445:web:50f69cf498453a3a0ccb94',
};

const isWindowControllerSupported = () => {
  return (
    navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window
  );
};

const app = isWindowControllerSupported() && initializeApp(firebaseConfig);
const messaging = isWindowControllerSupported() && getMessaging(app);

export const requestPermission = () => {
  console.log('Requesting User Permission......');

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification User Permission Granted.');
      return getToken(messaging)
        .then((currentToken) => {
          if (currentToken) {
            console.log('Client Token: ', currentToken);
            // send to backend
            createDevice({ device_id: currentToken });
            // alert(currentToken)
            window.BrrmChat('subscribe-device', {
              fcmToken: currentToken,
              deviceUniqueId: window.navigator.userAgent,
            });
          } else {
            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {
          console.log(
            'An error occurred when requesting to receive the token.',
            err
          );
        });
    } else {
      console.log('User Permission Denied.');
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

