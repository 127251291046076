import React from 'react';

const VehicleText = ({ label, text, icon }) => {
  return (
    <div className="flex_between mb-2">
      <p style={{ color: '#595959' }}>
        <span
          style={{
            width: '22px',
            display: 'inline-block',
            textAlign: 'center',
          }}
          className="me-2"
        >
          {icon}
        </span>
        {label}
      </p>
      <p>{text}</p>
    </div>
  );
};

export default VehicleText;
