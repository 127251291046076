import React, { useState } from 'react';

import {
  Drawer,
  Form,
  Col,
  Row,
  Input,
  Button,
  Space,
  Select,
  Divider,
  InputNumber,
  DatePicker,
} from 'antd';
import { bodyType } from '../../lib/constants/bodyType';
import { fuelType } from '../../lib/constants/fuelType';
import { useTranslation } from 'react-i18next';
import { searchUserRequests } from '../../lib/request';
import { Modal } from '../UI';
import VehiclesList from './VehiclesList';
import DuplicateVehiclesList from './DuplicateVehicles/DuplicateVehiclesList';

const { Option } = Select;

const VehicleForm = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [formData, setFormData] = useState(null);
  const [duplicateVehicles, setDuplicateVehicles] = useState();
  const [isSearching, setIsSearcing] = useState(false);
  const [showMultipleSubmission, setShowMultipleSubmission] = useState(false);

  const isLoading = props.isAdding || isSearching;

  const onFinish = (values) => {
    checkForSameVehicles(values);
  };

  const checkForSameVehicles = async (values) => {
    setIsSearcing(true);

    const data = await searchUserRequests(props.dealershipId, props.userId, {
      vin: values.vin,
    });

    setIsSearcing(false);

    if (!data.length) {
      onFinishCreate(values);
    } else {
      setFormData(values);
      setDuplicateVehicles(data);
      setShowMultipleSubmission(true);
    }
  };

  const onFinishCreate = (values) => {
    let newVehicle = {};

    const source = values || formData;

    for (const key in source) {
      let value = source[key];

      newVehicle[key] = value || undefined;
    }

    const { request_type } = newVehicle;

    delete newVehicle['request_type'];

    props.onCreateVehicle(newVehicle, request_type);
  };

  const onSubmitHandler = () => {
    form.submit();
  };

  const { canScanDocument, requests } = props;

  const dateFormatList = ['DD.MM.YYYY', 'DD/MM/YYYY'];

  return (
    <>
      <Drawer
        title={t('vehicle.form.title')}
        width={props.isSM ? '100%' : 545}
        onClose={props.onClose}
        visible={props.isVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button disabled={isLoading} onClick={props.onClose}>
              {t('global.form.cancel')}
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              // disabled={disabledSave}
              onClick={onSubmitHandler}
              htmlType="submit"
            >
              {t('global.form.submit')}
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          // onValuesChange={onValuesChange}
        >
          <Row gutter={16}>
            <Col xs={24} sm={{ span: 12, offset: 6 }}>
              <Form.Item
                name="vin"
                label={t('vehicle.vin')}
                rules={[
                  { required: true, message: t('vehicle.form.requiredVin') },
                ]}
              >
                <Input placeholder={t('vehicle.form.enterVIN')} />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: '6px 0' }} />
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="make"
                label={t('vehicle.form.make')}
                rules={[
                  { required: true, message: t('vehicle.form.requiredMake') },
                ]}
              >
                <Input placeholder={t('vehicle.form.enterMake')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="model"
                label={t('vehicle.form.model')}
                rules={[
                  { required: true, message: t('vehicle.form.requiredModel') },
                ]}
              >
                <Input placeholder={t('vehicle.form.enterModel')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="body_type"
                label={t('vehicle.bodyType')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredBodyType'),
                  },
                ]}
              >
                <Select
                  placeholder={t('vehicle.form.selectBodyType')}
                  allowClear
                >
                  {bodyType.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="fuel_type"
                label={t('vehicle.form.fuelType')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredFuelType'),
                  },
                ]}
              >
                <Select
                  placeholder={t('vehicle.form.selectFuelType')}
                  allowClear
                >
                  {fuelType.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!canScanDocument && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="year"
                  label={t('vehicle.form.year')}
                  rules={[
                    { required: true, message: t('vehicle.form.requiredYear') },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    placeholder={t('vehicle.form.enterYear')}
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="mileage"
                label={t('vehicle.mileage')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredMileage'),
                  },
                ]}
              >
                <InputNumber
                  addonAfter="Km"
                  className="w-100"
                  placeholder={t('vehicle.form.enterMileage')}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="engine_displacement"
                label={t('vehicle.engineCapacity')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredCapacity'),
                  },
                ]}
              >
                <InputNumber
                  addonAfter="CCM"
                  className="w-100"
                  placeholder={t('vehicle.form.enterCapacity')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="engine_power"
                label={t('vehicle.enginePower')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredEnginePower'),
                  },
                ]}
              >
                <InputNumber
                  addonAfter="kW"
                  className="w-100"
                  placeholder={t('vehicle.form.enterEnginePower')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="trim" label={t('vehicle.trim')}>
                <Input placeholder={t('vehicle.form.enterTrim')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="gearbox_type" label={t('vehicle.gearbox')}>
                <Select
                  placeholder={t('vehicle.form.selectGearboxType')}
                  allowClear
                >
                  <Option value="MANUAL">{t('vehicle.form.manual')}</Option>
                  <Option value="AUTOMATIC">
                    {t('vehicle.form.automatic')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="national_code"
                label={t('vehicle.nationalCode')}
                rules={[
                  {
                    required: canScanDocument,
                    message: t('vehicle.form.requiredNationalCode'),
                  },
                ]}
              >
                <Input placeholder={t('vehicle.form.enterNationalCode')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="date_first_registration"
                label={t('vehicle.firstRegistration')}
                rules={[
                  {
                    required: canScanDocument,
                    message: t('vehicle.form.requiredFirstRegistration'),
                  },
                ]}
              >
                <DatePicker format={dateFormatList} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="request_type"
                label={t('vehicle.form.requestType')}
                rules={[
                  {
                    required: true,
                    message: t('vehicle.form.requiredRequest'),
                  },
                ]}
                tooltip={t('vehicle.form.requiredRequestInfo')}
              >
                <Select
                  placeholder={t('vehicle.form.selectRequestType')}
                  allowClear
                >
                  {requests.map((reqeust, index) => (
                    <React.Fragment key={index}>
                      {reqeust === 'EXCHANGE' && (
                        <Option value="VEHICLE_EXCHANGE">
                          {t('features.vehicleExchange')}
                        </Option>
                      )}
                    </React.Fragment>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {canScanDocument && (
            <>
              <Divider orientation="left">
                {t('vehicle.moreInformation')}
              </Divider>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item name="name" label={t('vehicle.form.firstName')}>
                    <Input placeholder={t('vehicle.form.enterFirstName')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="last_name"
                    label={t('vehicle.form.lastName')}
                  >
                    <Input placeholder={t('vehicle.form.enterLastName')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item name="address" label={t('global.form.address')}>
                    <Input placeholder={t('vehicle.form.enterAddress')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="class_vehicle_type"
                    label={t('vehicle.form.vehicleType')}
                  >
                    <Input placeholder={t('vehicle.form.enterVehicleType')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="total_mass"
                    label={t('vehicle.form.totalMass')}
                  >
                    <Input
                      addonAfter="kg"
                      className="w-100"
                      placeholder={t('vehicle.form.enterTotalMass')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="total_weight"
                    label={t('vehicle.form.totalWeight')}
                  >
                    <Input
                      addonAfter="kg"
                      className="w-100"
                      placeholder={t('vehicle.form.enterTotalWeight')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="trailer_load_used"
                    label={t('vehicle.form.trailerWithBreaks')}
                  >
                    <Input
                      addonAfter="kg"
                      className="w-100"
                      placeholder={t('vehicle.form.enterTrailerWithBreaks')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="weight" label="Weight">
                    <Input
                      addonAfter="kg"
                      className="w-100"
                      placeholder={t('vehicle.form.enterWeight')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="unbridled"
                    label={t('vehicle.form.trailerWithoutBreaks')}
                  >
                    <Input
                      addonAfter="kg"
                      className="w-100"
                      placeholder={t('vehicle.form.enterTrailerWitouthBreaks')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="authorized"
                    label={t('vehicle.form.registered')}
                  >
                    <DatePicker format={dateFormatList} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Drawer>

      {showMultipleSubmission && (
        <Modal
          title={t('vehicle.form.multipleSubmission')}
          onClose={() => setShowMultipleSubmission(false)}
        >
          <p style={{ fontSize: '1rem' }}>
            {t('vehicle.form.multipleSubmissionInfo', {
              count: duplicateVehicles.length,
            })}
          </p>
          <div style={{ overflow: 'auto', maxHeight: ' 300px' }}>
            <DuplicateVehiclesList requests={duplicateVehicles} />
          </div>
          <Space className="pt-3 w-100 justify-content-end">
            <Button onClick={() => setShowMultipleSubmission(false)}>
              {t('global.form.cancel')}
            </Button>
            <Button type="primary" onClick={() => onFinishCreate()}>
              {t('global.form.submit')}
            </Button>
          </Space>
        </Modal>
      )}
    </>
  );
};

export default VehicleForm;
