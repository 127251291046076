import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { Image as ImageTag, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classes from './Image.module.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const BlurHashImage = ({
  url,
  blurHash,
  spinner = true,
  imgClass = '',
  blurhashClass = '',
  preview = true,
  imgWidth,
  imgHeight,
  previewUrl = null,
  loaded,
  isCustomHeight = false,
  alt,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });

  useEffect(() => {
    loadImage(url).then((image) => {
      setIsLoaded(true);
      isCustomHeight && loaded(true);
    });
  }, [url, loaded, isCustomHeight]);

  const isVisible = preview
    ? {
        src: previewUrl || url,
      }
    : false;

  return (
    <>
      {!isLoaded && (
        <div
          style={{ position: 'relative', minWidth: '100%', height: imgHeight }}
        >
          {spinner && (
            <div className={classes.spinner_container}>
              <Spin indicator={antIcon} />
            </div>
          )}

          <Blurhash
            hash={blurHash}
            height={imgHeight}
            width={imgWidth}
            className={blurhashClass}
          />
        </div>
      )}
      {/* {isLoaded && ( */}
      <ImageTag
        src={url}
        style={{
          animation: 'show 450ms ease-in forwards',
          display: isLoaded ? 'block' : 'none',
        }}
        className={imgClass}
        preview={isVisible}
        alt={alt}
      />
      {/* )} */}
    </>
  );
};

export default BlurHashImage;
