import React from 'react';
import Modal from '../UI/modal/Modal';
import { Form, Input, Button, Switch, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const GroupForm = ({
  onClose,
  onCreateGroup,
  onUpdateGroup,
  selectedGroup,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const initialValues = () => {
    let values = {};

    if (selectedGroup) {
      values['name'] = selectedGroup.name;
      values['description'] = selectedGroup.description;
      values['pinned'] = selectedGroup.pinned;
    }

    return values;
  };

  const onFinish = (values) => {
    !selectedGroup ? onFinishCreate(values) : onFinishEdit(values);
  };

  const onFinishCreate = (values) => {
    let group = {};

    for (const key in values) {
      let value = values[key];

      group[key] = value || undefined;
    }

    onCreateGroup(group);
  };

  const onFinishEdit = (values) => {
    let group = {};

    Object.keys(selectedGroup).forEach((key) => {
      if (key in values) {
        if (selectedGroup[key] !== values[key]) {
          group[key] = values[key] || null;
          if (key === 'pinned') {
            group[key] = values[key] || false;
          }
        }
      }
    });

    if (Object.keys(group).length === 0 && group.constructor === Object) {
      return;
    }

    onUpdateGroup(group);
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={selectedGroup ? t('groups.editGroup') : t('users.user.form.newGroup')}
      onClose={onClose}
    >
      <div className="p-1">
        <Form
          onFinish={onFinish}
          form={form}
          {...formItemLayout}
          initialValues={initialValues()}
        >
          <Form.Item
            name="name"
            label={t('global.form.name')}
            rules={[
              {
                required: true,
                message: t('global.formValidationError.requiredName'),
              },
            ]}
          >
            <Input placeholder={t('groups.enterGroupName')} />
          </Form.Item>

          <Form.Item name="description" label={t('groups.description')}>
            <Input placeholder={t('groups.enterDescription')} />
          </Form.Item>

          <Form.Item
            name="pinned"
            label={t('groups.showInSidebar')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Space>
            <Button type="primary" loading={isLoading} htmlType="submit">
              {t('global.form.submit')}
            </Button>
            <Button type="default" onClick={onClose}>
              {t('global.form.cancel')}
            </Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default GroupForm;
