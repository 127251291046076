import React, { useState, useEffect } from 'react';
import {
  useLocation,
  NavLink,
  Outlet,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/actions/auth-actions';
import { useMediaQuery } from 'react-responsive';
import Modal from '../UI/modal/Modal';

import { Layout, Menu, Avatar, Badge, Dropdown, Input, Image } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BellOutlined,
  LogoutOutlined,
  CarOutlined,
  TeamOutlined,
  HomeOutlined,
  SearchOutlined,
  AimOutlined,
  ControlOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import classes from './Navigation.module.css';
import logo from '../../assets/img/logo.svg';
import user2 from '../../assets/img/user2.jpg';
import { Roles } from '../../lib/constants/roles';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import { selectPinnedGroups } from '../../store/slices/user-group-slice';
import { selectCurrentDealership } from '../../store/slices/dealership-slice';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../UI/language/LanguageSelector';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;

const Navigation = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { groupId } = useParams();
  const x = useMediaQuery({ query: `(max-width: 992px)` });
  const xSmall = useMediaQuery({ query: `(max-width: 575px)` });
  const tablets = useMediaQuery({ query: `(max-width: 768px)` });
  const loggedInUser = useSelector(selectLoggedInUser);

  const currentDealership = useSelector(selectCurrentDealership);
  const groups = useSelector((state) => state.groups.groups).filter(
    (group) => group.pinned
  );
  // const groups = useSelector(state => selectPinnedGroups(state));

  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(x);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(x);
  const [current, setCurrent] = useState(location.pathname);
  const [currentGroup, setCurrentGroup] = useState(groupId || null);
  const [searchValue, setSearchValue] = useState('');

  const isCollapsedMobile = collapsed && isMobile;
  const isCollapsedDesktop = collapsed && !isMobile;

  const toggle = () => {
    setCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (location) {
      let url = `/${location.pathname.split('/')[1]}`;
      if (current !== url) {
        setCurrent(url);
      }
      setCurrentGroup(groupId || null);
    }
  }, [location, current, groupId]);

  useEffect(() => {
    if (!xSmall) setToggleSearch(false);
  }, [xSmall]);

  const changeUrlHandler = (e) => {
    setCurrent(e.key);
  };

  const onBreakpointHandler = (broken) => {
    if (broken) {
      setCollapsed(true);
      setIsMobile(true);
    } else {
      setCollapsed(false);
      setIsMobile(false);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const openSearchHandler = () => {
    setToggleSearch(true);
  };

  const menu = (
    <Menu style={{ minWidth: '135px' }}>
      {xSmall && (
        <>
          <li style={{ padding: '5px 12px' }}>
            <span className={`${classes.avatar_name}`}>
              {t('global.hiUser', {
                name: loggedInUser.name.split(' ')[0],
              })}
            </span>
          </li>
          <Menu.Divider />
        </>
      )}
      {loggedInUser.type !== Roles.ROOT && (
        <Menu.Item key="0" icon={<UserOutlined />}>
          <NavLink to="/profile">{t('users.user.profile')}</NavLink>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item
        onClick={() => {
          dispatch(logoutUser());
          navigate('/login', { replace: true });
        }}
        key="3"
        icon={<LogoutOutlined />}
      >
        {t('global.logout')}
      </Menu.Item>
    </Menu>
  );

  const customLogo =
    currentDealership && currentDealership.logo
      ? currentDealership.logo.paths.original
      : false;

  return (
    <Layout>
      <Sider
        trigger={null}
        theme="light"
        collapsible
        collapsedWidth={isMobile ? 0 : 80}
        collapsed={collapsed}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          onBreakpointHandler(broken);
        }}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 999,
        }}
      >
        <div className="logo flex_center">
          <img
            src={customLogo || logo}
            className={'img-fluid'}
            style={{ height: isCollapsedDesktop ? 'auto' : '100%' }}
            alt="logo"
          />
        </div>
        <Menu
          theme="light"
          mode="inline"
          onClick={changeUrlHandler}
          selectedKeys={[current, currentGroup]}
          defaultOpenKeys={[]}
        >
          {loggedInUser.type === Roles.ROOT && (
            <>
              <Menu.Item key="/dealerships" icon={<HomeOutlined />}>
                <NavLink to="/dealerships">Dealerships</NavLink>
              </Menu.Item>
              <Menu.Item key="/users" icon={<TeamOutlined />}>
                <NavLink to="/users">Users</NavLink>
              </Menu.Item>
              <Menu.Item key="/targets" icon={<AimOutlined />}>
                <NavLink to="/targets">Targets</NavLink>
              </Menu.Item>
              <Menu.Item key="/features" icon={<ControlOutlined />}>
                <NavLink to="/features">Features</NavLink>
              </Menu.Item>
            </>
          )}
          {loggedInUser.type === 'DEALERSHIP' && (
            <>
              <Menu.Item key="/clients" icon={<TeamOutlined />}>
                <NavLink to="/clients">{t('global.clients')}</NavLink>
              </Menu.Item>
              {!!groups.length && (
                <SubMenu key="/groups" icon={<TeamOutlined />} title="Groups">
                  {groups.map((group) => (
                    <Menu.Item key={`${group.id}`} icon={<TeamOutlined />}>
                      <NavLink to={`groups/${group.id}/users`}>
                        {group.name}
                      </NavLink>
                    </Menu.Item>
                  ))}
                </SubMenu>
              )}
              <Menu.Item key="/requests" icon={<CarOutlined />}>
                <NavLink to="/requests">{t('global.requests')}</NavLink>
              </Menu.Item>
              <Menu.Item key="/invoices" icon={<FileTextOutlined />}>
                <NavLink to="/invoices">{t('global.invoices')}</NavLink>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={
          isCollapsedMobile || (!collapsed && isMobile)
            ? { marginLeft: 0 }
            : isCollapsedDesktop
            ? { marginLeft: 80 }
            : { marginLeft: 200 }
        }
      >
        <Header
          className="site-layout-background"
          style={{
            position: 'fixed',
            zIndex: 10,
            width: '100%',
            height: '52px',
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggle,
            }
          )}
          <div
            className={`flex_center ${classes['badge']} ${
              isCollapsedDesktop
                ? classes.collapsed_desktop
                : isCollapsedMobile || (!collapsed && isMobile)
                ? classes.collapsed_mobile
                : ''
            }`}
          >
            <LanguageSelector />
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            >
              <div className={classes.user_avatar_container}>
                {loggedInUser.profile_picture_id ? (
                  <Image
                    src={loggedInUser.profile_picture?.paths['320x240']}
                    preview={false}
                    style={{
                      overflow: 'hidden',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                    }}
                  />
                ) : (
                  <Avatar
                    className={classes.background_primary}
                    icon={<UserOutlined />}
                  />
                )}
                <span className={`ms-2 ${classes.avatar_name}`}>
                  {t('global.hiUser', {
                    name: loggedInUser.name.split(' ')[0],
                  })}
                </span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: '0',
            marginTop: 52,
            backgroundColor: '#F8F9FE',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      {isMobile && !collapsed && (
        <div
          className={classes.backdrop}
          onClick={() => setCollapsed(true)}
        ></div>
      )}
    </Layout>
  );
};

export default Navigation;
