import {
  createAdditionalRequest,
  deleteRequests,
  deleteUserRequests,
  getDealershipRequests,
  getUserRequest,
  updateUserRequest,
} from '../../lib/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRequests = createAsyncThunk(
  'requests/fetchRequests',
  async ({ dealershipId, pagination, options = {} }) => {
    const data = await getDealershipRequests(dealershipId, pagination, options);

    return data;
  }
);

export const fetchUserRequest = createAsyncThunk(
  'requests/fetchUserRequest',
  async ({ userId, requestId, options = {} }) => {
    const data = await getUserRequest(userId, requestId, options);
    // dispatch(requestActions.setRequest({ data }));

    return data;
  }
);

export const editRequest = createAsyncThunk(
  'requests/editRequest',
  async ({ userId, requestId, body }) => {
    const data = await updateUserRequest(userId, requestId, body);

    return data;
  }
);

export const sendAdditionalRequest = createAsyncThunk(
  'requests/additionalRequest',
  async (
    { dealershipId, userId, requestId, imageId, body },
    { rejectWithValue }
  ) => {
    try {
      const data = await createAdditionalRequest(
        dealershipId,
        userId,
        requestId,
        imageId,
        body
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeRequests = createAsyncThunk(
  'requests/removeRequests',
  async ({ dealershipId, body }) => {
    const data = await deleteRequests(dealershipId, body);

    return data;
  }
);

export const removeUserRequests = createAsyncThunk(
  'requests/removeUserRequests',
  async ({ userId, body }) => {
    const data = await deleteUserRequests(userId, body);

    return data;
  }
);