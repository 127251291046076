import { Button, Divider, Dropdown, InputNumber, Menu } from 'antd';
import { Card, ConfirmModal, Modal, ThreeDots } from '../UI';
import {
  ArrowRightOutlined,
  EuroCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

import classes from './InvoiceItem.module.css';
import InvoiceRibbon from './InvoiceRibbon';
import InvoiceText from './InvoiceText';
import { InvoiceType } from '../../lib/constants/invoiceType';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceClose, invoicePay } from '../../store/actions/invoice-actions';
import { selectLoggedInUser } from '../../store/slices/auth-slice';
import { Roles } from '../../lib/constants/roles';
import { PRIMARY_DATE_TIME_FORMAT } from '../../lib/constants/utils';
import { useTranslation } from 'react-i18next';

const InvoiceItem = ({ invoice, dealershipId }) => {
  const [togglePay, setTogglePay] = useState(false);
  const [toggleCloseInvoice, setToggleCloseInvoice] = useState(false);
  const [invoiceLoadingStatus, setInvoiceLoadingStatus] = useState(false);
  const loggedInUser = useSelector(selectLoggedInUser);
  const creditsInput = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menu = (
    <Menu style={{ minWidth: '165px' }}>
      <Menu.Item className={classes.flex_between} key="0">
        <Link to={`${invoice.id}`}>{t('invoices.details')}</Link>
        <FileTextOutlined />
      </Menu.Item>
    </Menu>
  );

  const type = invoice.type.toUpperCase();
  const createdAt = moment(invoice.created_at).format(PRIMARY_DATE_TIME_FORMAT);
  const closedAt = invoice.closed
    ? moment(invoice.closed_at).format(PRIMARY_DATE_TIME_FORMAT)
    : '';

  let contentRibbon = [InvoiceType.UNLIMITED, InvoiceType.PREPAID].includes(
    type
  ) ? (
    <>
      {invoice.closed && (
        <>
          <span className="small">{closedAt}</span>
          <InvoiceRibbon
            classes={'green_ribbon'}
            icon={<FileTextOutlined className="me-1" />}
            text={t('invoices.closed')}
          />
        </>
      )}
      {!invoice.closed && (
        <InvoiceRibbon
          classes={'gray_ribbon'}
          icon={<FileTextOutlined className="me-1" />}
          text={t('invoices.opened')}
        />
      )}
    </>
  ) : (
    <>
      {invoice.closed && (
        <>
          <span className="small">{closedAt}</span>
          <InvoiceRibbon
            classes={'green_ribbon'}
            icon={<EuroCircleOutlined className="me-1" />}
            text={t('invoices.success')}
          />
        </>
      )}

      {!invoice.closed && +invoice.paid_amount === 0 && (
        <InvoiceRibbon
          classes={'gray_ribbon'}
          icon={<EuroCircleOutlined className="me-1" />}
          text={t('invoices.pending')}
        />
      )}

      {+invoice.paid_amount !== 0 && !invoice.closed && (
        <InvoiceRibbon
          classes={'yellow_ribbon'}
          icon={<EuroCircleOutlined className="me-1" />}
          text={t('invoices.partial')}
        />
      )}
    </>
  );

  const payInvoice = async () => {
    if (!creditsInput.current.defaultValue) {
      return;
    }

    const body = {
      amount: +creditsInput.current.defaultValue,
    };

    setInvoiceLoadingStatus(true);
    
    await dispatch(invoicePay({ dealershipId, invoiceId: invoice.id, body }));

    setInvoiceLoadingStatus(false);
    setTogglePay(false);
  };

  const closeInvoice = async () => {
    setInvoiceLoadingStatus(true);

    await dispatch(invoiceClose({ dealershipId, invoiceId: invoice.id }));

    setInvoiceLoadingStatus(false);
    setToggleCloseInvoice(false);
  };

  return (
    <>
      <Card
        style={{
          flexGrow: '1',
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={`p-2 ${classes.invoice_header}`}>
          <div style={{ position: 'absolute', right: '5px' }}>
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={menu}
            >
              <ThreeDots />
            </Dropdown>
          </div>

          <p>
            {t('invoices.invoiceNumber', {
              invoiceNumber: invoice.invoice_number,
            })}
          </p>
          <span className={`small ${classes.thin_font}`}>{createdAt}</span>
        </div>

        <Divider style={{ margin: '8px 0' }} />

        <div className={`p-2 credits_data ${classes.invoice_header}`}>
          <div style={{ position: 'relative', minHeight: '22px' }}>
            {contentRibbon}
          </div>

          <div style={{ margin: '40px 0 20px 0' }}>
            <InvoiceText
              text={t('invoices.creditsCost')}
              credits={invoice.amount}
            />

            {type === InvoiceType.POSTPAID && (
              <InvoiceText
                text={t('invoices.creditsFromAccount')}
                credits={invoice.credits}
              />
            )}

            {type === InvoiceType.POSTPAID &&
              +invoice.paid_amount !== 0 &&
              !invoice.closed && (
                <InvoiceText
                  text={t('invoices.creditsPaid')}
                  credits={invoice.paid_amount}
                />
              )}

            {type === InvoiceType.POSTPAID && (
              <Divider style={{ margin: '8px 0' }} />
            )}

            {type === InvoiceType.POSTPAID && (
              <div>
                {!invoice.closed && (
                  <InvoiceText
                    text={t('invoices.totalToPay')}
                    credits={
                      invoice.amount - invoice.credits - +invoice.paid_amount
                    }
                  />
                )}
                {invoice.closed && (
                  <InvoiceText
                    text={t('invoices.paid')}
                    credits={+invoice.paid_amount}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-auto payment p-2">
          {loggedInUser.type === Roles.ROOT && (
            <div>
              {invoice.closed && (
                <Button className="w-100" type="primary">
                  <Link to={`${invoice.id}`} className="big_text">
                    {t('invoices.details')}
                  </Link>
                </Button>
              )}
              {[InvoiceType.PREPAID, InvoiceType.UNLIMITED].includes(type) &&
                !invoice.closed && (
                  <Button
                    className="w-100 big_text"
                    onClick={() => setToggleCloseInvoice(true)}
                  >
                    {t('global.form.close')}
                  </Button>
                )}
              {type === InvoiceType.POSTPAID && !invoice.closed && (
                <Button
                  className="w-100 flex_center red_button big_text"
                  onClick={() => setTogglePay(true)}
                >
                  {t('invoices.proccedToPayment')}
                  <ArrowRightOutlined />
                </Button>
              )}
            </div>
          )}
        </div>
      </Card>

      {togglePay && (
        <Modal title={'Invoice Payment'} onClose={() => setTogglePay(false)}>
          <p>
            {t('invoices.creditsToPay', {
              credits: invoice.amount - invoice.credits - +invoice.paid_amount,
            })}
          </p>
          <InputNumber
            ref={creditsInput}
            max={invoice.amount - invoice.credits - +invoice.paid_amount}
          />
          <div className="d-flex justify-content-end">
            <Button
              disabled={invoiceLoadingStatus}
              onClick={() => {
                setTogglePay(false);
              }}
            >
              {t('global.form.close')}
            </Button>
            <Button
              loading={invoiceLoadingStatus}
              onClick={payInvoice}
              className="ms-2 big_text"
              type="primary"
            >
              {t('invoices.payInvoice')}
            </Button>
          </div>
        </Modal>
      )}

      {toggleCloseInvoice && (
        <ConfirmModal
          title={t('invoices.closeInvoice')}
          onClose={() => setToggleCloseInvoice(false)}
          description={t('invoices.closeInvoiceDescription', {
            invoiceNumber: invoice.invoice_number,
          })}
          buttonText={t('invoices.closeInvoice')}
          onConfirm={closeInvoice}
          isLoading={invoiceLoadingStatus}
        ></ConfirmModal>
      )}
    </>
  );
};

export default InvoiceItem;
