import React from 'react';
import classes from './DefaultBadge.module.css';

const DefaultBadge = ({ text, isAdmin, color }) => {

  const style = color && {
    background: color
  }

  const _classes = isAdmin
    ? `${classes['badge']} ${classes['admin_badge']} ms-2`
    : `${classes['badge']} ${classes['default_badge']}`;

  return <span style={style} className={_classes}>{text}</span>;
};

export default DefaultBadge;
