import React from 'react';

const UserText = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-center my-2">
      {icon}
      <p className="ms-2">{text}</p>
    </div>
  );
};

export default UserText;
