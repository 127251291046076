import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OTHER_FEATURES, REQUEST_FEATURES } from '../../lib/constants/features';
import FeatureItem from './FeatureItem';

const FeaturesList = React.memo(
  ({
    features,
    userFeatures,
    onChange,
    isLoading,
    prices,
    isUserFeature = false,
    onDateValidation,
  }) => {
    const { t } = useTranslation();

    const requestsFeatures = useMemo(() => {
      return features.filter((feature) =>
        REQUEST_FEATURES.includes(feature.name)
      );
    }, [features]);

    const otherFeatures = useMemo(() => {
      return features.filter((feature) =>
        OTHER_FEATURES.includes(feature.name)
      );
    }, [features]);

    return (
      <>
        <p className="mb-3 primary_clr">{t('features.requestFeatures')}</p>
        {requestsFeatures.length ? (
          requestsFeatures.map((feature) => (
            <FeatureItem
              key={feature.name}
              onChange={onChange}
              feature={feature}
              userFeatures={userFeatures}
              prices={prices}
              isLoading={isLoading}
              isUserFeature={isUserFeature}
              onDateValidation={onDateValidation}
            />
          ))
        ) : (
          <p className="gray-clr mb-3 px-1">
            {t('features.noRequestFeatures')}
          </p>
        )}

        <p className="mb-3 primary_clr">{t('features.otherFeatures')}</p>
        {otherFeatures.length ? (
          otherFeatures.map((feature) => (
            <FeatureItem
              key={feature.name}
              onChange={onChange}
              feature={feature}
              userFeatures={userFeatures}
              prices={prices}
              isLoading={isLoading}
              isUserFeature={isUserFeature}
              onDateValidation={onDateValidation}
            />
          ))
        ) : (
          <p className="gray-clr mb-3 px-1">{t('features.noOtherFeatures')}</p>
        )}
      </>
    );
  }
);

export default FeaturesList;
