import { toastrSuccess } from '../../helpers/toastr';
import {
  createUser,
  getDealershipUsers,
  unassignUserFromDealership,
  updateUser,
  updateUserAssignment,
  uploadProfilePicture,
  getAllUsers,
  assignUserFeature,
  updateUserFeature,
  deleteUserFeature,
} from '../../lib/user';
import { authActions } from '../slices/auth-slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { dealershipActions } from '../slices/dealership-slice';

export const addUser = createAsyncThunk(
  'users/addUser',
  async (user, { rejectWithValue }) => {
    try {
      const response = await createUser(user);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  'users/editUser',
  async ({ userId, body, isCurrent = false }, { dispatch }) => {
    const response = await updateUser(userId, body);

    isCurrent && dispatch(authActions.setLoggedInUser({ user: response }));

    toastrSuccess(
      i18n.t('global.successEdited', {
        name: response.name,
      })
    );

    return response;
  }
);

export const editUserAssigment = createAsyncThunk(
  'users/editUserAssigment',
  async ({ userId, dealershipId, body, isCurrent = false }, { dispatch }) => {
    const response = await updateUserAssignment(userId, dealershipId, body);

    if (isCurrent) {
      const dealership = response?.dealerships?.find(d => d.id === dealershipId);
      
      if (dealership) {
        dispatch(dealershipActions.setCurrentDealership({ dealership }));
      }

      dispatch(authActions.setLoggedInUser({ user: response }));
    }


    toastrSuccess(i18n.t('global.success'));

    return response;
  }
);

export const switchUserFeature = createAsyncThunk(
  'users/switchUserFeature',
  async ({ userId, dealershipId, body, type }) => {
    let response;

    switch (type) {
      case 'POST':
        response = await assignUserFeature(userId, dealershipId, body);
        break;
      case 'PATCH':
        response = await updateUserFeature(userId, dealershipId, body);
        break;
      case 'DELETE':
        response = await deleteUserFeature(userId, dealershipId, body);
        break;
      default:
        break;
    }

    toastrSuccess(i18n.t('global.success'));

    return response;
  }
);

export const fetchDealershipUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ dealershipId, pagination, options = {} }) => {
    const data = await getDealershipUsers(dealershipId, pagination, options);

    return data;
  }
);

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ pagination, options = {} }) => {
    const data = await getAllUsers(pagination, options);

    return data;
  }
);

export const removeUser = createAsyncThunk(
  'users/removeUser',
  async ({ userId, dealershipId }) => {
    const data = await unassignUserFromDealership(userId, dealershipId);

    return data;
  }
);

export const uploadUserImage = createAsyncThunk(
  'users/uploadUserImage',
  async ({ userId, body }, { dispatch }) => {
    const editUser = await uploadProfilePicture(userId, body);

    dispatch(authActions.setUserProfileImage(editUser));

    toastrSuccess(i18n.t('global.success'));

    return editUser;
  }
);
