import React from 'react';

const InvoiceText = ({ text, credits }) => {
  return (
    <div className="d-flex justify-content-between">
      <p>{text}</p>
      <span>{credits}</span>
    </div>
  );
};

export default InvoiceText;
