import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  Row,
  Col,
  Divider,
  Button,
  Pagination,
  Dropdown,
  Space,
  Menu,
  Popconfirm,
  Checkbox,
} from 'antd';
import VehiclesList from '../components/Vehicles/VehiclesList';
import FeaturesList from '../components/Settings/FeaturesList';
import VehicleForm from '../components/Vehicles/VehicleForm';
import ContentWrapper from '../components/UI/content-wrapper/ContentWrapper';
import PaginationCount from '../components/pagination/PaginationCount';
import UserProfileData from '../components/Users/user-profile/UserProfileData';
import ProfilePicture from '../components/Users/user-profile/ProfilePicture';
import { LoadingSpinner, ThreeDots } from '../components/UI';
import { createUserRequest, getUserRequests } from '../lib/request';
import { initFeatures, REQUEST_FEATURES } from '../lib/constants/features';
import { getUserById } from '../lib/user';
import { createUserVehicle } from '../lib/vehicle';
import { switchUserFeature } from '../store/actions/user-actions';
import {
  selectCurrentDealership,
  selectPermissions,
} from '../store/slices/dealership-slice';
import { selectUserById } from '../store/slices/user-slice';
import { fetchDealershipPermissions } from '../store/actions/dealership-actions';
import { selectLoggedInUser } from '../store/slices/auth-slice';
import { Roles } from '../lib/constants/roles';
import { RequestStatus } from '../lib/constants/requestStatus';
import { useMemo } from 'react';
import { isNotZeroDate, setPagination } from '../helpers/_util';
import { useTranslation } from 'react-i18next';
import { toastrSuccess } from '../helpers/toastr';
import { removeUserRequests } from '../store/actions/request-actions';

const perPage = 10;

const UserProfile = () => {
  const dispatch = useDispatch();

  const currentDealership = useSelector(selectCurrentDealership);
  const loggedInUser = useSelector(selectLoggedInUser);
  const dealershipPermissions = useSelector(selectPermissions);

  const { t } = useTranslation();

  const { id: userId } = useParams();
  const { dealershipId: id } = useParams();
  const dealershipId = id || currentDealership?.id;

  const [requests, setRequests] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoadingFeature, setIsLoadingFeature] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [page, setPage] = useState(1);
  const [requestsCount, setRequestsCount] = useState(0);
  const [userFeatures, setUserFeatures] = useState([]);
  const [dealershipFeatures, setDealershipFeatures] = useState([]);
  const [featurePrices, setFeaturePrices] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    useSelector((state) => selectUserById(state, userId))
  );

  const [checkedList, setCheckedList] = useState([]);

  const onChange = (list) => {
    setCheckedList(list);
  };

  const handleCheckAll = () => {
    const filteredRequests = requests.filter((req) =>
      [
        RequestStatus.ACCEPTED,
        RequestStatus.ADDITIONAL_REQ,
        RequestStatus.REJECTED,
      ].includes(req.status)
    );

    setCheckedList(filteredRequests);
  };

  const menu = (
    <Menu style={{ minWidth: '165px' }}>
      <Menu.Item onClick={handleCheckAll} key="0">
        {t('requests.selectAll')}
      </Menu.Item>
    </Menu>
  );

  const isSM = useMediaQuery({ query: `(max-width: 575px)` });

  const isLoading = isLoadingUser || isLoadingVehicles || isLoadingPermissions;

  const onChangeFeatureHandler = useCallback(
    async (name, checked, e) => {
      setIsLoadingFeature(true);

      const body = checked
        ? {
            feature: name.toUpperCase(),
          }
        : {
            features: [name.toUpperCase()],
          };

      try {
        const type = checked ? 'POST' : 'DELETE';

        const response = await dispatch(
          switchUserFeature({ userId, dealershipId, body, type })
        ).unwrap();

        setSelectedUser(response);
      } catch (err) {}

      setIsLoadingFeature(false);
    },
    [dealershipId, dispatch, userId]
  );

  const canScanDocument = useMemo(
    () => userFeatures.some((feature) => feature === 'SCAN_DOCUMENT'),
    [userFeatures]
  );

  const requestsFeatures = useMemo(() => {
    return userFeatures.filter((feature) => REQUEST_FEATURES.includes(feature));
  }, [userFeatures]);

  const loadUser = async (userId, signal) => {
    const options = {
      signal,
    };

    try {
      const user = await getUserById(userId, options);

      setSelectedUser(user);
    } catch (err) {}
  };

  const loadVehicles = useCallback(
    async (dealershipId, id, signal) => {
      try {
        const params = {
          status: [RequestStatus.ACCEPTED, RequestStatus.ADDITIONAL_REQ].join(
            ','
          ),
        };

        const options = { signal, params };
        const pagination = setPagination(page, perPage);

        setCheckedList([]);

        const data = await getUserRequests(
          dealershipId,
          id,
          pagination,
          options
        );

        const filteredRequests = data.requests.filter(
          (req) => req.type === 'VEHICLE_EXCHANGE'
        );

        setRequestsCount(data.count);
        setRequests([...filteredRequests]);
      } catch (err) {}
    },
    [page]
  );

  const loadPermissions = useCallback(
    async (signal) => {
      const options = { signal };

      try {
        setIsLoadingPermissions(true);

        await dispatch(
          fetchDealershipPermissions(dealershipId, options)
        ).unwrap();
      } catch (err) {
      } finally {
        setIsLoadingPermissions(false);
      }
    },
    [dealershipId, dispatch]
  );

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const loadData = async (signal) => {
      setIsLoadingVehicles(true);

      await loadVehicles(dealershipId, userId, signal);

      setIsLoadingVehicles(false);
    };

    loadData(signal);

    return () => controler.abort();
  }, [dealershipId, loadVehicles, userId]);

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    const loadData = async (signal) => {
      setIsLoadingUser(true);

      await loadUser(userId, signal);

      setIsLoadingUser(false);
    };

    if (!selectedUser) {
      loadData(signal);
    }

    return () => controler.abort();
  }, [selectedUser, userId]);

  useEffect(() => {
    const controler = new AbortController();
    const { signal } = controler;

    if (!dealershipPermissions && loggedInUser.type !== Roles.ROOT) {
      loadPermissions(signal);
    }

    return () => controler.abort();
  }, [dealershipPermissions, loadPermissions, loggedInUser.type]);

  /**
   * If user is loaded, set dealership and
   * user features.
   */
  useEffect(() => {
    if (selectedUser) {
      const userDealership = selectedUser.dealerships.find(
        (dealership) => dealership.id === dealershipId
      );

      const dealership = currentDealership || userDealership;

      // We are mapping so we can retrieve feature description
      const dealershipFeatures = dealership?.features
        ? dealership.features.map((feature) => {
            const f = initFeatures.find(
              (f) => f.name.toUpperCase() === feature
            );

            return f || feature;
          })
        : [];

      const userFeatures = userDealership?.user_dealership.features || [];

      const featureDetails = getFeatureDetails(
        userDealership?.prices,
        userDealership
      );

      setFeaturePrices(featureDetails);
      setDealershipFeatures(dealershipFeatures);
      setUserFeatures(userFeatures);
    }
  }, [currentDealership, dealershipId, selectedUser]);

  const getFeatureDetails = (features, userDealership) => {
    if (!features) return [];

    return features.map((price) => {
      const featureFound = userDealership?.user_dealership?.user_features?.find(
        (feature) => feature.feature === price.feature
      );

      const valid = isNotZeroDate(featureFound?.valid_until)
        ? featureFound.valid_until
        : price.valid_until;

      return featureFound
        ? {
            ...price,
            valid_until: valid,
            created_at: featureFound.created_at,
            dealership_valid_until: price.valid_until,
          }
        : {
            ...price,
            dealership_valid_until: price.valid_until,
          };
    });
  };

  const onCloseHandler = () => {
    setIsVisible(false);
  };

  const onOpenHandler = () => {
    setIsVisible(true);
  };

  const createVehicleHandler = async (body, requestType) => {
    setIsAdding(true);

    body['dealership_id'] = dealershipId;

    try {
      const newVehicle = await createUserVehicle(userId, body);

      const requestBody = {
        type: requestType,
        vehicle_id: newVehicle.id,
        dealership_id: dealershipId,
      };

      await createUserRequest(userId, requestBody);

      onCloseHandler();
    } catch (err) {}

    setIsAdding(false);
  };

  const changePageHandler = (pageNumber) => {
    setPage(pageNumber);
  };

  const dateValidationHandler = useCallback(
    async (name, date) => {
      const body = {
        feature: name.toUpperCase(),
        valid_until: date,
      };

      setIsLoadingFeature(true);

      try {
        const response = await dispatch(
          switchUserFeature({ userId, dealershipId, body, type: 'PATCH' })
        ).unwrap();

        setSelectedUser(response);
      } catch (err) {}

      setIsLoadingFeature(false);
    },
    [dealershipId, dispatch, userId]
  );

  const handleDeleteRequests = async () => {
    const body = {
      request_ids: checkedList.map((request) => request.id),
    };

    try {
      await dispatch(removeUserRequests({ userId, body }));

      toastrSuccess(t('requests.requestsAreDeleted'));

      setIsLoadingVehicles(true);

      await loadVehicles(dealershipId, userId);

      setIsLoadingVehicles(false);
    } catch {}
  };

  return (
    <>
      <div className="picture_background">
        <ProfilePicture user={selectedUser} />
      </div>

      <ContentWrapper>
        {!isLoading ? (
          <Row gutter={[16, 16]} className="a">
            <Col xs={24} md={10} lg={{ span: 8 }}>
              <div className="card_wrapper">
                {!!selectedUser && <UserProfileData user={selectedUser} />}

                <Divider orientation="left" className="big_text">
                  {t('userProfile.settings')}
                </Divider>
                {dealershipFeatures.length > 0 && (
                  <div className="p-2">
                    <FeaturesList
                      onChange={onChangeFeatureHandler}
                      features={dealershipFeatures}
                      userFeatures={userFeatures}
                      isLoading={isLoadingFeature}
                      prices={featurePrices}
                      isUserFeature
                      onDateValidation={dateValidationHandler}
                    />
                  </div>
                )}
                {!dealershipFeatures.length && (
                  <p className="gray-clr p-2">{t('features.noFeatures')}</p>
                )}
              </div>
            </Col>
            <Col xs={24} md={14} lg={{ span: 14, offset: 2 }}>
              <div className="card_wrapper">
                {!requestsFeatures.length &&
                (loggedInUser.type === Roles.ROOT ||
                  (dealershipPermissions &&
                    dealershipPermissions['admin_create_vehicle'])) ? (
                  <p
                    className="gray-clr p-3"
                    style={{
                      background: ' #84b4e92b',
                      borderRadius: '5px',
                    }}
                  >
                    {t('userProfile.createVehicleInfo')}
                  </p>
                ) : null}

                {checkedList.length ? (
                  <div className="flex_between my-3 gap-2">
                    <span>
                      {t('requests.selectedInfo', {
                        count: checkedList.length,
                      })}
                    </span>
                    <Space>
                      <Button onClick={() => setCheckedList([])}>
                        {t('global.form.dismiss')}
                      </Button>
                      <Popconfirm
                        placement="bottomRight"
                        title={t('requests.deleteRequestsInfo')}
                        onConfirm={handleDeleteRequests}
                        okText={t('global.form.yes')}
                        cancelText={t('global.form.no')}
                      >
                        <Button type="danger">{t('global.form.delete')}</Button>
                      </Popconfirm>
                    </Space>
                  </div>
                ) : (
                  <div className="flex_between my-3">
                    {!!requestsFeatures.length &&
                      (loggedInUser.type === Roles.ROOT ||
                        (dealershipPermissions &&
                          dealershipPermissions['admin_create_vehicle'])) && (
                        <Button onClick={onOpenHandler} type="primary">
                          {t('userProfile.newVehicle')}
                        </Button>
                      )}
                    {!!requests.length && (
                      <>
                        <div></div>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: '1rem' }}
                        >
                          <PaginationCount
                            perPage={perPage}
                            page={page}
                            total={requestsCount}
                          />
                          <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            overlay={menu}
                          >
                            <ThreeDots />
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {requests.length ? (
                  <>
                    <div style={{ maxHeight: '460px', overflow: 'auto' }}>
                      <Checkbox.Group
                        className="w-100"
                        onChange={onChange}
                        value={checkedList}
                      >
                        <VehiclesList requests={requests} />
                      </Checkbox.Group>
                    </div>
                    <div className="d-flex justify-content-sm-end mt-3">
                      <Pagination
                        defaultCurrent={page}
                        pageSize={perPage}
                        showQuickJumper
                        showSizeChanger={false}
                        total={requestsCount}
                        onChange={changePageHandler}
                        size="small"
                      />
                    </div>
                  </>
                ) : (
                  <p className="no_data">
                    {t('userProfile.noAcceptedVehicles')}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <LoadingSpinner />
        )}
      </ContentWrapper>

      {isVisible && (
        <VehicleForm
          onCreateVehicle={createVehicleHandler}
          isVisible={isVisible}
          isSM={isSM}
          isAdding={isAdding}
          onClose={onCloseHandler}
          canScanDocument={canScanDocument}
          requests={requestsFeatures}
          userId={userId}
          dealershipId={dealershipId}
          // res={response}
        />
      )}
    </>
  );
};

export default UserProfile;
