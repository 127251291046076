import axios from '../api/axios';

export async function getUserVehicles(userId) {
  const response = await axios.get(`/users/${userId}/vehicles`);

  return response.data;
}

export async function createUserVehicle(userId, body) {
  const response = await axios.post(
    `/users/${userId}/vehicles`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
}
