import axios from '../api/axios';

export async function createDevice(body) {
  const response = await axios.post('/devices', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}
