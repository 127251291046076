import { configureStore } from '@reduxjs/toolkit';
import i18next from 'i18next';
import antdSlice, { antdActions } from './slices/antd-slice';
import authSlice from './slices/auth-slice';
import dealershipSlice from './slices/dealership-slice';
import documentSlice from './slices/document-slice';
import errorSlice from './slices/error-slice';
import invoiceSlice from './slices/invoice-slice';
import requestSlice from './slices/request-slice';
import targetSlice from './slices/target-slice';
import userGroupSlice from './slices/user-group-slice';
import userSlice from './slices/user-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    dealerships: dealershipSlice.reducer,
    requests: requestSlice.reducer,
    users: userSlice.reducer,
    error: errorSlice.reducer,
    groups: userGroupSlice.reducer,
    targets: targetSlice.reducer,
    invoices: invoiceSlice.reducer,
    document: documentSlice.reducer,
    antd: antdSlice.reducer,
  },
});

i18next.on('languageChanged', (lng) => {
  store.dispatch(antdActions.setLang({ lang: lng }));
})

export default store;
