import { ConfigProvider } from 'antd';
import React from 'react';
import deDE from 'antd/es/locale/de_DE';
import { selectLang } from '../../../store/slices/antd-slice';
import { useSelector } from 'react-redux';

const LanguageProvider = ({ children, i18n }) => {
  // const lang = useSelector(selectLang);

  return (
    <ConfigProvider locale={i18n.language === 'de' ? deDE : undefined}>
      {children}
    </ConfigProvider>
  );
};

export default LanguageProvider;
