import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorActions } from '../store/slices/error-slice';
import ContentWrapper from '../components/UI/content-wrapper/ContentWrapper';
import { useTranslation } from 'react-i18next';

const ServerError = ({ error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removeErrorHandler = () => {
    dispatch(errorActions.removeError());
  };

  return (
    <ContentWrapper>
      <Result
        status="500"
        title="500"
        subTitle={t('errorPages.subTitle500')}
        extra={
          <>
            <Button onClick={removeErrorHandler} type="primary">
              <Link to={'/'}>{t('global.backHome')}</Link>
            </Button>
            <p className="mt-2">{error.message}</p>
          </>
        }
      />
    </ContentWrapper>
  );
};

export default ServerError;
