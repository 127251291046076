import React, { useRef } from 'react';
import classes from './ThreeDots.module.css';

const ThreeDots = React.forwardRef(({ ...props }, ref) => {
  return (
    <div {...props} className={classes.dots_container}>
      <div className={classes.three_dots} ref={ref}></div>
    </div>
  );
});

export default ThreeDots;
