import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  fetchInvoices,
  invoiceClose,
  invoicePay,
} from '../actions/invoice-actions';

const initialState = {
  invoices: [],
  count: 0,
  isLoading: true,
};

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInvoices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        const { invoices, count } = action.payload;

        state.invoices = invoices;
        state.count = count;
        state.isLoading = false;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(invoicePay.fulfilled, invoiceClose.fulfilled),
        (state, action) => {
          const index = state.invoices.findIndex(
            (invoice) => invoice.id === action.payload.id
          );

          if (~index) {
            state.invoices[index] = action.payload;
          }
        }
      );
  },
});

export const selectAllINvoices = (state) => state.invoices.invoices;
export const selectInvoiceById = (state, invoiceId) =>
  state.invoices.invoices.find((invoice) => invoice.id === invoiceId);

export const invoiceActions = invoiceSlice.actions;

export default invoiceSlice;
