import axios from '../api/axios';

export async function getDefaultPrices(options = {}) {
  const response = await axios.get(`/prices`, options);

  return response.data;
}

export async function addDefaultPrice(body) {
  const response = await axios.post(`/price`, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function updateDefaultPrice(body) {
  const response = await axios.patch(`/price`, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function deleteDefaultpPrice(body) {
  const response = await axios.delete(`/price`, {
    data: { ...body },
  });

  return response.data;
}
