import { Col, Row } from 'antd';
import React from 'react';
import TargetItem from './TargetItem';

const TargetsList = ({
  targets,
  onEditTarget,
  onDeleteTarget,
  onChangeImage,
  onSetting,
}) => {
  return (
    <Row gutter={[8, 8]}>
      {targets.map((target, index) => (
        <Col key={index} xs={24} sm={8} md={8} lg={6} xl={4}>
          <TargetItem
            onEdit={onEditTarget}
            onDelete={onDeleteTarget}
            onChangeImage={onChangeImage}
            onSetting={onSetting}
            target={target}
          />
        </Col>
      ))}
    </Row>
  );
};

export default TargetsList;
