import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { onMessageListener } from '../../../firebase';
import { toast, Zoom } from 'react-toastify';
import logo from '../../../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Notification() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [x, setX] = useState(null);

  let navigate = useNavigate();
  const { t } = useTranslation();

  function getNotificationTitle(event) {
    let title = 'Notification';

    switch (event) {
      case 'NEW_REQUEST':
        title = t('notifications.newRequest');
        break;
      case 'UNHANDLED_REQUEST':
        title = t('notifications.unhandledRequest');
        break;
      default:
        break;
    }

    return title;
  }

  function getNotificationMessage(event) {
    let message = 'Notification';

    switch (event) {
      case 'NEW_REQUEST':
        message = t('notifications.newRequestMessage');
        break;
      case 'UNHANDLED_REQUEST':
        message = t('notifications.unhandledRequestMessage');
        break;
      default:
        break;
    }

    return message;
  }

  const handleBrrmNotifications = (data) => {
    window.BrrmChat('notify', data);

    setX(data);

    return false;
  };

  const handleNavigate = (event) => {
    switch (event) {
      case 'NEW_REQUEST':
        navigate('/requests?status=PENDING', { replace: true });
        break;
      case 'UNHANDLED_REQUEST':
        navigate('/requests?status=PENDING', { replace: true });
        break;
      default:
        break;
    }
  };

  if (
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  ) {
    onMessageListener().then((payload) => {
      console.log(payload);
      if (payload?.data?.appName === 'BrrmChatApp') {
        handleBrrmNotifications(payload?.data);
        return;
      }

      const title = getNotificationTitle(payload?.data?.status);
      const message = getNotificationMessage(payload?.data?.status);

      setNotification({
        title: getNotificationTitle(payload?.data?.status) || 'Notification',
        body: payload?.data?.message,
      });

      toast(<CustomToast message={`${title}: ${message}`} imageUrl={logo} />, {
        position: 'top-right',
        hideProgressBar: true,
        progress: undefined,
        autoClose: false,
        transition: Zoom,
        onClick: () => handleNavigate(payload?.data?.status),
      });
    });
  }

  return <></>;
}

export default Notification;

const CustomToast = ({ message, imageUrl }) => (
  <div className="custom-toast">
    {imageUrl && (
      <img
        src={imageUrl}
        alt="Toast"
        className="toast-image"
        style={{ width: '50px' }}
      />
    )}
    <div className="toast-message">{message}</div>
  </div>
);
