import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_LOGO = './favicon.ico';

const initialState = {
  title: 'My Autohouse',
  logo: DEFAULT_LOGO,
};

const documentSlice = createSlice({
  name: 'document',
  initialState: initialState,
  reducers: {
    setTitle(state, action) {
      const { title } = action.payload;

      state.title = title;
    },
    setLogo(state, action) {
      state.logo = action.payload || DEFAULT_LOGO;
    },
  },
});

export const documentActions = documentSlice.actions;

export default documentSlice;
