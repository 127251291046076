import axios from '../api/axios';
import store from '../store';
import { authActions } from '../store/slices/auth-slice';
import { logoutUser } from '../store/actions/auth-actions';

/**
 * is refresh token interceptor.
 * if error code is 401, try to refresh token
 */
export function createAxiosResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message === 'canceled') return Promise.reject(error);
      // Reject promise if usual error or error on login api route
      if (
        error?.response?.status !== 401 ||
        error?.response?.config?.url === '/auth/login'
      ) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);

      const state = store.getState();
      const token = state.auth.token;
      const refresh_token = token?.refresh_token;

      // Reject if there is no refresh token
      if (!refresh_token) return Promise.reject(error);
      // 'eyJhbGciOiJSUzI1NiIsImtpZCI6Im15YXV0b2hhdXMtcmVmcmVzaCIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDg4MDgzNDAsImlhdCI6MTY0NjIxNjM0MCwiaXNzIjoiIiwic3ViIjoiIiwidXNlcl9pZCI6ImY0YzhlOTE0LTc4ZmEtNDZkYS04ZmU0LWU3ZDI0YThmZmMxMSJ9.TnL00dnCLzGR5MfhxdXoMDnMU57fMn_OcCdMSnejOdn9cG2IECdbF-GuTmn0v_SDoxZ11r7K73OEoflrxcrG70h0gXbY1rPOfdZfGmHFJo9seo9b5tO5wGnuNZ3fgSznafoHSlltnpags6uTJsOO6Pf74bIEf_Y02QNxtHMm1f9I9vWCSAqa2RgC4jIXjMC-BnWbyirP_z7hvJYAhbRZlYenSHcBWamLTUChl_kKrARIydX56JrknJSdNSuhH0O9p_MVjme1YMKFG1w_fJ1pSGuRbstQ6tRB9HlD1bK-c4HHlFcGTxjwf67DdutnuwahYGqMyfzk2hB_WQsfm3Zo9w',

      return axios
        .post('/auth/refresh', {
          refresh_token,
        })
        .then((response) => {
          const token = response.data;
          store.dispatch(authActions.setToken({ token }));

          error.response.config.headers['Authorization'] =
            'Bearer ' + response.data.access_token;

          return axios(error.response.config);
        })
        .catch((error) => {
          store.dispatch(logoutUser());
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
}
