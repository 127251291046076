import React from 'react';
import { Modal } from '../UI';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ChangePasswordForm = ({ onClose, isLoading, onUpdatePassword }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const onFinish = (values) => {
    let data = {
      password: values['password'],
    };

    onUpdatePassword(data);
  };

  return (
    <Modal title={t('selfProfile.changePassword')} onClose={onClose}>
      <div className="p-1">
        <Form onFinish={onFinish} form={form} {...formItemLayout}>
          <Form.Item
            name="password"
            label={t('global.form.password')}
            rules={[
              {
                required: true,
                message: t('global.formValidationError.requiredPassword'),
              },
            ]}
            hasFeedback
          >
            <Input
              type="password"
              placeholder={t('global.form.enterNewPassword')}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={t('global.form.confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Confirm New Password.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('global.formValidationError.mismatchPassword'))
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={t('users.user.form.enterConfirmPassword')}
            />
          </Form.Item>

          <Button type="primary" loading={isLoading} htmlType="submit">
            {t('global.form.submit')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default ChangePasswordForm;
