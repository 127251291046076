import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  closeInvoice,
  getAllInvoices,
  getInvoice,
  getInvoiceItems,
  payInvoice,
} from '../../lib/invoice';

export const fetchInvoices = createAsyncThunk(
  'invoices/fetchInvoices',
  async ({ dealershipId, pagination, options = {} }) => {
    const data = await getAllInvoices({ dealershipId, pagination, options });

    return data;
  }
);

export const fetchInvoice = createAsyncThunk(
  'invoices/fetchInvoice',
  async ({ dealershipId, invoiceId }) => {
    const data = await getInvoice({ dealershipId, invoiceId });

    return data;
  }
);

export const fetchInvoiceItems = createAsyncThunk(
  'invoices/fetchInvoiceItems',
  async ({ dealershipId, invoiceId, pagination }) => {
    const data = await getInvoiceItems({ dealershipId, invoiceId, pagination });

    return data;
  }
);

export const invoicePay = createAsyncThunk(
  'invoices/invoicePay',
  async ({ dealershipId, invoiceId, body }) => {
    const data = await payInvoice({ dealershipId, invoiceId, body });

    return data;
  }
);

export const invoiceClose = createAsyncThunk(
  'invoices/invoiceClose',
  async ({ dealershipId, invoiceId }) => {
    const data = await closeInvoice({ dealershipId, invoiceId });

    return data;
  }
);
