import {
  EnvironmentOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Col, Image, Row, Tooltip } from 'antd';
import { BadgeIcon } from '../../UI';
import { useTranslation } from 'react-i18next';

const DealershipProfile = ({ dealership }) => {
  const { t } = useTranslation();

  return (
    <>
      <h6 style={{ fontWeight: '200' }}>
        {dealership.name}{' '}
        {/* {dealership.logo && (
          <Image
            src={dealership.logo.paths.original}
            preview={false}
            style={{ width: '50px' }}
          />
        )} */}
      </h6>

      <div className="d-flex align-items-center my-4">
        <BadgeIcon title={'CODE'} />
        <span className="me-2">{dealership.code}</span>
        <Tooltip title={t('register.referralCodeTooltip')}>
          <InfoCircleOutlined className="primary_clr" />
        </Tooltip>
      </div>

      <Row gutter={16}>
        <Col lg={24} xl={12}>
          <p
            style={{ wordBreak: 'break-all' }}
            className="d-flex align-items-center gap-2 mb-2"
          >
            <MailOutlined className="primary_clr" /> {dealership.email}
          </p>
        </Col>
        <Col lg={24} xl={12}>
          <p className="d-flex align-items-center gap-2 mb-2">
            <HomeOutlined className="primary_clr" /> {dealership.country} -{' '}
            {dealership.city}
          </p>
        </Col>
        <Col lg={24} xl={12}>
          <p className="d-flex align-items-center gap-2 mb-2">
            <EnvironmentOutlined className="primary_clr" />
            <span>{dealership.address ? dealership.address : '-'}</span>
          </p>
        </Col>
        <Col lg={24} xl={12}>
          <p className="d-flex align-items-center gap-2 mb-2">
            <PhoneOutlined className="primary_clr" /> {dealership.phone}
          </p>
        </Col>
      </Row>

      <div className="my-3">
        {!dealership.features || !dealership.features.length ? (
          <p className="gray-clr">{t('features.noFeatures')}</p>
        ) : (
          <p>
            {t('features.availableFeatures')}
            <span style={{ fontWeight: 'bold' }}>
              {dealership.features
                .map(
                  (feature) =>
                    feature.charAt(0).toUpperCase() +
                    feature.toLowerCase().slice(1)
                )
                .map((feature) => feature.replace('_', ' '))
                .join(', ')}
            </span>{' '}
          </p>
        )}
      </div>
    </>
  );
};

export default DealershipProfile;
