import React from 'react';
import classes from './Card.module.css';

const Card = ({ children, isProfile, style, className, onClick }) => {
  const allClasses = `${!isProfile && classes.card} ${
    className ? classes[className] : ''
  }`;

  return (
    <div onClick={onClick} className={allClasses} style={style}>
      {children}
    </div>
  );
};

export default Card;
