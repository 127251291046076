import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const PaginationCount = ({ perPage, page, total }) => {
  const xSmall = useMediaQuery({ query: `(max-width: 575px)` });
  const { t } = useTranslation();

  const lower = () => {
    return perPage * (page - 1) + 1;
  };

  const upper = () => {
    return Math.min(perPage * page, total);
  };

  return (
    <p>
      {t('global.resultsNumber', {
        lower: lower(),
        upper: upper(),
        total,
      })}
      {!xSmall && <span> {t('global.results')}</span>}
    </p>
  );
};

export default PaginationCount;
